import { Environment } from '@shared/interfaces/environment.interface';
import { Partners } from '@shared/enums/partners.enum';

export const environment: Environment = {
  production: false,
  hmr: false,
  SIGNATURE: 'beta',
  INTERCOME_APP_ID: 'yc1ho306',
  APP_URL: 'https://beta.digitalfirst.ai',
  API: 'https://beta.api.digitalfirst.ai/api/graphql',
  API_PREFIX: 'https://beta.api.digitalfirst.ai',
  API_WS: 'wss://beta.api.digitalfirst.ai/api/graphql',
  API_SSE: 'https://beta.api.digitalfirst.ai/api/sse',
  API_RECOMMENDATIONS: ' https://ai.digitalfirst.ai',
  API_RECOMMENDATIONS_LOGIN: 'digitalfirst_rd', // TODO remove when API will handle token
  API_RECOMMENDATIONS_PASSWORD: 'X7SBjRXhAzourA', // TODO remove when API will handle token
  MARKETPLACE_DOMAIN: 'develtio.dev',
  MARKETPLACE_CLIENT_ID: '53b08648-1deb-418e-a9f8-c04e5f3dc197',
  MARKETPLACE_URL: 'https://dev-mp-digitalfirst.develtio.dev',
  NEW_CDN_PATH: 'https://uat.cdn.digitalfirst.ai',
  ASSETS: 'https://uat.cdn.digitalfirst.ai/uploads/',
  DEBUG: true,
  GTAG: 'GTM-PNQX28P',
  GTAG_PUBLIC: 'GTM-PNQX28P',
  CAPTCHA: '',
  CONTACT_EMAIL: 'hello@digitalfirst.ai',
  SENTRY_ENV: 'uat',
  SENTRY_URL: 'https://c6f84d41452b4de2afcf1f45e888a426@o4504609403240448.ingest.sentry.io/4504609427554304',
  FACEBOOK_CLIENT_ID: '715954296945065',
  GOOGLE_CLIENT_ID: '217474323804-u1f390q149n5jnp4ancjmrnmnh3uuid1.apps.googleusercontent.com',
  APPLE_CLIENT_ID: 'com.develtio.digitalfirst-dev',
  APPLE_REDIRECT_URL: 'https://uat.api.digitalfirst.ai/api/auth/apple',
  DROPBOX_APP_KEY: 'saetb6hndyjc8px',
  partners: {
    [Partners.OXFORD_IE]: {
      APPLE_REDIRECT_URL: 'https://dev-ie-digitalfirst.develtio.dev/api/auth/apple',
    },
    [Partners.SEMRUSH]: {
      PUBLIC_KEY: '22ab62a4-d8d8-47be-9d30-60db6492c109',
    },
  },
};
