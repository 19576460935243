import { Component, Input } from '@angular/core';
import { TacticStepIconComponent } from '../tactic-step-icon/tactic-step-icon.component';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'df-tactic-landing',
  standalone: true,
  imports: [TacticStepIconComponent, CommonModule],
  templateUrl: './tactic-landing.component.html',
  styleUrl: './tactic-landing.component.css',
})
export class TacticLandingComponent {
  @Input() funnelId!: number;

  constructor(private sanitizer: DomSanitizer) {}

  getUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://landing.digitalfirst.ai/?funnelId=' + this.funnelId,
    ) as string;
  }

  onEditLanding() {
    window?.open('https://landing.digitalfirst.ai/?funnelId=' + this.funnelId + '&edit=true', '_blank')?.focus();
  }
}
