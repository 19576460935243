import { Routes } from '@angular/router';
import { PromptCanActivateGuard } from '@modules/prompt/guards/prompt-can-activate.guard';
import { StatementCanActivateGuard } from '@modules/statement/guards/statement-can-activate.guard';
import { Page404Component } from '@shared/components/page404/page404.component';
import { PartnerNoAuthComponent } from '@shared/components/partner-no-auth/partner-no-auth.component';
import { PaymentStatusComponent } from '@shared/components/payment-status/payment-status.component';
import { PartnerCanActivateGuard } from '@shared/guards/partner-can-activate.guard';
import { PermissionsResolver } from '@shared/services/permissions.resolver';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@modules/authorization/authorization.module').then((m) => m.AuthorizationModule),
  },
  {
    path: '',
    loadChildren: () => import('@modules/base-panel/base-panel.module').then((m) => m.BasePanelModule),
    resolve: {
      globalData: PermissionsResolver,
    },
  },
  {
    path: 'ayrshare',
    loadChildren: () => import('@modules/ayrshare/ayrshare.module').then((m) => m.AyrshareModule),
  },
  {
    path: 'statement',
    canActivate: [StatementCanActivateGuard],
    loadChildren: () =>
      import('@modules/statement/shared/modules/statement-onboarding/statement-onboarding.module').then(
        (m) => m.StatementOnboardingModule,
      ),
  },
  {
    path: 'statement',
    canActivate: [StatementCanActivateGuard],
    outlet: 'statement',
    loadChildren: () =>
      import('@modules/statement/shared/modules/statement-onboarding/statement-onboarding.module').then(
        (m) => m.StatementOnboardingModule,
      ),
  },
  {
    path: 'public',
    loadChildren: () => import('@modules/public/public.module').then((m) => m.PublicModule),
    canActivate: [PartnerCanActivateGuard],
  },
  {
    path: 'pricing',
    outlet: 'pricing',
    loadChildren: () =>
      import('@modules/pricing/shared/modules/pricing-packets/pricing-packets.module').then(
        (m) => m.PricingPacketsModule,
      ),
  },
  {
    path: 'pricing',
    loadChildren: () =>
      import('@modules/pricing/shared/modules/pricing-packets/pricing-packets.module').then(
        (m) => m.PricingPacketsModule,
      ),
  },
  {
    path: 'canvas',
    loadChildren: () => import('@modules/canvas/canvas.module').then((m) => m.CanvasModule),
  },
  {
    path: 'business-data',
    loadChildren: () => import('@modules/business-data/business-data.module').then((m) => m.BusinessDataModule),
  },
  {
    loadChildren: () => import('@modules/faq/faq.module').then((m) => m.FaqModule),
    path: 'faq',
  },
  {
    loadChildren: () => import('@modules/user-settings/user-settings.module').then((m) => m.UserSettingsModule),
    path: 'account',
  },
  {
    loadChildren: () => import('@modules/users/users.module').then((m) => m.UsersModule),
    path: 'users',
  },
  {
    loadChildren: () => import('@modules/funnels/funnels.module').then((m) => m.FunnelsModule),
    path: 'funnels',
  },
  {
    loadChildren: () => import('@modules/tactics/tactics.module').then((m) => m.TacticsModule),
    path: 'tactics',
  },
  {
    loadChildren: () => import('@modules/home/home-page.module').then((m) => m.HomePageModule),
    path: 'home',
  },
  {
    path: 'prompt',
    canActivate: [PromptCanActivateGuard],
    loadChildren: () => import('@modules/prompt/prompt.module').then((m) => m.PromptModule),
  },
  {
    path: 'marketing-campaign',
    loadChildren: () =>
      import('@modules/marketing-campaign/marketing-campaign.module').then((m) => m.MarketingCampaignModule),
  },
  {
    path: 'marketing-campaing',
    loadChildren: () =>
      import('@modules/marketing-campaign/marketing-campaign.module').then((m) => m.MarketingCampaignModule),
  },
  {
    path: 'competition-comparison',
    loadChildren: () =>
      import('@modules/competition-comparison/competition-comparison.module').then(
        (m) => m.CompetitionComparisonModule,
      ),
  },
  {
    path: 'payment/:status',
    component: PaymentStatusComponent,
    resolve: {
      globalData: PermissionsResolver,
    },
  },
  {
    path: 'business-goals',
    loadChildren: () => import('@modules/business-goals/business-goals.module').then((m) => m.BusinessGoalsModule),
  },
  {
    path: 'tiptap',
    loadChildren: () => import('@modules/tiptap/tiptap.module').then((m) => m.TiptapModule),
  },
  {
    path: 'master-visuals',
    loadChildren: () => import('@modules/master-visuals/master-visuals.module').then((m) => m.MasterVisualsModule),
  },
  {
    path: 'campaign',
    loadChildren: () => import('@modules/campaign/campaign.module').then((m) => m.CampaignModule),
  },
  { path: 'xp-no-auth', component: PartnerNoAuthComponent },
  { path: '404', component: Page404Component },
  { path: '**', redirectTo: '/404' },

  // {
  //   children: TailwindTestRoutes,
  //   path: 'tailwind',
  // },
];
