<button
  *ngIf="!this.userService.User?.isSemrushPlan()"
  tuiIconButton
  type="button"
  size="xl"
  class="onboarding-btn float-button fixed bottom-20 md:bottom-28 z-50 right-6 rounded-full shadow-lg"
  (click)="showDialog(template)"
>
  ?
</button>

<ng-template
  #template
  let-observer
>
  <iframe
    [src]="src | sanitizeResourceUrl"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay;  encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
    class="m-auto video-iframe"
  ></iframe>
</ng-template>
