<df-full-list-loader *ngIf="loading$ | async"></df-full-list-loader>

<ng-container *ngIf="access; else noAccess">
  <div
    *ngIf="(loading$ | async) === false"
    class="w-full h-full pt-6 pr-4 flex flex-row tui-base-03 relative"
  >
    <div
      class="close-btn absolute tui-text_body-m-12 cursor-pointer sm:top-7 sm:right-7 xl:top-10 xl:right-10"
      (click)="close()"
    >
      <svg-icon [src]="'/assets/img/ic-close.svg'"></svg-icon>
    </div>
    <div class="edit-url-btn absolute tui-text_body-m-12 cursor-pointer sm:top-7 sm:right-12 xl:top-10">
      <button
        type="button"
        tuiButton
        appearance="outline"
        class="mr-2"
        (click)="clearForm()"
      >
        {{ 'Funnels.Canvases.Clear' | translate }}
      </button>
      <button
        appearance="outline"
        tuiButton
        (click)="n.go('funnels/f/d/:id/business-data', { id: funnelId })"
      >
        Edit URL
      </button>
    </div>
    <div class="w-1/5 min-w-[220px] px-4 mb-10">
      <form
        [formGroup]="searchForm"
        class="flex items-center h-8 w-full rounded-lg px-2 bg-white"
      >
        <svg-icon
          [src]="'/assets/img/ic-search.svg'"
          [svgStyle]="{ width: '1rem', height: '1rem' }"
          class="tui-base-06 mr-4 cursor-pointer"
        >
        </svg-icon>
        <tui-input-inline
          formControlName="search"
          class="w-full"
        >
          {{ 'Funnels.Canvases.search' | translate }}
        </tui-input-inline>
      </form>

      <hr class="mt-4 tui-base-05 opacity-25" />
      <div
        class="categories"
        *ngIf="displayedCategories.length"
      >
        <div *ngFor="let cat of displayedCategories; let i = index">
          <ng-container *ngIf="cat.display">
            <div
              class="pb-4 category-name"
              [ngClass]="i === 0 ? 'mt-5' : 'mt-8'"
            >
              {{ cat.name | firstUpper }}
            </div>
            <button
              *ngFor="let canva of cat.canvases"
              (click)="selectCanvasTemplate(canva.id)"
              class="p-2 mb-4 w-full sm:h-full xl:h-12 rounded border border-solid bg-white canva-name flex justify-between items-center break-all"
              [class.focused-button]="canva.id === selectedCanvas?.id"
            >
              <span class="canvas-btn-span">{{ canva.name }}</span>
              <svg-icon
                *ngIf="!!canva?.prompt"
                [src]="'/assets/img/robot.svg'"
              ></svg-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <div
      *ngIf="selectedCanvas"
      class="w-4/5 rounded-lg p-6 border bg-white canvas-form"
    >
      <div
        class="sm:w-full xl:w-1/2"
        id="pdf-container"
      >
        <div class="head-title mb-4">
          {{ selectedCanvas.name }}
        </div>
        <div class="head-text mb-10">
          {{ selectedCanvas.description }}
        </div>

        <form
          *ngIf="form"
          [formGroup]="form"
          (submit)="submitForm()"
        >
          <div
            formArrayName="positions"
            *ngIf="!!form.controls.positions.value.length"
          >
            <div
              *ngFor="let position of positions.controls; let i = index"
              class="py-3 mb-3"
            >
              <div class="flex items-center pb-7 position-title">
                {{ position.value.label | titlecase }}
                <svg-icon
                  [tuiHint]="descriptionTooltip"
                  [src]="'/assets/img/ic_question_circle.svg'"
                  [svgStyle]="{ width: '1rem', height: '1rem' }"
                  class="tui-base-06 cursor-pointer ml-1 mb-1"
                >
                </svg-icon>
                <ng-template #descriptionTooltip>
                  <div>{{ position.value.description }}</div>
                </ng-template>
              </div>
              <df-canvas-position [position]="position"></df-canvas-position>
            </div>
          </div>
          <div class="xl:flex xl:sticky xl:bottom-0">
            <div class="sm:ml-auto mr">
              <button
                *ngIf="selectedCanvas?.prompt"
                (click)="getSuggestionsIfCompanyDataSet(selectedCanvas.id)"
                type="button"
                tuiButton
                appearance="outline"
                class="mr-2"
              >
                {{ 'Funnels.Canvases.aiGenerate' | translate }}
              </button>
              <button
                (click)="generatePDF()"
                type="button"
                tuiButton
                appearance="outline"
                class="mr-2"
              >
                {{ 'Funnels.Canvases.PDFGenerate' | translate }}
              </button>
              <button
                class="w-2/12 disabled:cursor-not-allowed"
                tuiButton
                type="submit"
                [disabled]="!form.valid"
              >
                {{ 'save' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noAccess>
  <div class="w-full h-full overflow-y-auto py-5 px-4 md:px-6 lg:px-7.5 tui-base-02">
    <h3 class="text-base md:text-xl font-semibold tui-text-03 mb-4 mt-0">
      {{ 'Funnels.canvases' | translate }}
    </h3>
    <df-funnel-no-access-banner>
      <span [innerHTML]="'Funnels.Canvases.You need' | translate: { permission: permissionName }"></span>
      <span>{{ 'Funnels.canvases' | translate }}</span
      >.
    </df-funnel-no-access-banner>
  </div>
</ng-template>

<df-onboarding-video [src]="'https://www.youtube.com/embed/ZtcoDffaFWc?si=npAAX-e8Tvfow_Te'"></df-onboarding-video>
