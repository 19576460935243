import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'df-tiptap',
  templateUrl: './tiptap.component.html',
  styleUrl: './tiptap.component.scss',
})
export class TiptapComponent implements OnInit {
  funnelId!: number;
  goalId!: string;

  constructor(
    private route: ActivatedRoute,
    private readonly n: NavigateService,
  ) {}

  ngOnInit() {
    this.funnelId = Number(this.route?.parent?.snapshot.params['id']);
    this.goalId = this.route.snapshot.queryParams.goalId;
  }

  onNextButtonClick() {
    this.n.go(`/funnels/f/d/${this.funnelId}/master-visuals`, { id: this.funnelId });
  }
}
