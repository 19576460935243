import { XPartnerService } from '@shared/services/x-partner.service';
import { Partners } from '@shared/enums/partners.enum';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { PricingGraphqlService } from '@modules/pricing/shared/services/pricing-graphql.service';
import { FetchResult } from '@apollo/client/core';
import { GetSubscriptionsPlansQuery } from '@modules/pricing/shared/graphql/queries/get-subscriptions-plans.query.generated';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import {
  GetResponseEvent,
  PaymentIntervalEnum,
  PlanPermissionOutputGraphql,
  PriceOutputGraphql,
  SubscriptionPlanOutputGraphql,
} from '@modules/graphql/graphql-types';
import { GlobalDataService } from '@shared/services/global-data.service';
import { CreateSubscriptionMutation } from '@modules/pricing/shared/graphql/mutations/create-subscription.mutation.generated';
import { UserService } from '@shared/services/user.service';
import { Permission } from '@shared/models/permission.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Config } from '@shared/configs/config';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { AuthModalComponent } from '@modules/public/shared/components/register-modal/auth-modal.component';
import { filter, map, tap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { PricingPromotionCodeModalComponent } from '@modules/pricing/shared/components/pricing-promotion-code-modal/pricing-promotion-code-modal.component';
import { GetAppSumoSubscriptionsPlansQuery } from '@modules/pricing/shared/graphql/queries/get-app-sumo-subscriptions-plans.query.generated';
import { GetResponseEventsService } from '@shared/services/get-response-events.service';
import { GenerateSubscriptionsManagementUrlMutation } from '@shared/graphql/mutations/generate-subscriptions-management-url.mutation.generated';
import isMobile from 'is-mobile';
import { throttle } from 'helpful-decorators';
import { LayoutService } from '@shared/services/layout.service';
import { LayoutConfig } from '@shared/interfaces/layout-config.interface';
import { LayoutViewType } from '@shared/enums/layout-view-type.enum';

@Component({
  selector: 'df-packets',
  templateUrl: './packets.component.html',
  styleUrls: ['./packets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PacketsComponent implements OnInit, OnDestroy {
  readonly Config = Config;
  readonly Partners = Partners;
  readonly PaymentIntervalEnum = PaymentIntervalEnum;

  @Input() public = false;

  plans: SubscriptionPlanOutputGraphql[] = [];
  layoutConfig: LayoutConfig;
  LayoutViewType = LayoutViewType;
  loading = true;
  buyPlanLoading = false;
  userCurrentPlansIds: number[] = [];
  permissions: Permission[];
  credits: (number | string)[] = [];
  creditsList: (number | string)[] = [1200, 2500, 'Unlimited'];
  creditsListAppsumo: number[] = [400, 1200, 2500];
  buyFromParam = true;
  wentToCheckout = false;
  defaultTrialPlan: SubscriptionPlanOutputGraphql | null = null;
  urlToPayments: string | null = null;
  planNameMap: Map<number, boolean> = new Map<number, boolean>();
  planeNameArray: boolean[] = [];

  protected subscriptions: Subscription[] = [];

  get appSumo(): boolean {
    return (this.userService.User?.activePlans.filter((p) => p.name.includes('AppSumo')).length ?? 0) > 0;
  }

  get usedTrial(): boolean {
    return !!this.userService.User?.usedTrial;
  }

  get usingTrial(): boolean {
    return this.userService.User?.trialDaysLeft !== null;
  }

  get trialEnded(): boolean | null {
    return this.userService.User?.recentlyEndedTrial ?? null;
  }

  get noActivePlan(): boolean {
    return !!this.userService.User && !this.userService.User!.hasActivePlan();
  }

  constructor(
    public pricingService: PricingService,
    private pricingGraphqlService: PricingGraphqlService,
    public globalData: GlobalDataService,
    private s: SnackbarService,
    private t: TranslateService,
    public n: NavigateService,
    private changes: ChangeDetectorRef,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    public xPartnerService: XPartnerService,
    private readonly getResponseEventsService: GetResponseEventsService,
    public layoutService: LayoutService,
  ) {
    this.layoutConfig = this.layoutService.onResize.value;
    this.subscriptions.push(
      this.layoutService.onResize.pipe().subscribe((value: LayoutConfig) => (this.layoutConfig = value)),
    );
    this.permissions = this.globalData.permissions.filter((item) =>
      this.appSumo ? item.isAvailableInAppSumo : item.isAvailable,
    );
  }

  ngOnInit(): void {
    this.pricingService.initializePaymentInterval();
    this.loading = true;
    this.changes.detectChanges();
    if (!this.userService.User) {
      const sub = this.getPackets();
      sub.add(() => (this.public ? this._checkBuyParam() : (this.buyFromParam = false)));
      sub.add(() => this.setLoader(false));
    } else {
      const sub = this.userService.getMe();
      sub.add(() => this._sendSeenPricingEvent());
      sub.add(() => this.getPackets().add(() => (this.public ? this._checkBuyParam() : (this.buyFromParam = false))));

      this.userService
        .generateSubscriptionManagementUrl()
        .subscribe({
          next: (res: FetchResult<GenerateSubscriptionsManagementUrlMutation>) => {
            this.urlToPayments = res.data?.generateSubscriptionsManagementUrl.redirectUrl!;
            this.changes.detectChanges();
          },
        })
        .add(() => this.setLoader(false));
    }
    this.setCredits();
  }

  private setLoader(value: boolean): void {
    this.loading = value;
    this.changes.detectChanges();
  }

  calculateSavePrice(prices: PriceOutputGraphql[]) {
    const monthPrice = prices.find((p) => p.paymentInterval === PaymentIntervalEnum.Month)!;
    const yearPrice = prices.find((p) => p.paymentInterval === PaymentIntervalEnum.Year)!;

    if (!monthPrice?.amount || !yearPrice?.amount) {
      return null;
    }

    return monthPrice.amount * 12 - yearPrice.amount;
  }

  getTrialText() {
    return 'Pricing.Start your free trial';
  }

  calculateYearPrice(amount: number) {
    return String(amount * 12).replace(',', '');
  }

  setCredits() {
    this.credits = this.appSumo ? this.creditsListAppsumo : this.creditsList;
  }

  onHoverAccess(index: number) {
    this.clearAllHighlighted();
    const toHighlight = document.querySelectorAll(`[data-access-id="${index}"]`);
    toHighlight.forEach((el) => {
      el.classList.add('hovered');
    });
  }

  clearAllHighlighted() {
    const highlighted = document.querySelectorAll('.packets__info-list__access.hovered');
    highlighted.forEach((el) => {
      el.classList.remove('hovered');
    });
  }

  getPackets(): Subscription {
    const request: Observable<SubscriptionPlanOutputGraphql[]> = this.appSumo
      ? this.pricingGraphqlService
          .getAppSumoPackets()
          .pipe(
            map(
              (res: FetchResult<GetAppSumoSubscriptionsPlansQuery>) =>
                res.data?.getAppSumoSubscriptionsPlans as SubscriptionPlanOutputGraphql[],
            ),
          )
      : this.pricingGraphqlService
          .getPackets()
          .pipe(
            map(
              (res: FetchResult<GetSubscriptionsPlansQuery>) =>
                res.data?.getSubscriptionsPlans as SubscriptionPlanOutputGraphql[],
            ),
          );

    return request.subscribe({
      next: (res: SubscriptionPlanOutputGraphql[]) => {
        this.plans = res;
        res.map(() => this.planeNameArray.push(false));

        this.defaultTrialPlan = this.plans.find((p) => p.name.includes(Config.DEFAULT_TRIAL_PLAN)) ?? null;
        this._setPacketsIdsToPermissions();
        this._setCurrentUserActivePlans();
        this.changes.detectChanges();
      },
      error: () => {
        this.s.error(this.t.instant('Pricing.There is problem with getting packets. Try again.'));
      },
    });
  }

  /**
   * Get price for specified pricingService.paymentInterval: PaymentIntervalEnum
   */
  getPriceForInterval(prices: PriceOutputGraphql[], isUltimateProduct = false): PriceOutputGraphql | null {
    if (isUltimateProduct) return prices.length ? prices[0] : null;
    const paymentIntervalValue = this.appSumo ? PaymentIntervalEnum.Lifetime : this.pricingService.paymentInterval;
    return prices.find((p) => p.paymentInterval === paymentIntervalValue) ?? null;
  }

  getIntegerMonthlyPriceAmount(amount: number, isOnePaymentProduct: boolean) {
    const paymentIntervalValue = this.appSumo ? PaymentIntervalEnum.Lifetime : this.pricingService.paymentInterval;

    if (isOnePaymentProduct) return amount;

    switch (paymentIntervalValue) {
      case PaymentIntervalEnum.Month:
        return amount;
      case PaymentIntervalEnum.Year:
        if (Math.floor(amount / 12 / 100) < 70) {
          return 67;
        } else {
          return 99;
        }

      case PaymentIntervalEnum.Lifetime:
        return amount;
    }
  }

  private _setCurrentUserActivePlans() {
    this.userCurrentPlansIds = [];
    this.userService.User?.activePlans.map((p: SubscriptionPlanOutputGraphql) => {
      this.userCurrentPlansIds.push(p.id);
    });
  }

  isPlanActive(plan: SubscriptionPlanOutputGraphql) {
    /**
     * Special behewior for plan 501 - Ultimate with NFC, Test AB
     */
    if (plan.strength === 501) return false;

    const paymentIntervalValue = this.appSumo
      ? PaymentIntervalEnum.Lifetime
      : this.getPriceForInterval(plan.prices)!.paymentInterval;

    const userCurrentPlanPaymentInterval = this.appSumo
      ? PaymentIntervalEnum.Lifetime
      : this.userService.User?.activeSubscription.price.paymentInterval || '';

    return this.userCurrentPlansIds.indexOf(plan.id) !== -1 && paymentIntervalValue === userCurrentPlanPaymentInterval;
  }

  private _setPacketsIdsToPermissions() {
    this.globalData.permissions.map((p) => {
      this.plans.map((plan: SubscriptionPlanOutputGraphql) => {
        const foundPermissions: PlanPermissionOutputGraphql[] = plan.permissions.filter(
          (per) => per.permissionType.id === p.id,
        );
        if (foundPermissions.length) {
          p.planPermissions[plan.id] = foundPermissions[0];
        }
      });
    });
  }

  choosePlan(plan: SubscriptionPlanOutputGraphql, promoCode = false): void {
    this.getLoggedInStatus(plan, !this.usedTrial)
      .pipe(tap(() => this.changes.detectChanges()))
      .subscribe(() =>
        promoCode
          ? this._usePromotionalCode(plan, !this.usedTrial)
          : this.handlePlanChoice(plan, undefined, !this.usedTrial),
      );
  }

  private getLoggedInStatus(plan: SubscriptionPlanOutputGraphql, trial = false): Observable<boolean> {
    let logged$: Observable<boolean> = of(true);
    if (this.public && !this.userService.User) {
      this.buyPlanLoading = false;
      this.changes.detectChanges();
      logged$ = this.dialogService
        .open<boolean>(new PolymorpheusComponent(AuthModalComponent, this.injector), {
          size: this.buyFromParam ? 'fullscreen' : 'm',
          data: {
            plan,
            trial,
          },
          dismissible: !this.buyFromParam,
          closeable: !this.buyFromParam,
        })
        .pipe(
          filter((authorized) => authorized),
          tap(() => this._setCurrentUserActivePlans()),
        );
    }
    return logged$;
  }

  handlePlanChoice(plan: SubscriptionPlanOutputGraphql, promoCode?: string, trial = false): void {
    if (!trial && !(this.usingTrial && !this.urlToPayments) && !this.noActivePlan) {
      this.goToPaymentSettings();
      return;
    }

    this.buyPlanLoading = true;
    this.changes.detectChanges();
    trial ? this._saveTrial(plan) : '';
    this._saveUrl();
    if (trial && this.usedTrial) {
      if (this.usingTrial) {
        this.wentToCheckout = true;
        this.n.router.navigate(['payment/success']);
      } else if (this.trialEnded) {
        this.s.error(
          this.t.instant(
            'Pricing.You have already used your trial version of Digitalfirst.ai. Purchase subscription plan to continue using the platform.',
          ),
        );
      } else {
        this.s.defaultError();
      }
    } else {
      this._buyPlan(plan, promoCode, trial);
    }
  }

  private _buyPlan(plan: SubscriptionPlanOutputGraphql, promoCode?: string, trial = false) {
    const price = this.getPriceForInterval(plan.prices, plan.strength === 501);
    this.pricingGraphqlService.createSubscription(plan.id, promoCode, trial, price!.id).subscribe({
      next: (res: FetchResult<CreateSubscriptionMutation>) => {
        this.wentToCheckout = true;
        window.location.href = res.data?.createSubscription.redirectUrl!;
      },
      error: () => {
        this.s.error(this.t.instant('Pricing.There is problem with create subscription plan. Try again.'));
        this.buyPlanLoading = false;
        this.changes.detectChanges();
      },
    });
  }

  goToPaymentSettings(): void {
    if (!this.urlToPayments) return;
    window.open(this.urlToPayments, '_self');
  }

  private _saveTrial(plan: SubscriptionPlanOutputGraphql): void {
    this.userService.saveSelectedTrial(plan);
  }

  private _saveUrl(): void {
    const statementUrl: RegExpMatchArray | null = this.router.url.match(/\(statement:[^()]+\)/);
    this.userService.saveBeforePaymentUrl(window.location.pathname, statementUrl ? statementUrl[0] : undefined);
  }

  private _checkBuyParam(): void {
    const toBuyId: number | undefined = +this.route.snapshot.queryParams['toBuy'];
    if (!isNaN(toBuyId)) {
      const toBuy: SubscriptionPlanOutputGraphql | undefined = this.plans.find((item) => item.id === toBuyId);
      if (toBuy) {
        this.buyFromParam = true;
        this.buyPlanLoading = true;
        this.changes.detectChanges();
        this.choosePlan(toBuy);
        return;
      }
    }
    this.buyFromParam = false;
    this.n.go('public/pricing');
  }

  private _usePromotionalCode(plan: SubscriptionPlanOutputGraphql, trial = false): void {
    this.dialogService
      .open<boolean>(new PolymorpheusComponent(PricingPromotionCodeModalComponent, this.injector), {
        size: 's',
        data: {
          plan,
          packetsComponent: this,
          trial,
        },
        dismissible: true,
        closeable: true,
      })
      .subscribe();
  }

  goToFunnel(): void {
    this.router.navigate([{ outlets: { statement: null, pricing: null } }]).then(() => {
      if (this.userService.User) {
        this.n.go('funnels/f/d/:id', {
          id: this.userService.User.contextFunnel.id,
        });
      } else {
        this.n.go('sign-up');
      }
    });
  }

  openIntercom(): void {
    window['Intercom']('show');
  }

  private _sendSeenPricingEvent(): void {
    if (this.userService.User) {
      this.getResponseEventsService.triggerGetResponseEvent(
        this.userService.User.hasActivePlan()
          ? GetResponseEvent.UserWithPlanSeenPricing
          : GetResponseEvent.UserWithoutPlanSeenPricing,
      );
    }
  }

  public get checkMobile() {
    return isMobile();
  }

  toggleMobileDescription(index: number): void {
    this.planeNameArray[index] = !this.planeNameArray[index];
  }

  ngOnDestroy(): void {
    if (this.userService.User && !this.wentToCheckout) {
      this.getResponseEventsService.triggerGetResponseEvent(GetResponseEvent.UserSeenPricingWithoutBuying);
    }
    this.subscriptions.forEach((item: Subscription) => item.unsubscribe());
  }

  @HostListener('window:resize', ['$event'])
  @throttle(400)
  onResize() {
    this.checkMobile;
  }
}
