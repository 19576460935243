import { EventEmitter, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  EditOutputDataInputGraphql,
  FlowInputGraphql,
  FunnelInputGraphql,
  FunnelPubliclyInputGraphql,
  GenerateContentInputGraphql,
  InputsDataListInputGraphql,
  IntegrationTypeEnum,
  OutputsDataListInputGraphql,
} from '@modules/graphql/graphql-types';
import { CreateFunnelMutation } from '@modules/funnels/shared/graphql/mutations/create-funnel.mutation.generated';
import { CreateFunnelDocument } from '@modules/funnels/shared/graphql/mutations/create-funnel.mutation';
import { GetFunnelDocument } from '@modules/funnels/shared/graphql/queries/get-funnel.query';
import { Observable } from 'rxjs';
import { FetchResult } from '@apollo/client/core';
import { FilterStepsQuery } from '@modules/funnels/shared/graphql/queries/filter-funnel-steps.query.generated';
import { FilterFunnelStepsDocument } from '@modules/funnels/shared/graphql/queries/filter-funnel-steps.query';
import { EditFunnelMutation } from '@modules/funnels/shared/graphql/mutations/edit-funnel.mutation.generated';
import { EditFunnelDocument } from '@modules/funnels/shared/graphql/mutations/edit-funnel.mutation';
import { CreateFunnelImageMutation } from '@modules/funnels/shared/graphql/mutations/create-funnel-image.mutation.generated';
import { CreateFunnelImageDocument } from '@modules/funnels/shared/graphql/mutations/create-funnel-image.mutation';
import { GetFunnelQuery } from '@modules/funnels/shared/graphql/queries/get-funnel.query.generated';
import { CreateFlowMutation } from '@modules/funnels/shared/graphql/mutations/create-flow.mutation.generated';
import { CreateFlowDocument } from '@modules/funnels/shared/graphql/mutations/create-flow.mutation';
import { EditFlowMutation } from '@modules/funnels/shared/graphql/mutations/edit-flow.mutation.generated';
import { EditFlowDocument } from '@modules/funnels/shared/graphql/mutations/edit-flow.mutation';
import { AddFunnelToFavouritesMutation } from '@modules/funnels/shared/graphql/mutations/add-funnel-to-favourite.mutation.generated';
import { AddFunnelToFavouriteDocument } from '@modules/funnels/shared/graphql/mutations/add-funnel-to-favourite.mutation';
import { RemoveFunnelFromFavouritesMutation } from '@modules/funnels/shared/graphql/mutations/remove-funnel-from-favourite.mutation.generated';
import { RemoveFunnelFromFavouriteDocument } from '@modules/funnels/shared/graphql/mutations/remove-funnel-from-favourite.mutation';
import { RemoveFunnelMutation } from '@modules/funnels/shared/graphql/mutations/remove-funnel.mutation.generated';
import { RemoveFunnelDocument } from '@modules/funnels/shared/graphql/mutations/remove-funnel.mutation';
import { CloneFunnelMutation } from '@modules/funnels/shared/graphql/mutations/clone-funnel.mutation.generated';
import { CloneFunnelDocument } from '@modules/funnels/shared/graphql/mutations/clone-funnel.mutation';
import { ShareFunnelMutation } from '@modules/funnels/shared/graphql/mutations/share-funnel.mutation.generated';
import { ShareFunnelDocument } from '@modules/funnels/shared/graphql/mutations/share-funnel.mutation';
import { RemoveFunnelShareMutation } from '@modules/funnels/shared/graphql/mutations/remove-share-funnel.mutation.generated';
import { RemoveShareFunnelDocument } from '@modules/funnels/shared/graphql/mutations/remove-share-funnel.mutation';
import { RemoveFlowMutation } from '@modules/funnels/shared/graphql/mutations/remove-flow.mutation.generated';
import { RemoveFlowDocument } from '@modules/funnels/shared/graphql/mutations/remove-flow.mutation';
import { GetFunnelByPromotionCodeQuery } from '@modules/funnels/shared/graphql/queries/get-funnel-by-promotion-code.query.generated';
import { GetFunnelByPromotionCodeDocument } from '@modules/funnels/shared/graphql/queries/get-funnel-by-promotion-code.query';
import { CopyFunnelContentMutation } from '@modules/funnels/shared/graphql/mutations/copy-funnel-content.mutation.generated';
import { CopyFunnelContentDocument } from '@modules/funnels/shared/graphql/mutations/copy-funnel-content.mutation';
import { GetFunnelStatisticsQuery } from '@modules/funnels/shared/graphql/queries/get-funnel-statistics.query.generated';
import { GetFunnelStatisticsDocument } from '@modules/funnels/shared/graphql/queries/get-funnel-statistics.query';
import { GetIntegrationInputsDataQuery } from '@modules/funnels/shared/graphql/queries/get-integration-inputs-data.query.generated';
import { GetIntegrationInputsDataDocument } from '@modules/funnels/shared/graphql/queries/get-integration-inputs-data.query';
import { GetFunnelTacticQuery } from '@modules/funnels/shared/graphql/queries/get-funnel-tactic.query.generated';
import { GetFunnelTacticDocument } from '@modules/funnels/shared/graphql/queries/get-funnel-tactic.query';
import { GenerateContentMutation } from '@modules/funnels/shared/graphql/mutations/generate-content.mutation.generated';
import { GenerateContentDocument } from '@modules/funnels/shared/graphql/mutations/generate-content.mutation';
import { MarkOutputDataAsSelectedMutation } from '@modules/funnels/shared/graphql/mutations/mark-output-data-as-selected.mutation.generated';
import { MarkOutputDataAsSelectedDocument } from '@modules/funnels/shared/graphql/mutations/mark-output-data-as-selected.mutation';
import { EditOutputDataMutation } from '@modules/funnels/shared/graphql/mutations/edit-output-data.mutation.generated';
import { EditOutputDataDocument } from '@modules/funnels/shared/graphql/mutations/edit-output-data.mutation';
import { RemoveOutputDataMutation } from '@modules/funnels/shared/graphql/mutations/remove-output-data.mutation.generated';
import { RemoveOutputDataDocument } from '@modules/funnels/shared/graphql/mutations/remove-output-data.mutation';
import { GetIntegrationOutputsDataQuery } from '@modules/funnels/shared/graphql/queries/get-integration-outputs-data.query.generated';
import { GetIntegrationOutputsDataDocument } from '@modules/funnels/shared/graphql/queries/get-integration-outputs-data.query';
import { GetLastGeneratedIntegrationInputsDataQuery } from '@modules/funnels/shared/graphql/queries/get-last-generated-integration-inputs-data.query.generated';
import { GetLastGeneratedIntegrationInputsDataDocument } from '@modules/funnels/shared/graphql/queries/get-last-generated-integration-inputs-data.query';
import { GetBannerbearIntegrationsQuery } from '@modules/funnels/shared/graphql/queries/get-bannerbear-integrations.query.generated';
import { GetBannerbearIntegrationsDocument } from '@modules/funnels/shared/graphql/queries/get-bannerbear-integrations.query';
import { GenerateImagesForFunnelMutation } from '@modules/funnels/shared/graphql/mutations/generate-images-for-funnel.mutation.generated';
import { GenerateImagesForFunnelDocument } from '@modules/funnels/shared/graphql/mutations/generate-images-for-funnel.mutation';
import { GenerateDefaultImagesMutation } from '@modules/funnels/shared/graphql/mutations/generate-default-images.mutation.generated';
import { GenerateDefaultImagesDocument } from '@modules/funnels/shared/graphql/mutations/generate-default-images.mutation';
import { CreateContentGenerationImageMutation } from '@modules/funnels/shared/graphql/mutations/create-content-generation-image.mutation.generated';
import { CreateContentGenerationImageDocument } from '@modules/funnels/shared/graphql/mutations/create-content-generation-image.mutation';
import { DownloadOutputsDataZipMutation } from '@modules/funnels/shared/graphql/mutations/download-outputs-data-zip.mutation.generated';
import { DownloadOutputsDataZipDocument } from '@modules/funnels/shared/graphql/mutations/download-outputs-data-zip.mutation';
import { GetOutputsDataQuery } from '@modules/funnels/shared/graphql/queries/get-outputs-data.query.generated';
import { GetOutputsDataDocument } from '@modules/funnels/shared/graphql/queries/get-outputs-data.query';
import { GetOnboardingFunnelQuery } from '@modules/funnels/shared/graphql/queries/get-onboarding-funnel.query.generated';
import { GetOnboardingFunnelDocument } from '@modules/funnels/shared/graphql/queries/get-onboarding-funnel.query';
import { GetContentGenerationIntegrationsQuery } from '@modules/funnels/modules/funnel-manage/shared/graphql/queries/get-content-generation-integrations.query.generated';
import { GetContentGenerationIntegrationsDocument } from '@modules/funnels/modules/funnel-manage/shared/graphql/queries/get-content-generation-integrations.query';
import { GetIntegrationsQuery } from '../graphql/queries/get-integrations.query.generated';
import { GetIntegrationsDocument } from '../graphql/queries/get-integrations.query';
import { GetLastOutputsDataQuery } from '../graphql/queries/get-last-outputs-data.query.generated';
import { GetLastOutputsDataDocument } from '../graphql/queries/get-last-outputs-data.query';
import { GetPublicFunnelByIdQuery } from '../graphql/queries/get-public-funnel-by-id.query.generated';
import { GetPublicFunnelByIdDocument } from '../graphql/queries/get-public-funnel-by-id.query';
import { SetFunnelPubliclyDocument } from '../graphql/mutations/funnel-publicly.mutation';
import { SetFunnelPubliclyMutation } from '../graphql/mutations/funnel-publicly.mutation.generated';

import { CloneFunnelForAnonymousUserMutation } from '../graphql/mutations/clone-funnel-anonumous-user.mutation.generated';
import { CloneFunnelForAnonymousUserDocument } from '../graphql/mutations/clone-funnel-anonumous-user.mutation';
import { SetFunnelToOwnerDocument } from '../graphql/mutations/set-funnel-to-owner.mutation';
import { SetFunnelToOwnerMutation } from '../graphql/mutations/set-funnel-to-owner.mutation.generated';
import { FunnelGeneratePdfQuery } from '@modules/funnels/modules/funnel-manage/shared/graphql/queries/generate-pdf.query.generated';
import { FunnelGeneratePDFDocument } from '@modules/funnels/modules/funnel-manage/shared/graphql/queries/generate-pdf.query';

@Injectable({
  providedIn: 'root',
})
export class FunnelGraphqlService {
  refreshEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private apollo: Apollo) {}

  generatePDF(funnelId: number, logo: string) {
    return this.apollo.query<FunnelGeneratePdfQuery>({
      query: FunnelGeneratePDFDocument,
      variables: {
        funnelId,
        logo,
      },
      fetchPolicy: 'no-cache',
    });
  }

  createFunnel(input: FunnelInputGraphql, file?: File): Observable<FetchResult<CreateFunnelMutation>> {
    const variables = { input };
    file ? (variables['file'] = file) : '';
    return this.apollo.mutate<CreateFunnelMutation>({
      mutation: CreateFunnelDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        useMultipart: true,
      },
    });
  }

  setFunnelToOwner(funnelId: string) {
    return this.apollo.mutate<SetFunnelToOwnerMutation>({
      mutation: SetFunnelToOwnerDocument,
      variables: {
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  editFunnel(input: FunnelInputGraphql, funnelId: number): Observable<FetchResult<EditFunnelMutation>> {
    return this.apollo.mutate<EditFunnelMutation>({
      mutation: EditFunnelDocument,
      variables: {
        input,
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  setPublicly(input: FunnelPubliclyInputGraphql): Observable<FetchResult<SetFunnelPubliclyMutation>> {
    return this.apollo.mutate<SetFunnelPubliclyMutation>({
      mutation: SetFunnelPubliclyDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  createFunnelImage(funnelId: number, file: File): Observable<FetchResult<CreateFunnelImageMutation>> {
    return this.apollo.mutate<CreateFunnelImageMutation>({
      mutation: CreateFunnelImageDocument,
      variables: {
        funnelId,
        file,
      },
      fetchPolicy: 'no-cache',
      context: {
        useMultipart: true,
      },
    });
  }

  getFunnel(funnelId: number): Observable<FetchResult<GetFunnelQuery>> {
    return this.apollo.query<GetFunnelQuery>({
      query: GetFunnelDocument,
      variables: {
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getPublicFunnelById(funnelId: number): Observable<FetchResult<GetPublicFunnelByIdQuery>> {
    return this.apollo.query<GetPublicFunnelByIdQuery>({
      query: GetPublicFunnelByIdDocument,
      variables: {
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getFunnelByPromotionCode(promotionCode: string): Observable<FetchResult<GetFunnelByPromotionCodeQuery>> {
    return this.apollo.query<GetFunnelByPromotionCodeQuery>({
      query: GetFunnelByPromotionCodeDocument,
      variables: {
        promotionCode,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getOnboardingFunnel(): Observable<FetchResult<GetOnboardingFunnelQuery>> {
    return this.apollo.query<GetOnboardingFunnelQuery>({
      query: GetOnboardingFunnelDocument,
      fetchPolicy: 'no-cache',
    });
  }

  getSteps(): Observable<FetchResult<FilterStepsQuery>> {
    return this.apollo.query<FilterStepsQuery>({
      query: FilterFunnelStepsDocument,
      variables: {
        input: {
          records: 100,
          page: 1,
        },
      },
    });
  }

  createFlow(input: FlowInputGraphql, funnelId: number): Observable<FetchResult<CreateFlowMutation>> {
    return this.apollo.mutate<CreateFlowMutation>({
      mutation: CreateFlowDocument,
      variables: {
        input,
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  editFlow(input: FlowInputGraphql, flowId: number): Observable<FetchResult<EditFlowMutation>> {
    return this.apollo.mutate<EditFlowMutation>({
      mutation: EditFlowDocument,
      variables: {
        input,
        flowId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeFlow(flowId: number): Observable<FetchResult<RemoveFlowMutation>> {
    return this.apollo.mutate<RemoveFlowMutation>({
      mutation: RemoveFlowDocument,
      variables: {
        flowId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  addFunnelToFavourites(funnelId: number): Observable<FetchResult<AddFunnelToFavouritesMutation>> {
    return this.apollo.mutate<AddFunnelToFavouritesMutation>({
      mutation: AddFunnelToFavouriteDocument,
      variables: {
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeFunnelFromFavourites(funnelId: number): Observable<FetchResult<RemoveFunnelFromFavouritesMutation>> {
    return this.apollo.mutate<RemoveFunnelFromFavouritesMutation>({
      mutation: RemoveFunnelFromFavouriteDocument,
      variables: {
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeFunnel(funnelId: number): Observable<FetchResult<RemoveFunnelMutation>> {
    return this.apollo.mutate<RemoveFunnelMutation>({
      mutation: RemoveFunnelDocument,
      variables: {
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  cloneFunnel(funnelId: number): Observable<FetchResult<CloneFunnelMutation>> {
    return this.apollo.mutate<CloneFunnelMutation>({
      mutation: CloneFunnelDocument,
      variables: {
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  cloneFunnelForAnonymousUser(funnelId: number) {
    return this.apollo.mutate<CloneFunnelForAnonymousUserMutation>({
      mutation: CloneFunnelForAnonymousUserDocument,
      variables: {
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  copyFunnelContent(
    destinationFunnelId: number,
    funnelToCopyId: number,
  ): Observable<FetchResult<CopyFunnelContentMutation>> {
    return this.apollo.mutate<CopyFunnelContentMutation>({
      mutation: CopyFunnelContentDocument,
      variables: {
        destinationFunnelId,
        funnelToCopyId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  shareFunnel(funnelId: number, emails: string[]): Observable<FetchResult<ShareFunnelMutation>> {
    return this.apollo.mutate<ShareFunnelMutation>({
      mutation: ShareFunnelDocument,
      variables: {
        input: {
          funnelId,
          emails,
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeShareFunnel(shareId: number): Observable<FetchResult<RemoveFunnelShareMutation>> {
    return this.apollo.mutate<RemoveFunnelShareMutation>({
      mutation: RemoveShareFunnelDocument,
      variables: {
        shareId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getFunnelStatistics(funnelId: number): Observable<FetchResult<GetFunnelStatisticsQuery>> {
    return this.apollo.query<GetFunnelStatisticsQuery>({
      query: GetFunnelStatisticsDocument,
      variables: {
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getIntegrationInputsData(input: InputsDataListInputGraphql): Observable<FetchResult<GetIntegrationInputsDataQuery>> {
    return this.apollo.query<GetIntegrationInputsDataQuery>({
      query: GetIntegrationInputsDataDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getLastGeneratedIntegrationInputsData(
    integrationId: number,
  ): Observable<FetchResult<GetLastGeneratedIntegrationInputsDataQuery>> {
    return this.apollo.query<GetLastGeneratedIntegrationInputsDataQuery>({
      query: GetLastGeneratedIntegrationInputsDataDocument,
      variables: {
        integrationId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getIntegrationOutputsData(
    input: OutputsDataListInputGraphql,
  ): Observable<FetchResult<GetIntegrationOutputsDataQuery>> {
    return this.apollo.query<GetIntegrationOutputsDataQuery>({
      query: GetIntegrationOutputsDataDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getFunnelTactic(id: number): Observable<FetchResult<GetFunnelTacticQuery>> {
    return this.apollo.query<GetFunnelTacticQuery>({
      query: GetFunnelTacticDocument,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  generateContent(input: GenerateContentInputGraphql): Observable<FetchResult<GenerateContentMutation>> {
    return this.apollo.mutate<GenerateContentMutation>({
      mutation: GenerateContentDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  markOutputDataAsSelected(
    isSelected: boolean,
    outputDataId: number,
  ): Observable<FetchResult<MarkOutputDataAsSelectedMutation>> {
    return this.apollo.mutate<MarkOutputDataAsSelectedMutation>({
      mutation: MarkOutputDataAsSelectedDocument,
      variables: {
        isSelected,
        outputDataId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  editOutputData(input: EditOutputDataInputGraphql): Observable<FetchResult<EditOutputDataMutation>> {
    return this.apollo.mutate<EditOutputDataMutation>({
      mutation: EditOutputDataDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeOutputData(outputDataId: number): Observable<FetchResult<RemoveOutputDataMutation>> {
    return this.apollo.mutate<RemoveOutputDataMutation>({
      mutation: RemoveOutputDataDocument,
      variables: {
        outputDataId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getBannerbearIntegrations(): Observable<FetchResult<GetBannerbearIntegrationsQuery>> {
    return this.apollo.mutate<GetBannerbearIntegrationsQuery>({
      mutation: GetBannerbearIntegrationsDocument,
      fetchPolicy: 'no-cache',
    });
  }

  getIntegrations(integrationType: IntegrationTypeEnum): Observable<FetchResult<GetIntegrationsQuery>> {
    return this.apollo.mutate<GetIntegrationsQuery>({
      mutation: GetIntegrationsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        integrationType,
      },
    });
  }

  getLastOutputsData(
    records: number,
    integrationId: number,
    funnelId: number,
  ): Observable<FetchResult<GetLastOutputsDataQuery>> {
    return this.apollo.mutate<GetLastOutputsDataQuery>({
      mutation: GetLastOutputsDataDocument,
      fetchPolicy: 'no-cache',
      variables: {
        records,
        integrationId,
        funnelId,
      },
    });
  }

  generateImagesForFunnel(
    input: GenerateContentInputGraphql,
  ): Observable<FetchResult<GenerateImagesForFunnelMutation>> {
    return this.apollo.mutate<GenerateImagesForFunnelMutation>({
      mutation: GenerateImagesForFunnelDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  generateDefaultImages(funnelId: number): Observable<FetchResult<GenerateDefaultImagesMutation>> {
    return this.apollo.mutate<GenerateDefaultImagesMutation>({
      mutation: GenerateDefaultImagesDocument,
      variables: {
        funnelId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  createContentGenerationImage(
    funnelId: number,
    file: File,
  ): Observable<FetchResult<CreateContentGenerationImageMutation>> {
    return this.apollo.mutate<CreateContentGenerationImageMutation>({
      mutation: CreateContentGenerationImageDocument,
      variables: {
        funnelId,
        file,
      },
      fetchPolicy: 'no-cache',
      context: {
        useMultipart: true,
      },
    });
  }

  downloadOutputsDataZip(
    name: string,
    funnelId: number,
    outputsDataIds: number[],
  ): Observable<FetchResult<DownloadOutputsDataZipMutation>> {
    return this.apollo.mutate<DownloadOutputsDataZipMutation>({
      mutation: DownloadOutputsDataZipDocument,
      variables: {
        name,
        funnelId,
        outputsDataIds,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getOutputsData(outputsDataIds: number[]): Observable<FetchResult<GetOutputsDataQuery>> {
    return this.apollo.query<GetOutputsDataQuery>({
      query: GetOutputsDataDocument,
      variables: {
        outputsDataIds,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getGeneratorIntegrations(): Observable<FetchResult<GetContentGenerationIntegrationsQuery>> {
    return this.apollo.query<GetContentGenerationIntegrationsQuery>({
      query: GetContentGenerationIntegrationsDocument,
    });
  }
}
