<div class="ai-design-form-container">
  <div class="flex-grow overflow-y-auto px-5 py-3">
    <form
      *ngIf="form"
      [formGroup]="form"
      [validate-form]="form"
      (ngSubmit)="onSubmit()"
    >
      <div
        class="mt-5 flex flex-col justify-start items-stretch"
        style="min-height: 85vh"
      >
        <div *ngIf="getConfiguration('text_prompts') as configuration">
          <df-content-generator-form-fields-template
            [form]="form"
            [configuration]="configuration"
          ></df-content-generator-form-fields-template>

          <tui-notification
            status="info"
            class="tui-space_top-4"
          >
            <div class="flex mb-2 mt-1 items-center">
              <h4 class="notification__header">
                {{
                  maskingMode
                    ? ('Funnels.Graphics.Create a mask to edit an image locally.' | translate)
                    : ('Funnels.Graphics.No idea? Try an following example' | translate)
                }}
              </h4>
              <svg-icon
                (click)="copyPrompt()"
                class="ml-auto cursor-pointer"
                src="assets/img/icon-copy-1.svg"
              ></svg-icon>
            </div>

            <div class="flex mb-1">
              <p class="notification__text">
                {{
                  maskingMode
                    ? ('Funnels.Graphics.To create a mask for any element, use a brush tool. Once you have created the mask, you can prompt the AI to perform a specific action on that element.'
                      | translate)
                    : ('Funnels.Graphics.An astronaut playing basketball with cats in space, digital art' | translate)
                }}
              </p>
            </div>
          </tui-notification>
        </div>

        <div class="mt-6">
          <h4 class="section__header mb-1">
            {{ 'Funnels.Graphics.Graphics settings' | translate }}
          </h4>

          <div class="ml-4">
            <div *ngIf="getConfiguration('samples') as configuration">
              <df-content-generator-form-fields-template
                [form]="form"
                [configuration]="configuration"
              ></df-content-generator-form-fields-template>
            </div>

            <div *ngIf="getConfiguration('image_ratio') as configuration">
              <df-content-generator-form-fields-template
                [form]="form"
                [configuration]="configuration"
              ></df-content-generator-form-fields-template>
            </div>
          </div>
        </div>

        <div class="mt-6">
          <div class="ml-4">
            <div *ngIf="getConfiguration('image_integration') as configuration">
              <df-content-generator-form-fields-template
                [form]="form"
                [configuration]="configuration"
              ></df-content-generator-form-fields-template>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <tui-accordion>
            <tui-accordion-item [borders]="null">
              <h4 class="section__header mb-1">
                {{ 'Funnels.Graphics.Advanced' | translate }}
              </h4>
              <ng-template tuiAccordionItemContent>
                <div class="ml-4 grid grid-cols-2 gap-x-4">
                  <div *ngIf="getConfiguration('steps') as configuration">
                    <df-content-generator-form-fields-template
                      [form]="form"
                      [configuration]="configuration"
                    ></df-content-generator-form-fields-template>
                  </div>

                  <div *ngIf="getConfiguration('seed') as configuration">
                    <df-content-generator-form-fields-template
                      [form]="form"
                      [configuration]="configuration"
                    ></df-content-generator-form-fields-template>
                  </div>

                  <div *ngIf="getConfiguration('image_strength') as configuration">
                    <df-content-generator-form-fields-template
                      [form]="form"
                      [configuration]="configuration"
                    ></df-content-generator-form-fields-template>
                  </div>
                </div>
              </ng-template>
            </tui-accordion-item>
          </tui-accordion>
        </div>

        <div
          class="mt-6 image-referance-border pt-5"
          *ngIf="getConfiguration('init_image') as configuration"
        >
          <df-content-generator-form-fields-template
            (fileRemovedEvent)="fileRemoved()"
            (fileAddedEvent)="fileAdded()"
            [form]="form"
            [configuration]="configuration"
          ></df-content-generator-form-fields-template>

          <button
            class="w-full"
            tuiButton
            appearance="primary"
            type="submit"
            size="xl"
          >
            <span class="tui-text-body-m-3 ml-2.5">{{ 'Funnels.Graphics.Generate graphics' | translate }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
