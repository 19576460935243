import { NgModule } from '@angular/core';
import { FunnelManageComponent } from './pages/funnel-panel/components/funnel-manage/funnel-manage.component';
import { FunnelManageHeaderComponent } from './shared/components/funnel-manage-header/funnel-manage-header.component';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { FunnelManageFlowsComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-flows/funnel-manage-flows.component';
import { FunnelManageContentComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/funnel-manage-content.component';
import { FunnelManageContentSubHeaderComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-content-sub-header/funnel-manage-content-sub-header.component';
import { SwiperModule } from 'swiper/angular';
import { FunnelManageContentStepHeaderComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-content-step-header/funnel-manage-content-step-header.component';
import { FunnelManageContentEmptyStateComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-content-empty-state/funnel-manage-content-empty-state.component';
import { TacticsListModule } from '@modules/tactics/modules/tactics-list/tactics-list.module';
import { FunnelManageTacticComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/funnel-manage-tactic.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FunnelManageFlowsSearchComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-flows/components/funnel-manage-flows-search/funnel-manage-flows-search.component';
import { FunnelManageFlowDataModalComponent } from './shared/components/funnel-manage-flow-data-modal/funnel-manage-flow-data-modal.component';
import { FunnelCancelConfirmFlowModalComponent } from './shared/components/funnel-cancel-confirm-flow-modal/funnel-cancel-confirm-flow-modal.component';
import { TutorialVideoComponent } from './pages/funnel-panel/components/funnel-manage/components/tutorial-video/tutorial-video.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { FunnelManageTacticActivitiesComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-tactic-activities/funnel-manage-tactic-activities.component';
import { FunnelManageTacticNameModalComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-tactic-name-modal/funnel-manage-tactic-name-modal.component';
import { FunnelAddToProfileModalComponent } from './shared/components/funnel-add-to-profile-modal/funnel-add-to-profile-modal.component';
import { FunnelPanelComponent } from './pages/funnel-panel/funnel-panel.component';
import { FunnelPanelSubheaderComponent } from '@modules/funnels/modules/funnel-manage/shared/components/funnel-panel-subheader/funnel-panel-subheader.component';
import { FunnelOverviewComponent } from './pages/funnel-panel/components/funnel-overview/funnel-overview.component';
import { FunnelOverviewRecommendationsComponent } from './pages/funnel-panel/components/funnel-overview/components/funnel-overview-recommendations/funnel-overview-recommendations.component';
import { FunnelOverviewStatsComponent } from './pages/funnel-panel/components/funnel-overview/components/funnel-overview-stats/funnel-overview-stats.component';
import { FunnelStatementComponent } from './pages/funnel-panel/components/funnel-statement/funnel-statement.component';
import { FunnelRecommendationsComponent } from './pages/funnel-panel/components/funnel-recommendations/funnel-recommendations.component';
import { FunnelRecommendationsSectionComponent } from './pages/funnel-panel/components/funnel-recommendations/components/funnel-recommendations-section/funnel-recommendations-section.component';
import { FunnelRecommendationsItemComponent } from './pages/funnel-panel/components/funnel-recommendations/components/funnel-recommendations-section/components/funnel-recommendations-item/funnel-recommendations-item.component';
import { FunnelGeneratePdfModalComponent } from './shared/components/funnel-generate-pdf-modal/funnel-generate-pdf-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FunnelConfirmRemoveModalComponent } from './shared/components/funnel-confirm-remove-modal/funnel-confirm-remove-modal.component';
import { FunnelManageTacticPreviewComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-tactic-preview/funnel-manage-tactic-preview.component';
import { FunnelManageTacticDropdownMenuComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-tactic-dropdown-menu/funnel-manage-tactic-dropdown-menu.component';
import { FunnelContentGeneratorComponent } from './pages/funnel-panel/components/funnel-content-generator/funnel-content-generator.component';
import { ContentGeneratorStepsComponent } from './pages/funnel-panel/components/funnel-content-generator/components/content-generator-steps/content-generator-steps.component';
import { ContentGeneratorTacticComponent } from './pages/funnel-panel/components/funnel-content-generator/components/content-generator-tactic/content-generator-tactic.component';
import { FunnelOverviewContentFunnelComponent } from './pages/funnel-panel/components/funnel-overview/components/funnel-overview-content-funnel/funnel-overview-content-funnel.component';
import { FunnelOverviewContentGeneratorComponent } from './pages/funnel-panel/components/funnel-overview/components/funnel-overview-content-generator/funnel-overview-content-generator.component';
import { ContentGeneratorTacticDetailsComponent } from './pages/funnel-panel/components/funnel-content-generator/components/content-generator-tactic-details/content-generator-tactic-details.component';
import { TacticsModule } from '@modules/tactics/tactics.module';
import { TacticPreviewModule } from '@modules/tactics/modules/tactic-preview/tactic-preview.module';
import { ContentGeneratorAssetTileComponent } from './pages/funnel-panel/components/funnel-content-generator/components/content-generator-tactic-details/components/content-generator-asset-tile/content-generator-asset-tile.component';
import { ContentGeneratorItemTileComponent } from './shared/components/content-generator-item-tile/content-generator-item-tile.component';
import { ContentGeneratorModalComponent } from './pages/funnel-panel/components/funnel-content-generator/components/content-generator-modal/content-generator-modal.component';
import { ContentGeneratorIntegrationPlaceholderComponent } from './shared/components/content-generator-integration-placeholder/content-generator-integration-placeholder.component';
import { ContentGeneratorAssetShortComponent } from './shared/components/content-generator-asset-short/content-generator-asset-short.component';
import { ContentGeneratorFormComponent } from './shared/components/content-generator-form/content-generator-form.component';
import { ContentGeneratorFormFieldsComponent } from './shared/components/content-generator-form/components/content-generator-form-fields/content-generator-form-fields.component';
import { ContentGeneratorItemEditModalComponent } from './shared/components/content-generator-item-tile/components/content-generator-item-edit-modal/content-generator-item-edit-modal.component';
import { FunnelCreateTacticModalComponent } from './shared/components/funnel-create-tactic-modal/funnel-create-tactic-modal.component';
import { FunnelTacticBadgesComponent } from '@modules/funnels/modules/funnel-manage/shared/components/funnel-tactic-badges/funnel-tactic-badges.component';
import { FunnelGraphicsComponent } from './pages/funnel-panel/components/funnel-graphics/funnel-graphics.component';
import { GraphicsGeneratorItemTileComponent } from './shared/components/graphics-generator-item-tile/graphics-generator-item-tile.component';
import { FunnelGraphicsWelcomeModalComponent } from './pages/funnel-panel/components/funnel-graphics/components/funnel-graphics-welcome-modal/funnel-graphics-welcome-modal.component';
import { FunnelGraphicsCloseAlertComponent } from './pages/funnel-panel/components/funnel-graphics/components/funnel-graphics-close-alert/funnel-graphics-close-alert.component';
import { FunnelRecommendationsAiComponent } from './pages/funnel-panel/components/funnel-recommendations/components/funnel-recommendations-ai/funnel-recommendations-ai.component';
import { RecommendationsTacticsModalComponent } from './shared/components/recommendations-tactics-modal/recommendations-tactics-modal.component';
import { ContentGeneratorStylesModalComponent } from './shared/components/content-generator-form/components/content-generator-styles-modal/content-generator-styles-modal.component';
import { ContentGeneratorUnsplashModalComponent } from './shared/components/content-generator-form/components/content-generator-unsplash-modal/content-generator-unsplash-modal.component';
import { FunnelManageOnboardingBannerComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-onboarding-banner/funnel-manage-onboarding-banner.component';
import { FunnelTacticOnboardingPlaceholderComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-tactic-onboarding-placeholder/funnel-tactic-onboarding-placeholder.component';
import { FunnelManageOnboardingFlowBannerComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-onboarding-flow-banner/funnel-manage-onboarding-flow-banner.component';
import { ContentGeneratorOnboardingBannerComponent } from './pages/funnel-panel/components/funnel-content-generator/components/content-generator-tactic-details/components/content-generator-onboarding-banner/content-generator-onboarding-banner.component';
import { FunnelContentGeneratorLibraryComponent } from './pages/funnel-panel/components/funnel-content-generator-library/funnel-content-generator-library.component';
import { FunnelContentGeneratorLibraryTileComponent } from './pages/funnel-panel/components/funnel-content-generator-library/components/funnel-content-generator-library-tile/funnel-content-generator-library-tile.component';
import { FunnelContentGeneratorLibraryFiltersComponent } from './pages/funnel-panel/components/funnel-content-generator-library/components/funnel-content-generator-library-filters/funnel-content-generator-library-filters.component';
import { FunnelStatisticsComponent } from './pages/funnel-panel/components/funnel-statistics/funnel-statistics.component';
import { FunnelStatisticsReportTileComponent } from './pages/funnel-panel/components/funnel-statistics/components/funnel-statistics-report-tile/funnel-statistics-report-tile.component';
import { FunnelStatisticsAddNewReportFormComponent } from './pages/funnel-panel/components/funnel-statistics/components/funnel-statistics-add-new-report-from/funnel-statistics-add-new-report-form.component';
import { FunnelStatisticsAddNewReportIntroComponent } from './pages/funnel-panel/components/funnel-statistics/components/funnel-statistics-add-new-report-intro/funnel-statistics-add-new-report-intro.component';
import { FunnelStatisticsReportListComponent } from './pages/funnel-panel/components/funnel-statistics/components/funnel-statistics-report-list/funnel-statistics-report-list.component';
import { FunnelStatisticsDashboardIframeComponent } from './pages/funnel-panel/components/funnel-statistics/components/funnel-statistics-dashboard-iframe/funnel-statistics-dashboard-iframe.component';
import { FunnelContentGenereterLibraryNoContentComponent } from './pages/funnel-panel/components/funnel-content-generator-library/components/funnel-content-genereter-library-no-content/funnel-content-genereter-library-no-content.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ContentGeneratorTemplatesComponent } from './pages/funnel-panel/components/funnel-content-generator/components/content-generator-templates/content-generator-templates.component';
import { ContentGeneratorTemplateFormComponent } from './pages/funnel-panel/components/funnel-content-generator/components/content-generator-template-form/content-generator-template-form.component';
import { ContentGeneratorContentListComponent } from './pages/funnel-panel/components/funnel-content-generator/components/content-generator-content-list/content-generator-content-list.component';
import { FunnelOverviewHelpComponent } from './pages/funnel-panel/components/funnel-overview/components/funnel-overview-help/funnel-overview-help.component';
import { FunnelOverviewHelpItempModule } from './pages/funnel-panel/components/funnel-overview/components/funnel-overview-help/components/funnel-overview-help-item/funnel-overview-help-item.module';
import { GenerateContentCostBadgeComponent } from './shared/components/generate-content-cost-badge/generate-content-cost-badge.component';
import { FunnelGraphicTabsComponent } from './shared/components/funnel-graphic-tabs/funnel-graphic-tabs.component';
import { FunnelGraphicsAiDesignFormComponent } from './pages/funnel-panel/components/funnel-graphics/components/funnel-graphics-ai-design-form/funnel-graphics-ai-design-form.component';
import { FunnelGraphicAiDesignTileComponent } from './shared/components/funnel-graphic-ai-design-tile/funnel-graphic-ai-design-tile.component';
import { CanvasPositionComponent } from './pages/funnel-panel/components/funnel-canvases/components/position/canvas-position.component';

import { ContentGeneratorFormFieldsTemplateComponent } from './shared/components/content-generator-form/components/content-generator-form-fields-template/content-generator-form-fields-template.component';
import { TuiNotificationModule, TuiScrollbarModule } from '@taiga-ui/core';
import { FunnelManageRoutes } from '@core/routes/configs/funnels.routes';
import { FunnelCanvasesComponent } from './pages/funnel-panel/components/funnel-canvases/funnel-canvases.component';
import { AssetsModule } from '@modules/assets/assets.module';
import { FunnelGraphicsAiVideoFormComponent } from './pages/funnel-panel/components/funnel-graphics/components/funnel-graphics-ai-video-form/funnel-graphics-ai-video-form.component';
import { FunnelManageColumnsComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-columns/funnel-manage-columns.component';
import { TacticColumnsComponent } from './pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-columns/components/tactic-columns/tactic-columns.component';

@NgModule({
  declarations: [
    FunnelManageComponent,
    FunnelManageHeaderComponent,
    FunnelManageFlowsComponent,
    FunnelManageContentComponent,
    FunnelManageContentSubHeaderComponent,
    FunnelManageContentStepHeaderComponent,
    FunnelManageContentEmptyStateComponent,
    FunnelManageTacticComponent,
    FunnelManageFlowsSearchComponent,
    FunnelManageFlowDataModalComponent,
    FunnelCancelConfirmFlowModalComponent,
    TutorialVideoComponent,
    FunnelManageTacticActivitiesComponent,
    FunnelManageTacticNameModalComponent,
    FunnelManageColumnsComponent,
    FunnelAddToProfileModalComponent,
    FunnelPanelComponent,
    FunnelPanelSubheaderComponent,
    FunnelOverviewComponent,
    FunnelOverviewRecommendationsComponent,
    FunnelOverviewStatsComponent,
    FunnelStatementComponent,
    FunnelRecommendationsComponent,
    FunnelRecommendationsSectionComponent,
    FunnelRecommendationsItemComponent,
    FunnelGeneratePdfModalComponent,
    FunnelConfirmRemoveModalComponent,
    FunnelManageTacticPreviewComponent,
    FunnelManageTacticDropdownMenuComponent,
    FunnelContentGeneratorComponent,
    ContentGeneratorStepsComponent,
    ContentGeneratorTacticComponent,
    FunnelOverviewContentFunnelComponent,
    FunnelOverviewContentGeneratorComponent,
    ContentGeneratorTacticDetailsComponent,
    ContentGeneratorAssetTileComponent,
    ContentGeneratorItemTileComponent,
    ContentGeneratorModalComponent,
    ContentGeneratorIntegrationPlaceholderComponent,
    ContentGeneratorAssetShortComponent,
    ContentGeneratorFormComponent,
    ContentGeneratorFormFieldsComponent,
    ContentGeneratorItemEditModalComponent,
    FunnelCreateTacticModalComponent,
    FunnelTacticBadgesComponent,
    FunnelGraphicsComponent,
    GraphicsGeneratorItemTileComponent,
    FunnelGraphicsWelcomeModalComponent,
    FunnelGraphicsCloseAlertComponent,
    FunnelRecommendationsAiComponent,
    RecommendationsTacticsModalComponent,
    ContentGeneratorStylesModalComponent,
    ContentGeneratorUnsplashModalComponent,
    FunnelManageOnboardingBannerComponent,
    FunnelTacticOnboardingPlaceholderComponent,
    FunnelManageOnboardingFlowBannerComponent,
    ContentGeneratorOnboardingBannerComponent,
    FunnelContentGeneratorLibraryComponent,
    FunnelContentGeneratorLibraryTileComponent,
    FunnelContentGeneratorLibraryFiltersComponent,
    FunnelStatisticsComponent,
    FunnelStatisticsAddNewReportFormComponent,
    FunnelStatisticsAddNewReportIntroComponent,
    FunnelStatisticsReportTileComponent,
    FunnelStatisticsReportListComponent,
    FunnelStatisticsDashboardIframeComponent,
    FunnelContentGenereterLibraryNoContentComponent,
    ContentGeneratorTemplatesComponent,
    ContentGeneratorTemplateFormComponent,
    ContentGeneratorContentListComponent,
    FunnelOverviewHelpComponent,
    GenerateContentCostBadgeComponent,
    FunnelGraphicTabsComponent,
    FunnelGraphicsAiDesignFormComponent,
    FunnelGraphicsAiVideoFormComponent,
    FunnelGraphicAiDesignTileComponent,
    ContentGeneratorFormFieldsTemplateComponent,
    CanvasPositionComponent,
    FunnelCanvasesComponent,
  ],
  providers: [],
  exports: [
    FunnelCancelConfirmFlowModalComponent,
    FunnelManageTacticPreviewComponent,
    FunnelContentGenereterLibraryNoContentComponent,
    FunnelManageContentComponent,
  ],
  imports: [
    AssetsModule,
    TuiScrollbarModule,
    SharedModule,
    SwiperModule,
    TacticsListModule,
    TacticColumnsComponent,
    DragDropModule,
    RouterModule.forChild(FunnelManageRoutes),
    YouTubePlayerModule,
    ImageCropperModule,
    TacticPreviewModule,
    TacticsModule,
    InfiniteScrollModule,
    FunnelOverviewHelpItempModule,
    TuiNotificationModule,
  ],
})
export class FunnelManageModule {}
