import { EventEmitter, Injectable } from '@angular/core';
import { BaseStorageService } from '@core/services/base-storage.service';
import { Team } from '@shared/models/team.model';
import { StorageEnum } from '@shared/enums/storage.enum';
import { TeamGraphqlService } from '@modules/user-settings/modules/teams/shared/services/team-graphql.service';
import { map } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { MyTeamsQuery } from '@modules/user-settings/modules/teams/shared/graphql/queries/my-teams.query.generated';
import { TeamOutputGraphql } from '@modules/graphql/graphql-types';
import { NavigateService } from '@core/routes/services/navigate.service';

@Injectable({
  providedIn: 'root',
})
export class TeamsService extends BaseStorageService {
  teamsUpdate$: EventEmitter<boolean> = new EventEmitter<boolean>();

  get teams(): Team[] {
    const teams = this.get(StorageEnum.TEAMS);
    return teams ? JSON.parse(teams).map((team) => new Team(team)) : [];
  }

  set teams(teams: Team[]) {
    this.put(StorageEnum.TEAMS, JSON.stringify(teams));
    this.teamsUpdate$.emit(true);
  }

  constructor(
    private readonly teamGraphqlService: TeamGraphqlService,
    private readonly n: NavigateService,
  ) {
    super();
  }

  fetchTeams(): void {
    this.teamGraphqlService
      .myTeams()
      .pipe(map((res: FetchResult<MyTeamsQuery>) => (res?.data ? (res?.data?.myTeams as TeamOutputGraphql[]) : [])))
      .subscribe((teams: TeamOutputGraphql[]) => (this.teams = teams.map((team) => new Team(team))));
  }
}
