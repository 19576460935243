export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  JSONObject: any;
  Upload: any;
};

export enum AccountSetupParameterCategoriesEnum {
  CurrentJobRole = 'currentJobRole',
  HowHearAboutApp = 'howHearAboutApp',
  MostImportant = 'mostImportant'
}

export type AccountSetupParameterInputGraphql = {
  customValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type AccountSetupParameterOutputGraphql = {
  __typename?: 'AccountSetupParameterOutputGraphql';
  category: AccountSetupParameterCategoriesEnum;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ActivityAllowedParameterOutputGraphql = {
  __typename?: 'ActivityAllowedParameterOutputGraphql';
  budgets: Array<FilterItemOutputGraphql>;
  carriers: Array<FilterItemOutputGraphql>;
  formats: Array<FilterItemOutputGraphql>;
  mediums: Array<FilterItemOutputGraphql>;
  placements: Array<FilterItemOutputGraphql>;
  platforms: Array<ActivityPlatformOutputGraphql>;
  types: Array<FilterItemOutputGraphql>;
};

export type ActivityAssetOutputGraphql = {
  __typename?: 'ActivityAssetOutputGraphql';
  asset: AssetOutputGraphql;
  creations: Array<CanvaCreationOutputGraphql>;
  id: Scalars['Int'];
  selectedResolutions: Array<AssetResolutionOutputGraphql>;
  uuid?: Maybe<Scalars['String']>;
};

export type ActivityInputGraphql = {
  budgetId?: InputMaybe<Scalars['Int']>;
  carrierId?: InputMaybe<Scalars['Int']>;
  copy?: InputMaybe<Scalars['String']>;
  customPrompts: Array<CustomPromptActivityInputGraphql>;
  description?: InputMaybe<Scalars['String']>;
  formatId?: InputMaybe<Scalars['Int']>;
  mediumId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['Int']>;
  platformId?: InputMaybe<Scalars['Int']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type ActivityOutputGraphql = {
  __typename?: 'ActivityOutputGraphql';
  assets: Array<ActivityAssetOutputGraphql>;
  benchmarks: Array<BenchmarkOutputGraphql>;
  budget?: Maybe<ActivityParameterOutputGraphql>;
  carrier?: Maybe<ActivityParameterOutputGraphql>;
  copy?: Maybe<Scalars['String']>;
  customPrompts: Array<CustomPromptOutputGraphql>;
  description?: Maybe<Scalars['String']>;
  format?: Maybe<ActivityParameterOutputGraphql>;
  id: Scalars['Int'];
  image?: Maybe<AttachmentOutputGraphql>;
  logo?: Maybe<AttachmentOutputGraphql>;
  medium?: Maybe<ActivityParameterOutputGraphql>;
  name?: Maybe<Scalars['String']>;
  owner: UserOutputGraphql;
  placement?: Maybe<ActivityParameterOutputGraphql>;
  platform?: Maybe<ActivityPlatformOutputGraphql>;
  position: Scalars['Int'];
  tactic: TacticOutputGraphql;
  type?: Maybe<ActivityTypeOutputGraphql>;
};

export type ActivityParameterOutputGraphql = {
  __typename?: 'ActivityParameterOutputGraphql';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ActivityPlatformFilterOutputGraphql = {
  __typename?: 'ActivityPlatformFilterOutputGraphql';
  /** Data */
  records: Array<FilterTypeOutputActivityPlatformOutputGraphql>;
  /** Total records count */
  total: Scalars['Float'];
};

export type ActivityPlatformOutputGraphql = {
  __typename?: 'ActivityPlatformOutputGraphql';
  hasMediums: Scalars['Boolean'];
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  isOther: Scalars['Boolean'];
  name: Scalars['String'];
};

export type ActivityTypeOutputGraphql = {
  __typename?: 'ActivityTypeOutputGraphql';
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type AdTypesListInputGraphql = {
  platformId?: InputMaybe<Scalars['Int']>;
};

export type AddAssetInputGraphql = {
  activityId?: InputMaybe<Scalars['Int']>;
  addTypeId: Scalars['Int'];
  placementId: Scalars['Int'];
  platformId: Scalars['Int'];
  resolutionsIds?: InputMaybe<Array<Scalars['Int']>>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type AddBenchmarkInputGraphql = {
  activityId: Scalars['Int'];
  benchmarkTypeId: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type AddMembersInputGraphql = {
  members: Array<TeamMemberInputGraphql>;
  teamId: Scalars['Int'];
};

export type AppSumoRegisterInputGraphql = {
  appSumoToken: Scalars['String'];
  /** E-mail */
  email: Scalars['String'];
  /** First name */
  firstName: Scalars['String'];
  isMarketingCampaignUser?: Scalars['Boolean'];
  /** Last name */
  lastName?: InputMaybe<Scalars['String']>;
  /** Password */
  password: Scalars['String'];
  redirectedFromTacticsLpPage?: InputMaybe<Scalars['Boolean']>;
  /** Is rules accepted */
  rulesAccepted: Scalars['Boolean'];
};

export type AppSumoValidationOutputGraphql = {
  __typename?: 'AppSumoValidationOutputGraphql';
  appSumoEmail: Scalars['String'];
};

export type AssetAdTypeOutputGraphql = {
  __typename?: 'AssetAdTypeOutputGraphql';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AssetLibraryOutputGraphql = {
  __typename?: 'AssetLibraryOutputGraphql';
  context: Array<ContextAssetOutputGraphql>;
  createdAt: Scalars['DateTime'];
  fileId?: Maybe<Scalars['String']>;
  funnelId: Scalars['Float'];
  id: Scalars['Float'];
  integration: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['String']>;
};

export type AssetOutputGraphql = {
  __typename?: 'AssetOutputGraphql';
  adType: AssetAdTypeOutputGraphql;
  canvaCreationsUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  integrations: Array<IntegrationOutputGraphql>;
  maxFileSize?: Maybe<Scalars['String']>;
  native?: Maybe<Scalars['Boolean']>;
  placement: AssetPlacementOutputGraphql;
  platform: AssetPlatformOutputGraphql;
  primaryText?: Maybe<Scalars['String']>;
  properties: Array<AssetPropertyGraphql>;
  ratio?: Maybe<Scalars['String']>;
};

export type AssetPlacementOutputGraphql = {
  __typename?: 'AssetPlacementOutputGraphql';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AssetPlatformOutputGraphql = {
  __typename?: 'AssetPlatformOutputGraphql';
  id: Scalars['Int'];
  image: Scalars['String'];
  name: Scalars['String'];
};

export type AssetPropertyGraphql = {
  __typename?: 'AssetPropertyGraphql';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type AssetResolutionOutputGraphql = {
  __typename?: 'AssetResolutionOutputGraphql';
  height: Scalars['Int'];
  id: Scalars['Int'];
  width: Scalars['Int'];
};

export type AssetsPlacementsListInputGraphql = {
  adTypeId: Scalars['Int'];
  platformId: Scalars['Int'];
};

export type AssetsResolutionsListInputGraphql = {
  adTypeId?: InputMaybe<Scalars['Int']>;
  placementId?: InputMaybe<Scalars['Int']>;
  platformId?: InputMaybe<Scalars['Int']>;
};

export type AttachmentOutputGraphql = {
  __typename?: 'AttachmentOutputGraphql';
  created: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  file: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AuthOutputGraphql = {
  __typename?: 'AuthOutputGraphql';
  eventType?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserOutputGraphql>;
};

export type AyrshareAccountOutputGraphql = {
  __typename?: 'AyrshareAccountOutputGraphql';
  activeSocialAccounts: Array<Scalars['String']>;
  createdUTC: Scalars['String'];
  refId: Scalars['String'];
  title: Scalars['String'];
};

export type AyrshareCreateAccountOutputGraphql = {
  __typename?: 'AyrshareCreateAccountOutputGraphql';
  profileKey: Scalars['String'];
  status: Scalars['String'];
  title: Scalars['String'];
};

export type AyrshareCreatePostInputGraphql = {
  mediaUrls: Array<Scalars['String']>;
  platforms: Array<Scalars['String']>;
  post: Scalars['String'];
  publishDate?: InputMaybe<Scalars['String']>;
};

export type AyrshareErrorOutputGraphql = {
  __typename?: 'AyrshareErrorOutputGraphql';
  message: Scalars['String'];
  platform: Scalars['String'];
};

export type AyrsharePostOutputGraphql = {
  __typename?: 'AyrsharePostOutputGraphql';
  created?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<AyrshareErrorOutputGraphql>>;
  id: Scalars['String'];
  mediaUrls: Array<Scalars['String']>;
  platforms: Array<Scalars['String']>;
  post: Scalars['String'];
  status: Scalars['String'];
};

export type AyrsharePublishPostOutputGraphql = {
  __typename?: 'AyrsharePublishPostOutputGraphql';
  id: Scalars['String'];
  post: Scalars['String'];
  status: Scalars['String'];
};

export type BenchmarkOutputGraphql = {
  __typename?: 'BenchmarkOutputGraphql';
  attachment?: Maybe<AttachmentOutputGraphql>;
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type: BenchmarkTypeOutputGraphql;
  uuid?: Maybe<Scalars['String']>;
};

export enum BenchmarkTypeEnum {
  File = 'file',
  Link = 'link'
}

export type BenchmarkTypeOutputGraphql = {
  __typename?: 'BenchmarkTypeOutputGraphql';
  id: Scalars['Int'];
  image: Scalars['String'];
  name: Scalars['String'];
  type: BenchmarkTypeEnum;
};

export type BriefAnswerInputGraphql = {
  answer?: InputMaybe<Scalars['String']>;
  questionId: Scalars['Int'];
};

export type BriefAnswerOutputGraphql = {
  __typename?: 'BriefAnswerOutputGraphql';
  answer: Scalars['String'];
  id: Scalars['Int'];
  question: BriefQuestionOutputGraphql;
};

export type BriefInputGraphql = {
  answers: Array<BriefAnswerInputGraphql>;
  budget?: InputMaybe<Scalars['Int']>;
};

export type BriefOutputGraphql = {
  __typename?: 'BriefOutputGraphql';
  answers: Array<BriefAnswerOutputGraphql>;
  budget?: Maybe<Scalars['Int']>;
  client: UserOutputGraphql;
  file: AttachmentOutputGraphql;
  id: Scalars['Int'];
};

export type BriefQuestionOutputGraphql = {
  __typename?: 'BriefQuestionOutputGraphql';
  category: Scalars['String'];
  id: Scalars['Int'];
  question: Scalars['String'];
};

export type BudgetCalculationInputGraphql = {
  avgRevenuePerCustomer: Scalars['Int'];
  conversionRate: Scalars['Float'];
  expectedCPC: Scalars['Int'];
  goal: Scalars['Int'];
  leadsToCustomerRate: Scalars['Float'];
  serviceOrderId: Scalars['Int'];
};

export type BudgetCalculationOutputGraphql = {
  __typename?: 'BudgetCalculationOutputGraphql';
  avgRevenuePerCustomer: Scalars['Int'];
  budgetNeeded: Scalars['Int'];
  conversionRate: Scalars['Float'];
  costPerLead: Scalars['Int'];
  customersNeeded: Scalars['Int'];
  expectedCPC: Scalars['Int'];
  expectedProfit: Scalars['Int'];
  goal: Scalars['Int'];
  id: Scalars['Int'];
  leadsNeeded: Scalars['Int'];
  leadsToCustomerRate: Scalars['Float'];
  lpTrafficNeeded: Scalars['Int'];
  roas: Scalars['Float'];
};

export type CanvaCreationCreateInputGraphql = {
  activityAssetId: Scalars['Int'];
  canvaId: Scalars['String'];
  imageUrl: Scalars['String'];
  resolutionId?: InputMaybe<Scalars['Int']>;
};

export type CanvaCreationOutputGraphql = {
  __typename?: 'CanvaCreationOutputGraphql';
  canvaId: Scalars['String'];
  file: AttachmentOutputGraphql;
  id: Scalars['Int'];
  resolution?: Maybe<AssetResolutionOutputGraphql>;
};

export type CanvaCreationUpdateInputGraphql = {
  activityAssetId: Scalars['Int'];
  canvaId: Scalars['String'];
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
  resolutionId?: InputMaybe<Scalars['Int']>;
};

export type CanvasAnswerSuggestionsOutput = {
  __typename?: 'CanvasAnswerSuggestionsOutput';
  businessName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  suggestions?: Maybe<Scalars['JSONObject']>;
  url?: Maybe<Scalars['String']>;
};

export type CanvasCategoryAddOrInputInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type CanvasCategoryOutput = {
  __typename?: 'CanvasCategoryOutput';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type CanvasCreateInstanceInput = {
  /** funnel id */
  funnelId: Scalars['Float'];
  /** isBaseUrl */
  isBaseUrl?: InputMaybe<Scalars['Boolean']>;
  positionValue: Array<CanvasInstancePositionValueInput>;
  /** canvas template revision number */
  revision: Scalars['Float'];
  /** canvas template id */
  templateId: Scalars['Float'];
  /** url */
  url?: InputMaybe<Scalars['String']>;
};

export type CanvasFormInput = {
  categoryId: Scalars['Float'];
  description: Scalars['String'];
  hierarchyPosition?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Float']>;
  img?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['Float']>;
  positions: Array<CanvasPositionInput>;
  promptId?: InputMaybe<Scalars['Float']>;
  status: CanvasTemplateStatus;
};

export type CanvasInstanceOutput = {
  __typename?: 'CanvasInstanceOutput';
  id?: Maybe<Scalars['Int']>;
  positionValues?: Maybe<Array<CanvasInstancePositionValueOutput>>;
  template?: Maybe<CanvasTemplateOutput>;
};

export type CanvasInstancePositionValueInput = {
  /** canvas position template id */
  positionTemplateId: Scalars['Float'];
  /** answer from user */
  value: Scalars['String'];
};

export type CanvasInstancePositionValueOutput = {
  __typename?: 'CanvasInstancePositionValueOutput';
  position: CanvasPositionOutput;
  value: Scalars['String'];
};

export type CanvasPositionInput = {
  defaultValue?: InputMaybe<Scalars['String']>;
  defaultValueType?: InputMaybe<CanvasTemplatePositionDefaultValueType>;
  description: Scalars['String'];
  hierarchyPosition: Scalars['Float'];
  id?: InputMaybe<Scalars['Float']>;
  img?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  positionValues?: InputMaybe<Array<CanvasPositionValueInput>>;
  requirement: Scalars['Boolean'];
  sectionId?: InputMaybe<Scalars['Float']>;
  type: Scalars['String'];
  visibility: Scalars['Boolean'];
};

export type CanvasPositionOutput = {
  __typename?: 'CanvasPositionOutput';
  defaultValue: Scalars['String'];
  defaultValueType: CanvasTemplatePositionDefaultValueType;
  description: Scalars['String'];
  hierarchyPosition: Scalars['Float'];
  id: Scalars['Float'];
  img?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  positionValues: Array<CanvasPositionValueOutput>;
  requirement: Scalars['Boolean'];
  section?: Maybe<CanvasSectionsOutput>;
  type: Scalars['String'];
  visibility: Scalars['Boolean'];
};

export type CanvasPositionValueInput = {
  default: Scalars['Boolean'];
  hierarchyPosition: Scalars['Float'];
  id?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CanvasPositionValueOutput = {
  __typename?: 'CanvasPositionValueOutput';
  default: Scalars['Boolean'];
  hierarchyPosition: Scalars['Float'];
  id: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CanvasSectionInput = {
  /** Section description */
  description: Scalars['String'];
  /** Section hierarchy position */
  hierarchyPosition?: InputMaybe<Scalars['Float']>;
  /** Section image */
  img?: InputMaybe<Scalars['String']>;
  /** Section name */
  name: Scalars['String'];
};

export type CanvasSectionsOutput = {
  __typename?: 'CanvasSectionsOutput';
  description: Scalars['String'];
  hierarchyPosition?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  img?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CanvasTemplateOutput = {
  __typename?: 'CanvasTemplateOutput';
  category?: Maybe<CanvasCategoryOutput>;
  description: Scalars['String'];
  hierarchyPosition?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  img?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Float']>;
  positions: Array<CanvasPositionOutput>;
  prompt?: Maybe<CustomPromptOutputGraphql>;
  revision: Scalars['Int'];
  status: CanvasTemplateStatus;
};

export enum CanvasTemplatePositionDefaultValueType {
  Static = 'static',
  Variable = 'variable'
}

export enum CanvasTemplateStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type ChangePasswordInputGraphql = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type ChatOutputGraphql = {
  __typename?: 'ChatOutputGraphql';
  id: Scalars['Int'];
  participants: Array<PublicUserOutputGraphql>;
};

export type CompanyDataOutputGraphql = {
  __typename?: 'CompanyDataOutputGraphql';
  businessName: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type ContentGenerationOutputGraphql = {
  __typename?: 'ContentGenerationOutputGraphql';
  outputs: Array<OutputDataGraphql>;
  userCredits: Scalars['Int'];
};

export type ContextAssetInputGraphql = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ContextAssetOutputGraphql = {
  __typename?: 'ContextAssetOutputGraphql';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type CreateInputDataGraphql = {
  inputConfigurationId: Scalars['Int'];
  value: Scalars['JSONObject'];
};

export type CreateManyFunnelTacticInputGraphql = {
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  stepId: Scalars['Int'];
  tacticId: Scalars['Int'];
};

export type CreateMarketingCampaignGraphql = {
  __typename?: 'CreateMarketingCampaignGraphql';
  funnelId: Scalars['Int'];
};

export type CreateMarketingCampaignInputGraphql = {
  brief?: InputMaybe<Scalars['String']>;
  businessName: Scalars['String'];
  email: Scalars['String'];
  funnelId?: InputMaybe<Scalars['Float']>;
  logo?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

export type CreateMarketingComparisonInputGraphql = {
  businessName: Scalars['String'];
  competitionUrl1: Scalars['String'];
  competitionUrl2?: InputMaybe<Scalars['String']>;
  competitionUrl3?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  funnelId?: InputMaybe<Scalars['Float']>;
  url: Scalars['String'];
};

export type CreateResourcePermissionInputGraphql = {
  permission?: FunnelPermissionEnum;
  resourceTeamId: Scalars['Int'];
  teamMemberId: Scalars['Int'];
};

export type CreateResourceTeamInputGraphql = {
  funnelId?: InputMaybe<Scalars['Int']>;
  tacticId?: InputMaybe<Scalars['Int']>;
  teamId: Scalars['Int'];
  teamMembers: Array<ResourcePermissionInputGraphql>;
};

export type CreateServiceInputGraphql = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Int'];
};

export type CreateSubscriptionOutputGraphql = {
  __typename?: 'CreateSubscriptionOutputGraphql';
  created: Scalars['Boolean'];
  redirectUrl: Scalars['String'];
};

export type CreateTeamInputGraphql = {
  name: Scalars['String'];
};

export type CustomPromptActivityInputGraphql = {
  category?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  promptProperty: Scalars['String'];
  promptText: Scalars['String'];
  settingsActive?: Scalars['Boolean'];
  settingsIntegration?: InputMaybe<Scalars['String']>;
};

export type CustomPromptFieldInputGraphql = {
  defaultValue?: InputMaybe<Scalars['String']>;
  defaultValueId?: InputMaybe<Scalars['Float']>;
  fieldId: Scalars['String'];
  fieldType: Scalars['String'];
  helpText?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  name: Scalars['String'];
  optionValues?: InputMaybe<Array<Scalars['String']>>;
  order?: InputMaybe<Scalars['Float']>;
  required?: Scalars['Boolean'];
};

export type CustomPromptFieldOutputGraphql = {
  __typename?: 'CustomPromptFieldOutputGraphql';
  customPromptId: Scalars['Float'];
  defaultValue?: Maybe<Scalars['String']>;
  defaultValueId?: Maybe<Scalars['Float']>;
  fieldId: Scalars['String'];
  fieldType: Scalars['String'];
  helpText?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  name: Scalars['String'];
  optionValues?: Maybe<Array<Scalars['String']>>;
  order?: Maybe<Scalars['Float']>;
  required: Scalars['Boolean'];
};

export type CustomPromptInputGraphql = {
  category?: InputMaybe<Scalars['String']>;
  contentGeneratorCategory?: InputMaybe<Scalars['String']>;
  contentGeneratorIcon?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  forms: Array<CustomPromptFieldInputGraphql>;
  name: Scalars['String'];
  promptProperty: Scalars['String'];
  promptText: Scalars['String'];
  settingsActive?: Scalars['Boolean'];
  settingsIntegration?: InputMaybe<Scalars['String']>;
};

export type CustomPromptOutputCreateGraphql = {
  activityId: Scalars['Float'];
  customPromptId: Scalars['Float'];
  funnelTacticId?: InputMaybe<Scalars['Float']>;
  input: Scalars['String'];
  value: Scalars['String'];
};

export type CustomPromptOutputGraphql = {
  __typename?: 'CustomPromptOutputGraphql';
  category?: Maybe<Scalars['String']>;
  contentGeneratorCategory?: Maybe<Scalars['String']>;
  contentGeneratorIcon?: Maybe<Scalars['String']>;
  customPromptFields: Array<CustomPromptFieldOutputGraphql>;
  customPromptOutput?: Maybe<Array<CustomPromptOutputOutputGraphql>>;
  description: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  promptProperty: Scalars['String'];
  promptText: Scalars['String'];
  settingsActive: Scalars['Boolean'];
  settingsIntegration?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CustomPromptOutputOutputGraphql = {
  __typename?: 'CustomPromptOutputOutputGraphql';
  customPrompt: CustomPromptOutputGraphql;
  customPromptId: Scalars['Float'];
  /** Id of FunnelTactic */
  funnelTacticId?: Maybe<Scalars['Float']>;
  id: Scalars['Float'];
  input: Scalars['String'];
  value: Scalars['String'];
};

export type CustomPromptOutputUpdateGraphql = {
  activityId: Scalars['Float'];
  customPromptId: Scalars['Float'];
  funnelTacticId?: InputMaybe<Scalars['Float']>;
  id: Scalars['Float'];
  input: Scalars['String'];
  value: Scalars['String'];
};

export type DashboardInputGraphql = {
  funnelId: Scalars['Int'];
  link: Scalars['String'];
  name: Scalars['String'];
  type: DashboardTypeEnum;
};

export type DashboardOutputGraphql = {
  __typename?: 'DashboardOutputGraphql';
  id: Scalars['Int'];
  link: Scalars['String'];
  name: Scalars['String'];
  type: DashboardTypeEnum;
};

export enum DashboardTypeEnum {
  Facebook = 'facebook',
  General = 'general',
  Google = 'google',
  Instagram = 'instagram'
}

export type DataWarehouseCategoryOutput = {
  __typename?: 'DataWarehouseCategoryOutput';
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
};

export type DataWarehouseDataOutput = {
  __typename?: 'DataWarehouseDataOutput';
  id: Scalars['Int'];
  value: Scalars['String'];
};

export type DataWarehouseDefOutput = {
  __typename?: 'DataWarehouseDefOutput';
  category: DataWarehouseCategoryOutput;
  id: Scalars['Int'];
  label: Scalars['String'];
  lifetime: Scalars['String'];
  name: Scalars['String'];
  searchVisibility: Scalars['Boolean'];
  valueType: Scalars['String'];
};

export type DataWarehouseOutput = {
  __typename?: 'DataWarehouseOutput';
  data: DataWarehouseDataOutput;
  id: Scalars['Int'];
  lifetime: Scalars['Float'];
  name: Scalars['String'];
  searchVisibility: Scalars['Boolean'];
  valueType: Scalars['String'];
};

export type DictionaryOutputGraphql = {
  __typename?: 'DictionaryOutputGraphql';
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type DoneActivityOutputGraphql = {
  __typename?: 'DoneActivityOutputGraphql';
  activity: ActivityOutputGraphql;
  id: Scalars['Int'];
};

export type EditBenchmarksInputGraphql = {
  benchmarkId: Scalars['Int'];
  benchmarkTypeId: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type EditMemberInputGraphql = {
  id: Scalars['Int'];
  role: TeamRoleEnum;
};

export type EditOutputDataInputGraphql = {
  outputId: Scalars['Int'];
  value: Scalars['JSONObject'];
};

export type EditResourcePermissionInputGraphql = {
  /** Resource permission id */
  id: Scalars['Int'];
  permission?: FunnelPermissionEnum;
};

export type EditServiceInputGraphql = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Int'];
};

export type EditTeamInputGraphql = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type FaqCategoryOutputGraphql = {
  __typename?: 'FaqCategoryOutputGraphql';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type FaqOutputGraphql = {
  __typename?: 'FaqOutputGraphql';
  answer: Scalars['String'];
  category: FaqCategoryOutputGraphql;
  id: Scalars['Int'];
  question: Scalars['String'];
};

export enum FieldCategoryEnum {
  AvgProductMargin = 'avgProductMargin',
  B2bProductType = 'b2bProductType',
  B2cProductType = 'b2cProductType',
  Engagement = 'engagement',
  GoalMeasureFirstCustomer = 'goalMeasureFirstCustomer',
  GoalMeasureLeads = 'goalMeasureLeads',
  GoalMeasureSales = 'goalMeasureSales',
  GoalPeriod = 'goalPeriod',
  GoalType = 'goalType',
  MarketingAdvancement = 'marketingAdvancement',
  ObjectiveType = 'objectiveType',
  OfferPolicy = 'offerPolicy',
  PaymentFrequency = 'paymentFrequency',
  PaymentMethod = 'paymentMethod',
  PricingCharacteristics = 'pricingCharacteristics',
  ProductName = 'productName',
  ProductType = 'productType',
  SellingMethod = 'sellingMethod',
  TargetChannels = 'targetChannels',
  TargetCharacteristics = 'targetCharacteristics',
  TargetJob = 'targetJob',
  TargetLocation = 'targetLocation',
  TargetPerson = 'targetPerson',
  TargetSegment = 'targetSegment'
}

export enum FieldTypeEnum {
  Multiselect = 'multiselect',
  Number = 'number',
  Percent = 'percent',
  Range = 'range',
  Select = 'select',
  ShortText = 'shortText'
}

export type FilesArray = {
  __typename?: 'FilesArray';
  extension: Scalars['String'];
  isParsed?: Maybe<Scalars['Boolean']>;
  mimeType: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type FillOutStripeData = {
  __typename?: 'FillOutStripeData';
  feeAmount?: Maybe<Scalars['Int']>;
};

export type FilterInputGraphql = {
  /** Page number */
  page?: Scalars['Int'];
  /** Records per page */
  records?: Scalars['Int'];
  /** Search string */
  search?: InputMaybe<Scalars['String']>;
  /** Selected values */
  selectedValues?: InputMaybe<Array<Scalars['Float']>>;
};

export type FilterItemOutputGraphql = {
  __typename?: 'FilterItemOutputGraphql';
  /** Id */
  id: Scalars['Float'];
  /** Display name */
  name: Scalars['String'];
};

export type FilterOutputGraphql = {
  __typename?: 'FilterOutputGraphql';
  /** Data */
  records: Array<FilterItemOutputGraphql>;
  /** Total records count */
  total: Scalars['Float'];
};

export type FilterTypeOutputActivityPlatformOutputGraphql = {
  __typename?: 'FilterTypeOutputActivityPlatformOutputGraphql';
  /** Id */
  id: Scalars['Float'];
  /** Display name */
  name: Scalars['String'];
  object: ActivityPlatformOutputGraphql;
};

export type FilterTypeOutputStatementParameterOutputGraphql = {
  __typename?: 'FilterTypeOutputStatementParameterOutputGraphql';
  /** Id */
  id: Scalars['Float'];
  /** Display name */
  name: Scalars['String'];
  object: StatementParameterOutputGraphql;
};

export type FilterTypeOutputStepOutputGraphql = {
  __typename?: 'FilterTypeOutputStepOutputGraphql';
  /** Id */
  id: Scalars['Float'];
  /** Display name */
  name: Scalars['String'];
  object: StepOutputGraphql;
};

export type FilterTypeOutputTacticTypeOutputGraphql = {
  __typename?: 'FilterTypeOutputTacticTypeOutputGraphql';
  /** Id */
  id: Scalars['Float'];
  /** Display name */
  name: Scalars['String'];
  object: TacticTypeOutputGraphql;
};

export enum FilterTypes {
  Eq = 'eq',
  Gt = 'gt',
  Gte = 'gte',
  In = 'in',
  Is = 'is',
  IsNot = 'isNot',
  Lt = 'lt',
  Lte = 'lte',
  NotIn = 'notIn'
}

export type FindStatementInstanceInputGraphql = {
  funnelId: Scalars['Float'];
  templateId: Scalars['Float'];
};

export type FlowInputGraphql = {
  items: Array<FlowItemInputGraphql>;
  name: Scalars['String'];
};

export type FlowItemInputGraphql = {
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type FlowItemOutputGraphql = {
  __typename?: 'FlowItemOutputGraphql';
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type FlowOutputGraphql = {
  __typename?: 'FlowOutputGraphql';
  id: Scalars['Int'];
  items: Array<FlowItemOutputGraphql>;
  name: Scalars['String'];
};

export type FunnelInputGraphql = {
  name: Scalars['String'];
  projectName?: InputMaybe<Scalars['String']>;
};

export enum FunnelListTypeEnum {
  /** Show all available funnels */
  All = 'all',
  /** Show funnels that are marked as current user favourites */
  Favourite = 'favourite',
  /** Show only logged user funnels */
  OnlyMy = 'only_my',
  /** Show shared funnels for current user */
  SharedForMe = 'shared_for_me',
  /** Show only public strategic funnels */
  Strategic = 'strategic'
}

export type FunnelOutputGraphql = {
  __typename?: 'FunnelOutputGraphql';
  activitiesCount: Scalars['Int'];
  assetsCount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  currentUserPermission?: Maybe<FunnelPermissionEnum>;
  doneActivitiesCount: Scalars['Int'];
  doneTacticsCount: Scalars['Int'];
  flows: Array<FlowOutputGraphql>;
  id: Scalars['Int'];
  image?: Maybe<AttachmentOutputGraphql>;
  isFavourite: Scalars['Boolean'];
  isPublic?: Maybe<Scalars['Boolean']>;
  lastEditor?: Maybe<PublicUserOutputGraphql>;
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  owner: PublicUserOutputGraphql;
  project?: Maybe<TagOutputGraphql>;
  promotionCode: Scalars['String'];
  recommendationsCount: Scalars['Int'];
  shares: Array<FunnelShareOutputGraphql>;
  stats: Array<FunnelStatusOutputGraphql>;
  tactics: Array<FunnelTacticOutputGraphql>;
  tacticsCount: Scalars['Int'];
};

export enum FunnelPermissionEnum {
  Editor = 'editor',
  Share = 'share',
  ViewOnly = 'viewOnly'
}

export type FunnelPubliclyInputGraphql = {
  funnelId: Scalars['Float'];
  isPublic: Scalars['Boolean'];
};

export type FunnelSetOutputGraphql = {
  __typename?: 'FunnelSetOutputGraphql';
  description: Scalars['String'];
  funnels: Array<FunnelOutputGraphql>;
  id: Scalars['Int'];
  isTopSet: Scalars['Boolean'];
  name: Scalars['String'];
};

export type FunnelShareInputGraphql = {
  emails: Array<Scalars['String']>;
  funnelId: Scalars['Int'];
};

export type FunnelShareOutputGraphql = {
  __typename?: 'FunnelShareOutputGraphql';
  email: Scalars['String'];
  id: Scalars['Int'];
};

export type FunnelStatisticsOutputGraphql = {
  __typename?: 'FunnelStatisticsOutputGraphql';
  activitiesCount: Scalars['Int'];
  assetsCount: Scalars['Int'];
  step: StepOutputGraphql;
  tacticsCount: Scalars['Int'];
};

export type FunnelStatusOutputGraphql = {
  __typename?: 'FunnelStatusOutputGraphql';
  allCount: Scalars['Int'];
  doneCount: Scalars['Int'];
  step: StepOutputGraphql;
};

export type FunnelTacticInputGraphql = {
  funnelId: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  stepId: Scalars['Int'];
  tacticId: Scalars['Int'];
};

export type FunnelTacticOutputGraphql = {
  __typename?: 'FunnelTacticOutputGraphql';
  doneActivities: Array<DoneActivityOutputGraphql>;
  funnel: FunnelOutputGraphql;
  id: Scalars['Int'];
  isDone: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  selectedOutputsData: Array<OutputDataGraphql>;
  step: StepOutputGraphql;
  tactic: TacticOutputGraphql;
};

export type FunnelsListInputGraphql = {
  filters?: InputMaybe<Array<ListFiltersFieldInput>>;
  page?: InputMaybe<Scalars['Int']>;
  records?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<OrderTypes>;
  sortField?: InputMaybe<Scalars['String']>;
  type: FunnelListTypeEnum;
};

export type FunnelsListOutputGraphql = {
  __typename?: 'FunnelsListOutputGraphql';
  count: Scalars['Int'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  records?: Maybe<Array<FunnelOutputGraphql>>;
};

export type GenerateContentInputGraphql = {
  activityAssetId?: InputMaybe<Scalars['Int']>;
  funnelId?: InputMaybe<Scalars['Int']>;
  funnelTacticId?: InputMaybe<Scalars['Int']>;
  inputsData: Array<CreateInputDataGraphql>;
  integrationId: Scalars['Int'];
};

export type GetAssetInputGraphql = {
  content: Scalars['String'];
  funnelId: Scalars['Float'];
  integration: Scalars['String'];
};

export type GetLibraryAssetInputGraphql = {
  filter?: InputMaybe<LibraryFilterInputGraphql>;
  funnelId: Scalars['Float'];
  pagination?: InputMaybe<LibraryPaginationInputGraphql>;
};

export enum GetResponseEvent {
  UserAddedTacticsToFunnel = 'user_added_tactics_to_funnel',
  UserBecomeGrowthHacker = 'user_become_growth_hacker',
  UserBoughtTactic = 'user_bought_tactic',
  UserBroughtUnlimited = 'user_brought_unlimited',
  UserCanceledSubscription = 'user_canceled_subscription',
  UserCanceledTrial = 'user_canceled_trial',
  UserChangedAppSumoPlan = 'user_changed_app_sumo_plan',
  UserCompletedPublicStatement = 'user_completed_public_statement',
  UserCompletedStatement = 'user_completed_statement',
  UserCompletedStatementStep_1 = 'user_completed_statement_step_1',
  UserCompletedStatementStep_2 = 'user_completed_statement_step_2',
  UserCompletedStatementStep_3 = 'user_completed_statement_step_3',
  UserCreatedFlow = 'user_created_flow',
  UserCreatedFunnel = 'user_created_funnel',
  UserCreatedTactic = 'user_created_tactic',
  UserGeneratedContent = 'user_generated_content',
  UserGeneratedDalleGraphic = 'user_generated_dalle_graphic',
  UserGeneratedGraphic = 'user_generated_graphic',
  UserHasPurchasedSubscription = 'user_has_purchased_subscription',
  UserHasRefundedAppSumoPlan = 'user_has_refunded_app_sumo_plan',
  UserHasSignedUp = 'user_has_signed_up',
  UserHasSignedUpThroughAppSumo = 'user_has_signed_up_through_app_sumo',
  UserHasSignedUpThroughLpTacticsPage = 'user_has_signed_up_through_lp_tactics_page',
  UserLoggedIn = 'user_logged_in',
  UserLoggedInThroughLpTacticsPage = 'user_logged_in_through_lp_tactics_page',
  UserParticipatesInThePreSale = 'user_participates_in_the_pre_sale',
  UserPutUpTacticForSale = 'user_put_up_tactic_for_sale',
  UserSeenPricingWithoutBuying = 'user_seen_pricing_without_buying',
  UserSetAtFreeStrategyLpList = 'user_set_at_free_strategy_lp_list',
  UserSkippedStatement = 'user_skipped_statement',
  UserStartsTrial = 'user_starts_trial',
  UserTrialExpired = 'user_trial_expired',
  UserWithPlanSeenPricing = 'user_with_plan_seen_pricing',
  UserWithoutPlanSeenPricing = 'user_without_plan_seen_pricing'
}

export type InputConfigurationOutputGraphql = {
  __typename?: 'InputConfigurationOutputGraphql';
  default?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  label: Scalars['String'];
  metadata?: Maybe<Scalars['JSONObject']>;
  placeholder?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  property: Scalars['String'];
  required: Scalars['Boolean'];
  type: InputTypeEnum;
  validationPatterns: Array<ValidationPatternOutputGraphql>;
};

export type InputDataGraphql = {
  __typename?: 'InputDataGraphql';
  configuration: InputConfigurationOutputGraphql;
  createdAt: Scalars['DateTime'];
  funnel?: Maybe<FunnelOutputGraphql>;
  id: Scalars['Int'];
  modifiedAt: Scalars['DateTime'];
  refNr?: Maybe<Scalars['String']>;
  value: Scalars['JSONObject'];
};

export enum InputTypeEnum {
  Array = 'array',
  Bar = 'bar',
  Checkbox = 'checkbox',
  CustomImageFormatMultiselect = 'customImageFormatMultiselect',
  CustomImageTemplateSelect = 'customImageTemplateSelect',
  InputFile = 'inputFile',
  InputFileFromUnsplash = 'inputFileFromUnsplash',
  InputImageBase64 = 'inputImageBase64',
  InputNumber = 'inputNumber',
  InputText = 'inputText',
  Multiselect = 'multiselect',
  ObjectsArray = 'objectsArray',
  ReachText = 'reachText',
  Select = 'select',
  StringArray = 'stringArray',
  Textarea = 'textarea'
}

export type InputsDataListInputGraphql = {
  activityAssetId: Scalars['Int'];
  funnelTacticId: Scalars['Int'];
  integrationId: Scalars['Int'];
};

export type InputsFilesInputGraphql = {
  authToken?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  value: Scalars['String'];
};

export type InputsInputGraphql = {
  files: Array<InputsFilesInputGraphql>;
  funnelId: Scalars['Float'];
};

export type InputsOutputGraphql = {
  __typename?: 'InputsOutputGraphql';
  brief?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  editedAt: Scalars['DateTime'];
  files?: Maybe<Array<FilesArray>>;
  funnelId: Scalars['Float'];
  id: Scalars['Float'];
  urls?: Maybe<Array<UrlsArray>>;
};

export type IntegrationCategoryOutputGraphql = {
  __typename?: 'IntegrationCategoryOutputGraphql';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type IntegrationOutputGraphql = {
  __typename?: 'IntegrationOutputGraphql';
  category?: Maybe<IntegrationCategoryOutputGraphql>;
  creditsPrice: Scalars['Int'];
  description: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  inputsConfigurations: Array<InputConfigurationOutputGraphql>;
  name: Scalars['String'];
  outputType: OutputTypeEnum;
  outputsConfigurations: Array<OutputConfigurationGraphql>;
  type: IntegrationTypeEnum;
};

/** Integrations types enum */
export enum IntegrationTypeEnum {
  Abyssale = 'abyssale',
  Bannerbear = 'bannerbear',
  ChatGpt = 'chatGPT',
  Dalle = 'dalle',
  Stablediffusion = 'stablediffusion',
  Writesonic = 'writesonic'
}

export type LibraryFilterInputGraphql = {
  integration?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type LibraryPaginationInputGraphql = {
  limit: Scalars['Float'];
  offset: Scalars['Float'];
};

export type ListFiltersFieldInput = {
  field: Scalars['String'];
  operator: FilterTypes;
  value?: InputMaybe<Array<Scalars['String']>>;
};

export type ListInput = {
  filters?: InputMaybe<Array<ListFiltersFieldInput>>;
  page?: InputMaybe<Scalars['Int']>;
  records?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<OrderTypes>;
  sortField?: InputMaybe<Scalars['String']>;
};

export type MarkActivityDoneInputGraphql = {
  activityId: Scalars['Int'];
  funnelTacticId: Scalars['Int'];
  isDone: Scalars['Boolean'];
};

export type MarketingCampaningGraphql = {
  __typename?: 'MarketingCampaningGraphql';
  data: Scalars['JSON'];
  id?: Maybe<Scalars['Int']>;
};

export type MemberUserOutputGraphql = {
  __typename?: 'MemberUserOutputGraphql';
  avatar?: Maybe<AttachmentOutputGraphql>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  experienceYears: Scalars['Int'];
  favouritesCount: Scalars['Int'];
  firstFunnel?: Maybe<FunnelOutputGraphql>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  isBlocked: Scalars['Boolean'];
  isPro: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  services: Array<ServiceOutputGraphql>;
  skills: Array<SkillOutputGraphql>;
  tacticsCount: Scalars['Int'];
  website?: Maybe<Scalars['String']>;
};

export type MessageInputGraphql = {
  calculationId?: InputMaybe<Scalars['Int']>;
  chatId: Scalars['Int'];
  content?: InputMaybe<Scalars['String']>;
};

export type MessageOutputGraphql = {
  __typename?: 'MessageOutputGraphql';
  calculation?: Maybe<BudgetCalculationOutputGraphql>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  sender: PublicUserOutputGraphql;
  type: MessageTypeEnum;
};

export enum MessageTypeEnum {
  Calculation = 'calculation',
  Text = 'text'
}

export type MessagesListInputGraphql = {
  chatId: Scalars['Int'];
  filters?: InputMaybe<Array<ListFiltersFieldInput>>;
  page?: InputMaybe<Scalars['Int']>;
  records?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<OrderTypes>;
  sortField?: InputMaybe<Scalars['String']>;
};

export type MessagesListOutputGraphql = {
  __typename?: 'MessagesListOutputGraphql';
  count: Scalars['Int'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  records?: Maybe<Array<MessageOutputGraphql>>;
};

export type MetricOutputGraphql = {
  __typename?: 'MetricOutputGraphql';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type MixpanelTrackEventInputGraphql = {
  eventData: Scalars['JSONObject'];
  eventName: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAssetToActivity: ActivityAssetOutputGraphql;
  addBenchmarkToActivity: BenchmarkOutputGraphql;
  addCanvas: CanvasTemplateOutput;
  addCanvasFormSection: CanvasSectionsOutput;
  /** Add selected funnel to favourites */
  addFunnelToFavourites: Scalars['Boolean'];
  /** Create new resource permission for team member */
  addResourcePermission: ResourceTeamOutputGraphql;
  /** Add selected tactic to favourites */
  addTacticToFavourites: Scalars['Boolean'];
  /** Add new team members */
  addTeamMembers: Array<TeamMemberOutputGraphql>;
  addToFunnel: Scalars['Boolean'];
  addUserSkill: UserOutputGraphql;
  changePartnerUserPlan: SubscriptionPlanOutputGraphql;
  changePassword: UserOutputGraphql;
  /** Change privacy of selected tactic */
  changeTacticPrivacy: Scalars['Boolean'];
  changeTacticVerificationStatus: Scalars['Boolean'];
  changeUserPlan: Scalars['Boolean'];
  changeUserStatus: Scalars['Boolean'];
  checkCompanyDataExist: Scalars['Boolean'];
  cloneActivity: ActivityOutputGraphql;
  cloneFunnel: FunnelOutputGraphql;
  cloneFunnelForAnonymousUser: FunnelOutputGraphql;
  cloneTactic: TacticOutputGraphql;
  confirmEmail: AuthOutputGraphql;
  confirmEmailChange: Scalars['Boolean'];
  confirmPasswordReset: Scalars['Boolean'];
  copyActivitiesToTactic: TacticOutputGraphql;
  copyFunnelContent: FunnelOutputGraphql;
  createActivity: ActivityOutputGraphql;
  createActivityImage: AttachmentOutputGraphql;
  createActivityLogo: AttachmentOutputGraphql;
  createAyrshareAccount: AyrshareCreateAccountOutputGraphql;
  createBudgetCalculation: BudgetCalculationOutputGraphql;
  createCampanignStatement: Scalars['Boolean'];
  /** @deprecated Canva integration has been turned off */
  createCanvaCreation: CanvaCreationOutputGraphql;
  createCanvasInstance: CanvasInstanceOutput;
  /** crate image used in content generation */
  createContentGenerationImage: AttachmentOutputGraphql;
  /** Create new prompt */
  createCustomPrompt: CustomPromptOutputGraphql;
  createCustomPromptOutput: CustomPromptOutputOutputGraphql;
  createDashboard: DashboardOutputGraphql;
  createEmptyTactic: TacticOutputGraphql;
  createFlow: FlowOutputGraphql;
  createFunnel: FunnelOutputGraphql;
  createFunnelImage: AttachmentOutputGraphql;
  createFunnelTactic: FunnelTacticOutputGraphql;
  createManyFunnelsTactics: Array<FunnelTacticOutputGraphql>;
  createNewCampanign: CreateMarketingCampaignGraphql;
  createNewComparison: CreateMarketingCampaignGraphql;
  createPromoter: Scalars['Boolean'];
  /** Create new Recommendation */
  createRecomendation: RecommendationSystemOutput;
  /** Create new resource permission for selected team members */
  createResourceTeam: ResourceTeamOutputGraphql;
  createService: ServiceOutputGraphql;
  createServiceOrderBrief: ServiceOrderOutputGraphql;
  createStatement: StatementV2OutputGraphql;
  /** Create new Statement Template */
  createStatementV3Template: StatementV3OutputGraphql;
  createSubscription: CreateSubscriptionOutputGraphql;
  createTactic: TacticOutputGraphql;
  createTacticImage: AttachmentOutputGraphql;
  createTacticMetric: TacticMetricOutputGraphql;
  /** Returns newly created team */
  createTeam: TeamOutputGraphql;
  createUserAvatar: UserOutputGraphql;
  deactivatePartnerUserSubscription: Scalars['Boolean'];
  deleteAsset: MutationStatusAssetOutputGraphql;
  deleteInput: Scalars['Boolean'];
  deletePost: Scalars['Boolean'];
  downloadOutputsDataZip: AttachmentOutputGraphql;
  duplicateFunnelTactic: FunnelTacticOutputGraphql;
  editActivity: ActivityOutputGraphql;
  editBenchmark: BenchmarkOutputGraphql;
  /** @deprecated Canva integration has been turned off */
  editCanvaCreation: CanvaCreationOutputGraphql;
  editFlow: FlowOutputGraphql;
  editFunnel: FunnelOutputGraphql;
  editOrAddCategory: CanvasCategoryOutput;
  editOutputData: OutputDataGraphql;
  editPartnerUser: UserOutputGraphql;
  editProfile: UserOutputGraphql;
  /** Edit selected resource permissions */
  editResourcePermission: ResourcePermissionOutputGraphql;
  editService: ServiceOutputGraphql;
  editTactic: TacticOutputGraphql;
  editTacticMetric: TacticMetricOutputGraphql;
  /** Returns updated team */
  editTeam: TeamOutputGraphql;
  /** Edit team member */
  editTeamMember: TeamMemberOutputGraphql;
  endTutorial: Scalars['Boolean'];
  fillPublicFirstStep: Scalars['Boolean'];
  fillStatementFirstStep: StatementOutputGraphql;
  fillStatementSecondStep: StatementOutputGraphql;
  fillStatementThirdStep: StatementOutputGraphql;
  generateContent: ContentGenerationOutputGraphql;
  generateContentByFunnel: ContentGenerationOutputGraphql;
  /** Generates images for funnel base on data from funnel statement */
  generateDefaultImages: ContentGenerationOutputGraphql;
  generateImagesForFunnel: ContentGenerationOutputGraphql;
  generateRetoolAuthToken: Scalars['String'];
  generateServiceOrderPaymentUrl: ServiceOrderPaymentOutputGraphql;
  generateSubscriptionsManagementUrl: SubscriptionsManagementOutputGraphql;
  generateUserStripeAccountLoginUrl: Scalars['String'];
  generateUserStripeVerificationUrl: Scalars['String'];
  inputDeleteByRefNr: Scalars['Boolean'];
  /** Remove current user membership from selected team */
  leaveTeam: Scalars['Boolean'];
  login: AuthOutputGraphql;
  loginAsUser: AuthOutputGraphql;
  loginByPartner: AuthOutputGraphql;
  loginRetool: Scalars['String'];
  loginWithSocialMediaAccount: AuthOutputGraphql;
  markActivityDone: Scalars['Boolean'];
  markFunnelTacticAsDone: FunnelTacticOutputGraphql;
  markNotificationAsReceived: NotificationOutputGraphql;
  markOutputDataAsSelected: OutputDataGraphql;
  outputDeleteByRefNr: Scalars['Boolean'];
  performPromptBaseOnCompanyData: Scalars['Boolean'];
  prepareActivityAsset: ActivityAssetOutputGraphql;
  publishPost: Array<AyrsharePublishPostOutputGraphql>;
  rateTactic: TacticRateOutputGraphql;
  regenerate: Scalars['Boolean'];
  /** Register new user */
  register: AuthOutputGraphql;
  /** Register new user */
  registerAppSumo: AuthOutputGraphql;
  /** Register new user */
  registerByPartner: AuthOutputGraphql;
  removeActivity: Scalars['Boolean'];
  removeAssetFromActivity: Scalars['Boolean'];
  removeAttachment: Scalars['Boolean'];
  removeBenchmarkFromActivity: Scalars['Boolean'];
  /** @deprecated Canva integration has been turned off */
  removeCanvaCreation: Scalars['Boolean'];
  removeCanvasCategory: Scalars['Boolean'];
  /** Deletes prompt */
  removeCustomPrompt: Scalars['Boolean'];
  removeDashboard: Scalars['Boolean'];
  removeDiscordAccount: UserOutputGraphql;
  removeFlow: Scalars['Boolean'];
  removeFromFunnel: Scalars['Boolean'];
  removeFunnel: Scalars['Boolean'];
  /** Remove selected funnel from favourites. */
  removeFunnelFromFavourites: Scalars['Boolean'];
  removeFunnelShare: Scalars['Boolean'];
  removeFunnelTactic: Scalars['Boolean'];
  removeOutputData: Scalars['Boolean'];
  removePartnerUser: Scalars['Boolean'];
  removeProfile: Scalars['Boolean'];
  removePromoter: Scalars['Boolean'];
  /** Remove selected resource permissions */
  removeResourcePermission: Scalars['Boolean'];
  /** Remove resource permission for all team */
  removeResourceTeam: Scalars['Boolean'];
  removeService: Scalars['Boolean'];
  /** Deletes Statement Template */
  removeStatementV3Template: Scalars['Boolean'];
  removeTactic?: Maybe<Scalars['Boolean']>;
  /** Remove selected tactic from favourites. */
  removeTacticFromFavourites: Scalars['Boolean'];
  removeTacticMetric: Scalars['Boolean'];
  /** Remove selected tactic share */
  removeTacticShare: Scalars['Boolean'];
  removeTags: Scalars['Boolean'];
  /** Returns true after team remove */
  removeTeam: Scalars['Boolean'];
  /** Remove team member */
  removeTeamMember: Scalars['Boolean'];
  removeUserSkill: UserOutputGraphql;
  reorderActivities: Array<ActivityOutputGraphql>;
  reorderActivity: ActivityOutputGraphql;
  reorderFunnelTactic: FunnelTacticOutputGraphql;
  requestPasswordReset: Scalars['Boolean'];
  resendEmailChange: Scalars['Boolean'];
  resendEmailConfirm: Scalars['Boolean'];
  saveAccountSetup: Scalars['Boolean'];
  saveDiscordAccount: UserOutputGraphql;
  saveDiscordName: UserOutputGraphql;
  saveEmailToGetResponse: Scalars['Boolean'];
  saveInputData: InputsOutputGraphql;
  saveLibraryAsset: MutationStatusAssetOutputGraphql;
  saveStatementInstance: StatementV3InstanceGraphql;
  sendChatMessage: MessageOutputGraphql;
  sendHelpMessage: Scalars['Boolean'];
  sendMessage: Scalars['Boolean'];
  sendMessageToChatGPT: ContentGenerationOutputGraphql;
  sendPublicFirstStepByEmail: Scalars['Boolean'];
  sendTestSubscription: Scalars['Boolean'];
  setAffiliationId: UserOutputGraphql;
  setAppSumoSubscription: UserOutputGraphql;
  setCompanyData: Scalars['Boolean'];
  setContextFunnel: Scalars['Boolean'];
  setFunnelPublicly: FunnelOutputGraphql;
  setFunnelToOwner: FunnelOutputGraphql;
  setProBadge: Scalars['Boolean'];
  setServiceOrderPrice: ServiceOrderOutputGraphql;
  /** @deprecated Marketplace has been turned off */
  setTacticAsFree: TacticOutputGraphql;
  /** @deprecated Marketplace has been turned off */
  setTacticAsPayed: TacticOutputGraphql;
  setTacticDelayDays: Scalars['Boolean'];
  shareFunnel: FunnelOutputGraphql;
  /** Create new tactic shares and notify users about it via email.If the tactic has already been shared to given user, he will receive an email notification again. */
  shareTactic: TacticOutputGraphql;
  trackEvent: Scalars['Boolean'];
  triggerGetResponseEvent: Scalars['Boolean'];
  updateCustomPrompt: CustomPromptOutputGraphql;
  updateCustomPromptOutput: CustomPromptOutputOutputGraphql;
  updateFunnelTactic: FunnelTacticOutputGraphql;
  updateStatementV3Template: StatementV3OutputGraphql;
  updateUserCredits: Scalars['Boolean'];
  userEndedOnboarding: UserOutputGraphql;
};


export type MutationAddAssetToActivityArgs = {
  input: AddAssetInputGraphql;
};


export type MutationAddBenchmarkToActivityArgs = {
  attachmentId?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['Upload']>;
  input: AddBenchmarkInputGraphql;
};


export type MutationAddCanvasArgs = {
  input: CanvasFormInput;
};


export type MutationAddCanvasFormSectionArgs = {
  input: CanvasSectionInput;
};


export type MutationAddFunnelToFavouritesArgs = {
  funnelId: Scalars['Int'];
};


export type MutationAddResourcePermissionArgs = {
  input: CreateResourcePermissionInputGraphql;
};


export type MutationAddTacticToFavouritesArgs = {
  tacticId: Scalars['Int'];
};


export type MutationAddTeamMembersArgs = {
  input: AddMembersInputGraphql;
};


export type MutationAddToFunnelArgs = {
  tacticId: Scalars['Int'];
};


export type MutationAddUserSkillArgs = {
  skillId: Scalars['Int'];
};


export type MutationChangePartnerUserPlanArgs = {
  newPlanId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInputGraphql;
};


export type MutationChangeTacticPrivacyArgs = {
  private: Scalars['Boolean'];
  tacticId: Scalars['Int'];
};


export type MutationChangeTacticVerificationStatusArgs = {
  id: Scalars['Int'];
  status?: InputMaybe<VerificationStatusEnum>;
};


export type MutationChangeUserPlanArgs = {
  newPlanId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationChangeUserStatusArgs = {
  isBlocked: Scalars['Boolean'];
  userId: Scalars['Int'];
};


export type MutationCheckCompanyDataExistArgs = {
  funnelId: Scalars['Int'];
};


export type MutationCloneActivityArgs = {
  activityId: Scalars['Int'];
  tacticId: Scalars['Int'];
};


export type MutationCloneFunnelArgs = {
  funnelId: Scalars['Int'];
};


export type MutationCloneFunnelForAnonymousUserArgs = {
  funnelId: Scalars['Int'];
};


export type MutationCloneTacticArgs = {
  id: Scalars['Int'];
};


export type MutationConfirmEmailArgs = {
  token: Scalars['String'];
};


export type MutationConfirmEmailChangeArgs = {
  token: Scalars['String'];
};


export type MutationConfirmPasswordResetArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCopyActivitiesToTacticArgs = {
  dstTacticId: Scalars['Int'];
  srcTacticId: Scalars['Int'];
};


export type MutationCopyFunnelContentArgs = {
  destinationFunnelId: Scalars['Int'];
  funnelToCopyId: Scalars['Int'];
};


export type MutationCreateActivityArgs = {
  input: ActivityInputGraphql;
  tacticId: Scalars['Int'];
};


export type MutationCreateActivityImageArgs = {
  activityId: Scalars['Int'];
  file: Scalars['Upload'];
};


export type MutationCreateActivityLogoArgs = {
  activityId: Scalars['Int'];
  file: Scalars['Upload'];
};


export type MutationCreateBudgetCalculationArgs = {
  input: BudgetCalculationInputGraphql;
};


export type MutationCreateCampanignStatementArgs = {
  input: StatementInputGraphql;
};


export type MutationCreateCanvaCreationArgs = {
  input: CanvaCreationCreateInputGraphql;
};


export type MutationCreateCanvasInstanceArgs = {
  input: CanvasCreateInstanceInput;
};


export type MutationCreateContentGenerationImageArgs = {
  file: Scalars['Upload'];
  funnelId: Scalars['Int'];
};


export type MutationCreateCustomPromptArgs = {
  customPromptInput: CustomPromptInputGraphql;
};


export type MutationCreateCustomPromptOutputArgs = {
  customPromptOutputCreate: CustomPromptOutputCreateGraphql;
};


export type MutationCreateDashboardArgs = {
  input: DashboardInputGraphql;
};


export type MutationCreateFlowArgs = {
  funnelId: Scalars['Int'];
  input: FlowInputGraphql;
};


export type MutationCreateFunnelArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  input: FunnelInputGraphql;
};


export type MutationCreateFunnelImageArgs = {
  file: Scalars['Upload'];
  funnelId: Scalars['Int'];
};


export type MutationCreateFunnelTacticArgs = {
  input: FunnelTacticInputGraphql;
};


export type MutationCreateManyFunnelsTacticsArgs = {
  funnelId: Scalars['Int'];
  inputs: Array<CreateManyFunnelTacticInputGraphql>;
};


export type MutationCreateNewCampanignArgs = {
  input: CreateMarketingCampaignInputGraphql;
};


export type MutationCreateNewComparisonArgs = {
  input: CreateMarketingComparisonInputGraphql;
};


export type MutationCreatePromoterArgs = {
  refId: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationCreateRecomendationArgs = {
  input: RecomendationSystemInput;
};


export type MutationCreateResourceTeamArgs = {
  input: CreateResourceTeamInputGraphql;
};


export type MutationCreateServiceArgs = {
  input: CreateServiceInputGraphql;
};


export type MutationCreateServiceOrderBriefArgs = {
  input: ServiceOrderInputGraphql;
};


export type MutationCreateStatementArgs = {
  input: StatementInputGraphql;
};


export type MutationCreateStatementV3TemplateArgs = {
  input: StatementV3InputGraphql;
};


export type MutationCreateSubscriptionArgs = {
  isTrial?: Scalars['Boolean'];
  planId: Scalars['Int'];
  priceId?: InputMaybe<Scalars['Int']>;
  promotionCode?: InputMaybe<Scalars['String']>;
};


export type MutationCreateTacticArgs = {
  input: TacticInputGraphql;
};


export type MutationCreateTacticImageArgs = {
  file: Scalars['Upload'];
  tacticId: Scalars['Int'];
};


export type MutationCreateTacticMetricArgs = {
  input: TacticMetricInputGraphql;
};


export type MutationCreateTeamArgs = {
  icon?: InputMaybe<Scalars['Upload']>;
  input: CreateTeamInputGraphql;
};


export type MutationCreateUserAvatarArgs = {
  file: Scalars['Upload'];
};


export type MutationDeactivatePartnerUserSubscriptionArgs = {
  userId: Scalars['Int'];
};


export type MutationDeleteAssetArgs = {
  assetId: Scalars['Int'];
};


export type MutationDeleteInputArgs = {
  funnelId: Scalars['Int'];
};


export type MutationDeletePostArgs = {
  id: Scalars['String'];
};


export type MutationDownloadOutputsDataZipArgs = {
  funnelId: Scalars['Int'];
  name: Scalars['String'];
  outputsDataIds: Array<Scalars['Int']>;
};


export type MutationDuplicateFunnelTacticArgs = {
  funnelTacticId: Scalars['Int'];
};


export type MutationEditActivityArgs = {
  activityId: Scalars['Int'];
  input: ActivityInputGraphql;
};


export type MutationEditBenchmarkArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  input: EditBenchmarksInputGraphql;
};


export type MutationEditCanvaCreationArgs = {
  input: CanvaCreationUpdateInputGraphql;
};


export type MutationEditFlowArgs = {
  flowId: Scalars['Int'];
  input: FlowInputGraphql;
};


export type MutationEditFunnelArgs = {
  funnelId: Scalars['Int'];
  input: FunnelInputGraphql;
};


export type MutationEditOrAddCategoryArgs = {
  input: CanvasCategoryAddOrInputInput;
};


export type MutationEditOutputDataArgs = {
  input: EditOutputDataInputGraphql;
};


export type MutationEditPartnerUserArgs = {
  input: PartnerUserEditInputGraphql;
};


export type MutationEditProfileArgs = {
  input: UserProfileInputGraphql;
};


export type MutationEditResourcePermissionArgs = {
  input: EditResourcePermissionInputGraphql;
};


export type MutationEditServiceArgs = {
  input: EditServiceInputGraphql;
};


export type MutationEditTacticArgs = {
  id: Scalars['Float'];
  input: TacticInputGraphql;
};


export type MutationEditTacticMetricArgs = {
  id: Scalars['Int'];
  input: TacticMetricInputGraphql;
};


export type MutationEditTeamArgs = {
  icon?: InputMaybe<Scalars['Upload']>;
  input: EditTeamInputGraphql;
};


export type MutationEditTeamMemberArgs = {
  input: EditMemberInputGraphql;
};


export type MutationEndTutorialArgs = {
  tutorialEnded: Scalars['Boolean'];
};


export type MutationFillPublicFirstStepArgs = {
  input: StatementFirstInputGraphql;
};


export type MutationFillStatementFirstStepArgs = {
  input: StatementFirstInputGraphql;
};


export type MutationFillStatementSecondStepArgs = {
  funnelId: Scalars['Int'];
  input: Array<Scalars['Int']>;
};


export type MutationFillStatementThirdStepArgs = {
  funnelId: Scalars['Int'];
  input: Array<Scalars['Int']>;
};


export type MutationGenerateContentArgs = {
  input: GenerateContentInputGraphql;
};


export type MutationGenerateContentByFunnelArgs = {
  input: GenerateContentInputGraphql;
};


export type MutationGenerateDefaultImagesArgs = {
  funnelId: Scalars['Int'];
};


export type MutationGenerateImagesForFunnelArgs = {
  input: GenerateContentInputGraphql;
};


export type MutationGenerateServiceOrderPaymentUrlArgs = {
  id: Scalars['Int'];
};


export type MutationInputDeleteByRefNrArgs = {
  refNr: Scalars['String'];
};


export type MutationLeaveTeamArgs = {
  id: Scalars['Int'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  redirectedFromTacticsLpPage?: InputMaybe<Scalars['Boolean']>;
  rememberMe: Scalars['Boolean'];
};


export type MutationLoginAsUserArgs = {
  email: Scalars['String'];
};


export type MutationLoginByPartnerArgs = {
  email: Scalars['String'];
  tokenLifetime?: Scalars['Int'];
};


export type MutationLoginRetoolArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationLoginWithSocialMediaAccountArgs = {
  input: SocialLoginInputGraphql;
};


export type MutationMarkActivityDoneArgs = {
  input: MarkActivityDoneInputGraphql;
};


export type MutationMarkFunnelTacticAsDoneArgs = {
  funnelTacticId: Scalars['Int'];
  isDone: Scalars['Boolean'];
};


export type MutationMarkNotificationAsReceivedArgs = {
  id: Scalars['Int'];
};


export type MutationMarkOutputDataAsSelectedArgs = {
  isSelected: Scalars['Boolean'];
  outputDataId: Scalars['Int'];
};


export type MutationOutputDeleteByRefNrArgs = {
  refNr: Scalars['String'];
};


export type MutationPerformPromptBaseOnCompanyDataArgs = {
  input: PerformPromptBaseOnCompanyDataInput;
};


export type MutationPrepareActivityAssetArgs = {
  input: AddAssetInputGraphql;
};


export type MutationPublishPostArgs = {
  input: AyrshareCreatePostInputGraphql;
};


export type MutationRateTacticArgs = {
  input: TacticRateInputGraphql;
};


export type MutationRegenerateArgs = {
  funnelId: Scalars['Int'];
};


export type MutationRegisterArgs = {
  input: RegisterInputGraphql;
};


export type MutationRegisterAppSumoArgs = {
  input: AppSumoRegisterInputGraphql;
};


export type MutationRegisterByPartnerArgs = {
  input: PartnerRegisterInputGraphql;
};


export type MutationRemoveActivityArgs = {
  activityId: Scalars['Int'];
};


export type MutationRemoveAssetFromActivityArgs = {
  activityAssetId: Scalars['Int'];
};


export type MutationRemoveAttachmentArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveBenchmarkFromActivityArgs = {
  activityId: Scalars['Int'];
  benchmarkId: Scalars['Int'];
};


export type MutationRemoveCanvaCreationArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveCanvasCategoryArgs = {
  id: Scalars['Float'];
};


export type MutationRemoveCustomPromptArgs = {
  customPromptId: Scalars['Int'];
};


export type MutationRemoveDashboardArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveFlowArgs = {
  flowId: Scalars['Int'];
};


export type MutationRemoveFromFunnelArgs = {
  tacticId: Scalars['Int'];
};


export type MutationRemoveFunnelArgs = {
  funnelId: Scalars['Int'];
};


export type MutationRemoveFunnelFromFavouritesArgs = {
  funnelId: Scalars['Int'];
};


export type MutationRemoveFunnelShareArgs = {
  shareId: Scalars['Int'];
};


export type MutationRemoveFunnelTacticArgs = {
  funnelTacticId: Scalars['Int'];
};


export type MutationRemoveOutputDataArgs = {
  outputDataId: Scalars['Int'];
};


export type MutationRemovePartnerUserArgs = {
  userId: Scalars['Int'];
};


export type MutationRemovePromoterArgs = {
  userId: Scalars['Int'];
};


export type MutationRemoveResourcePermissionArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveResourceTeamArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveServiceArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveStatementV3TemplateArgs = {
  statementTemplateId: Scalars['Int'];
};


export type MutationRemoveTacticArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveTacticFromFavouritesArgs = {
  tacticId: Scalars['Int'];
};


export type MutationRemoveTacticMetricArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveTacticShareArgs = {
  shareId: Scalars['Int'];
};


export type MutationRemoveTagsArgs = {
  tagId: Scalars['Int'];
};


export type MutationRemoveTeamArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveTeamMemberArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveUserSkillArgs = {
  skillId: Scalars['Int'];
};


export type MutationReorderActivitiesArgs = {
  positions: Array<ReorderActivityInputGraphql>;
  tacticId: Scalars['Int'];
};


export type MutationReorderActivityArgs = {
  activityId: Scalars['Int'];
  position: Scalars['Int'];
};


export type MutationReorderFunnelTacticArgs = {
  input: ReorderTacticInputGraphql;
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationResendEmailConfirmArgs = {
  email: Scalars['String'];
};


export type MutationSaveAccountSetupArgs = {
  parameters: Array<SaveAccountSetupInputGraphql>;
  worksFor: WorksForEnum;
};


export type MutationSaveDiscordAccountArgs = {
  code: Scalars['String'];
};


export type MutationSaveDiscordNameArgs = {
  discordName: Scalars['String'];
};


export type MutationSaveEmailToGetResponseArgs = {
  email: Scalars['String'];
  funnelId: Scalars['Int'];
};


export type MutationSaveInputDataArgs = {
  input: InputsInputGraphql;
};


export type MutationSaveLibraryAssetArgs = {
  input: SaveLibraryAssetInputGraphql;
};


export type MutationSaveStatementInstanceArgs = {
  input: ResolveStatementInputGraphql;
};


export type MutationSendChatMessageArgs = {
  input: MessageInputGraphql;
};


export type MutationSendHelpMessageArgs = {
  input: SendHelpMessageInputGraphql;
};


export type MutationSendMessageArgs = {
  input: SendMessageInputGraphql;
};


export type MutationSendMessageToChatGptArgs = {
  input: GenerateContentInputGraphql;
};


export type MutationSendPublicFirstStepByEmailArgs = {
  email: Scalars['String'];
  file: Scalars['Upload'];
  input: StatementFirstInputGraphql;
};


export type MutationSendTestSubscriptionArgs = {
  text: Scalars['String'];
};


export type MutationSetAffiliationIdArgs = {
  affiliationId: Scalars['String'];
};


export type MutationSetAppSumoSubscriptionArgs = {
  token: Scalars['String'];
};


export type MutationSetCompanyDataArgs = {
  input: SetCompanyDataInputGraphql;
};


export type MutationSetContextFunnelArgs = {
  funnelId: Scalars['Int'];
};


export type MutationSetFunnelPubliclyArgs = {
  input: FunnelPubliclyInputGraphql;
};


export type MutationSetFunnelToOwnerArgs = {
  funnelId: Scalars['Int'];
};


export type MutationSetProBadgeArgs = {
  isPro: Scalars['Boolean'];
  userId: Scalars['Int'];
};


export type MutationSetServiceOrderPriceArgs = {
  id: Scalars['Int'];
  input: ServiceOrderPriceInputGraphql;
};


export type MutationSetTacticAsFreeArgs = {
  id: Scalars['Int'];
};


export type MutationSetTacticAsPayedArgs = {
  input: PayedTacticInputGraphql;
};


export type MutationSetTacticDelayDaysArgs = {
  delayDays: Scalars['Int'];
  tacticId: Scalars['Int'];
};


export type MutationShareFunnelArgs = {
  input: FunnelShareInputGraphql;
};


export type MutationShareTacticArgs = {
  input: TacticShareInputGraphql;
};


export type MutationTrackEventArgs = {
  input: MixpanelTrackEventInputGraphql;
};


export type MutationTriggerGetResponseEventArgs = {
  eventType: GetResponseEvent;
  funnelId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateCustomPromptArgs = {
  updateCustomPromptInput: UpdateCustomPromptInputGraphql;
};


export type MutationUpdateCustomPromptOutputArgs = {
  customPromptOutputUpdate: CustomPromptOutputUpdateGraphql;
};


export type MutationUpdateFunnelTacticArgs = {
  funnelTacticId: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateStatementV3TemplateArgs = {
  input: StatementV3InputGraphql;
};


export type MutationUpdateUserCreditsArgs = {
  credits: Scalars['Int'];
  userId: Scalars['Int'];
};

export type MutationStatusAssetOutputGraphql = {
  __typename?: 'MutationStatusAssetOutputGraphql';
  process: Scalars['String'];
  success: Scalars['Boolean'];
};

export type NotificationOutputGraphql = {
  __typename?: 'NotificationOutputGraphql';
  createdAt: Scalars['DateTime'];
  data?: Maybe<FillOutStripeData>;
  id: Scalars['Int'];
  message: Scalars['String'];
  received: Scalars['Boolean'];
  type: NotificationTypeEnum;
};

export enum NotificationTypeEnum {
  BookAdemo = 'bookAdemo',
  FillOutProfile = 'fillOutProfile',
  FillOutStripe = 'fillOutStripe',
  Novu = 'novu'
}

export enum OrderTypes {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OutputConfigurationGraphql = {
  __typename?: 'OutputConfigurationGraphql';
  id: Scalars['Int'];
  metadata?: Maybe<Scalars['JSONObject']>;
  outputProperties: Array<OutputPropertyGraphql>;
  type: OutputTypeEnum;
  validationPatterns: Array<ValidationPatternOutputGraphql>;
};

export type OutputDataGraphql = {
  __typename?: 'OutputDataGraphql';
  activityAsset?: Maybe<ActivityAssetOutputGraphql>;
  configuration: OutputConfigurationGraphql;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  integration: IntegrationOutputGraphql;
  isSelected: Scalars['Boolean'];
  metadata?: Maybe<Scalars['JSONObject']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  originalValue: Scalars['JSONObject'];
  refNr?: Maybe<Scalars['String']>;
  tactic?: Maybe<TacticOutputGraphql>;
  value: Scalars['JSONObject'];
};

export type OutputPropertyGraphql = {
  __typename?: 'OutputPropertyGraphql';
  id: Scalars['Int'];
  type: InputTypeEnum;
  validationPatterns: Array<ValidationPatternOutputGraphql>;
};

export enum OutputTypeEnum {
  Base64 = 'base64',
  File = 'file',
  Image = 'image',
  Object = 'object',
  Text = 'text',
  Url = 'url',
  Video = 'video'
}

export type OutputsDataListInputGraphql = {
  activityAssetId: Scalars['Int'];
  funnelTacticId: Scalars['Int'];
  integrationId: Scalars['Int'];
};

export type OutputsDataListOutputGraphql = {
  __typename?: 'OutputsDataListOutputGraphql';
  count: Scalars['Int'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  records?: Maybe<Array<OutputDataGraphql>>;
};

export type OutputsDataSearchInputGraphql = {
  filters?: InputMaybe<Array<ListFiltersFieldInput>>;
  page?: InputMaybe<Scalars['Int']>;
  records?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<OrderTypes>;
  sortField?: InputMaybe<Scalars['String']>;
};

export type PartnerRegisterInputGraphql = {
  /** E-mail */
  email: Scalars['String'];
  /** First name */
  firstName: Scalars['String'];
  /** Last name */
  lastName?: InputMaybe<Scalars['String']>;
  /** User metadata. It allows you to save any json data on registered user */
  metadata?: InputMaybe<Scalars['JSONObject']>;
  /** Is rules accepted */
  rulesAccepted: Scalars['Boolean'];
  /** JWT token lifetime in hours */
  tokenLifetime?: Scalars['Int'];
  /** Field for semrush */
  unlimitedPlan?: InputMaybe<Scalars['Boolean']>;
};

export type PartnerUserEditInputGraphql = {
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  experienceYears?: InputMaybe<Scalars['Float']>;
  firstName?: InputMaybe<Scalars['String']>;
  /** Selected user id */
  id: Scalars['Int'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
  position?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type PayedTacticInputGraphql = {
  copyForSale?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  price: Scalars['Int'];
};

export enum PaymentIntervalEnum {
  /** lifetime */
  Lifetime = 'lifetime',
  /** month */
  Month = 'month',
  /** week */
  Week = 'week',
  /** year */
  Year = 'year'
}

export type PerformPromptBaseOnCompanyDataInput = {
  activityId: Scalars['Int'];
  funnelId: Scalars['Int'];
  funnelTacticId: Scalars['Int'];
  promptId: Scalars['Int'];
};

export type PermissionOutputGraphql = {
  __typename?: 'PermissionOutputGraphql';
  id: Scalars['Int'];
  isAvailable: Scalars['Boolean'];
  isAvailableInAppSumo: Scalars['Boolean'];
  name: Scalars['String'];
  plansCount: Scalars['Int'];
  type: PermissionType;
};

export enum PermissionType {
  AiCopywriting = 'ai_copywriting',
  AnalyticsIntegration = 'analytics_integration',
  BecomeAGrowthHacker = 'become_a_growth_hacker',
  CanvasExport = 'canvas_export',
  CanvasPreview = 'canvas_preview',
  DiscordCommunityAccess = 'discord_community_access',
  ExportPdf = 'export_pdf',
  FavouriteTactics = 'favourite_tactics',
  FlowManagement = 'flow_management',
  FunnelAndTacticShare = 'funnel_and_tactic_share',
  FunnelManagement = 'funnel_management',
  FunnelPreview = 'funnel_preview',
  GraphicGenerator = 'graphic_generator',
  HireGrowthHackers = 'hire_growth_hackers',
  IsSemrushUser = 'is_semrush_user',
  Nft = 'nft',
  PublicTactics = 'public_tactics',
  PublishTacticsAndServices = 'publish_tactics_and_services',
  TacticsCreate = 'tactics_create',
  TacticsCreatorProfile = 'tactics_creator_profile',
  TacticsLibrary = 'tactics_library',
  TacticsRecommendations = 'tactics_recommendations',
  TacticsShare = 'tactics_share',
  TaskManagersIntegration = 'task_managers_integration',
  TeamCollaboration = 'team_collaboration',
  UsersNumber = 'users_number'
}

export type PlanPermissionOutputGraphql = {
  __typename?: 'PlanPermissionOutputGraphql';
  hasLimit: Scalars['Boolean'];
  id: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  permissionType: PermissionOutputGraphql;
};

export type PriceOutputGraphql = {
  __typename?: 'PriceOutputGraphql';
  amount: Scalars['Int'];
  currency: Scalars['String'];
  id: Scalars['Int'];
  paymentInterval: PaymentIntervalEnum;
  paymentIntervalCount: Scalars['Int'];
};

export type PromotionDataOutputGraphql = {
  __typename?: 'PromotionDataOutputGraphql';
  currency?: Maybe<Scalars['String']>;
  /** Promotion duration, if null, promotion lasts forever */
  durationInMonths?: Maybe<Scalars['Int']>;
  type: PromotionTypeEnum;
  /** Promotion value, if promotion is fixed, the value will be amount in smallest unit of currency (cent, gr etc.) */
  value: Scalars['Float'];
};

export enum PromotionTypeEnum {
  Fixed = 'fixed',
  Percent = 'percent'
}

export type PublicUserListOutputGraphql = {
  __typename?: 'PublicUserListOutputGraphql';
  count: Scalars['Int'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  records?: Maybe<Array<PublicUserOutputGraphql>>;
};

export type PublicUserOutputGraphql = {
  __typename?: 'PublicUserOutputGraphql';
  avatar?: Maybe<AttachmentOutputGraphql>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  experienceYears: Scalars['Int'];
  favouritesCount: Scalars['Int'];
  firstFunnel?: Maybe<FunnelOutputGraphql>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  isBlocked: Scalars['Boolean'];
  isPro: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  services: Array<ServiceOutputGraphql>;
  skills: Array<SkillOutputGraphql>;
  tacticsCount: Scalars['Int'];
  website?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  addUrlToQueue: Scalars['Boolean'];
  aiStrategyGeneratePDF: Scalars['String'];
  checkPromotionCode?: Maybe<PromotionDataOutputGraphql>;
  filterActivitiesBudgets: FilterOutputGraphql;
  filterActivitiesCarriers: FilterOutputGraphql;
  filterActivitiesFormats: FilterOutputGraphql;
  filterActivitiesMediums: FilterOutputGraphql;
  filterActivitiesPlacements: FilterOutputGraphql;
  filterActivitiesPlatforms: ActivityPlatformFilterOutputGraphql;
  filterActivitiesTypes: FilterOutputGraphql;
  filterAdvancements: FilterOutputGraphql;
  filterEffects: FilterOutputGraphql;
  filterProjects: FilterOutputGraphql;
  filterSegments: FilterOutputGraphql;
  filterStatementsParameters: StatementParameterFilterOutputGraphql;
  filterSteps: StepFilterOutputGraphql;
  filterTacticsCategories: FilterOutputGraphql;
  filterTacticsTypes: TacticTypeFilterOutputGraphql;
  findAllPrompts: Array<CustomPromptOutputGraphql>;
  findAllRetool: Array<RecommendationSystemOutput>;
  findAllStatementV3Templates: Array<StatementV3OutputGraphql>;
  findOnePrompt: CustomPromptOutputGraphql;
  findOneStatementV3Template: StatementV3OutputGraphql;
  findStatementV3Instance: StatementV3InstanceGraphql;
  findUserRecomendation: Array<RecommendationSystemOutput>;
  funnelGeneratePDF: Scalars['String'];
  generateAyrshareAccountUrl: Scalars['String'];
  generatePDF: Scalars['String'];
  getAbyssaleIntegrations: Array<IntegrationOutputGraphql>;
  getAccountSetupParameters: Array<AccountSetupParameterOutputGraphql>;
  getActivityAllowedParameters: ActivityAllowedParameterOutputGraphql;
  getActivityTypes: Array<ActivityTypeOutputGraphql>;
  getAppSumoSubscriptionsPlans: Array<SubscriptionPlanOutputGraphql>;
  getAsset: AssetLibraryOutputGraphql;
  getAssets: Array<AssetOutputGraphql>;
  getAssetsAdTypes: Array<AssetAdTypeOutputGraphql>;
  getAssetsPlacements: Array<AssetPlacementOutputGraphql>;
  getAssetsPlatforms: Array<AssetPlatformOutputGraphql>;
  getAssetsResolutions: Array<AssetResolutionOutputGraphql>;
  getAyrshareAccount: AyrshareAccountOutputGraphql;
  getBannerbearIntegrations: Array<IntegrationOutputGraphql>;
  getBenchmarksTypes: Array<BenchmarkTypeOutputGraphql>;
  getBriefsQuestions: Array<BriefQuestionOutputGraphql>;
  getCampaning: MarketingCampaningGraphql;
  getCanvasAnswerSuggestions: CanvasAnswerSuggestionsOutput;
  getCanvasCategories: Array<CanvasCategoryOutput>;
  getCanvasCategory: CanvasCategoryOutput;
  getCanvasInstance: CanvasInstanceOutput;
  getCanvasInstanceByFunnelAndTemplate: CanvasInstanceOutput;
  getCanvasSections: Array<CanvasSectionsOutput>;
  getCanvasTemplate: CanvasTemplateOutput;
  getCanvasTemplates: Array<CanvasTemplateOutput>;
  getCatalog?: Maybe<Array<DataWarehouseOutput>>;
  getCatalogByDef?: Maybe<DataWarehouseOutput>;
  getCategory?: Maybe<Array<DataWarehouseDefOutput>>;
  getCompanyData: CompanyDataOutputGraphql;
  getContentGenerationIntegrations: Array<IntegrationOutputGraphql>;
  /** Returns custom prompt output */
  getCustomPromptOutput: CustomPromptOutputOutputGraphql;
  getDashboards: Array<DashboardOutputGraphql>;
  getData?: Maybe<Array<DataWarehouseOutput>>;
  getDataByType: MarketingCampaningGraphql;
  getDiscordUrl: Scalars['String'];
  getFaqs: Array<FaqOutputGraphql>;
  getFunnel: FunnelOutputGraphql;
  getFunnelByPromotionCode: FunnelOutputGraphql;
  getFunnelStatistics: Array<FunnelStatisticsOutputGraphql>;
  getFunnelTactic: FunnelTacticOutputGraphql;
  getFunnelsSets: Array<FunnelSetOutputGraphql>;
  getInputData: InputsOutputGraphql;
  getInputsDataByOutput: Array<InputDataGraphql>;
  getIntegrationInputsData: Array<InputDataGraphql>;
  getIntegrationOutputsData: Array<OutputDataGraphql>;
  getIntegrations: Array<IntegrationOutputGraphql>;
  getIntegrationsCategories: Array<IntegrationCategoryOutputGraphql>;
  getKanban: Array<StepOutputGraphql>;
  getLastGeneratedIntegrationInputsData: Array<InputDataGraphql>;
  getLastOutputsData: Array<OutputDataGraphql>;
  getLibraryAssets: Array<AssetLibraryOutputGraphql>;
  getMarketingCampaignSubscriptionsPlans: Array<SubscriptionPlanOutputGraphql>;
  getMetrics: Array<MetricOutputGraphql>;
  getMultipleDataByType: MarketingCampaningGraphql;
  getOnboardingFunnel?: Maybe<FunnelOutputGraphql>;
  getOnboardingFunnelIntegrationInputsData: Array<InputDataGraphql>;
  getOutputsData: Array<OutputDataGraphql>;
  getPartnerUser: UserOutputGraphql;
  getPermissions: Array<PermissionOutputGraphql>;
  getPost: AyrsharePostOutputGraphql;
  getPromptForCanvas: Array<CustomPromptOutputGraphql>;
  getPublicFunnelById: FunnelOutputGraphql;
  /**
   * Available filters:
   * a.firstName
   *
   * a.lastName
   *
   * skills.id
   *
   * paidTacticsCount
   *
   * a.isPro
   *
   * Available orders:
   *
   * a.id
   * a.sortName
   * a.experienceYears
   * tacticsCount
   *
   *
   */
  getPublicUsers: PublicUserListOutputGraphql;
  getPubliclyVisibleTactic: TacticOutputGraphql;
  getPurchasedTacticByRoot?: Maybe<TacticOutputGraphql>;
  getRecommenderItems: Array<RecommenderOutputGraphql>;
  /** Get resource teams for selected resource. You can provide tacticId or funnelId */
  getResourceTeamsByResource: Array<ResourceTeamOutputGraphql>;
  getScheduledPosts: Array<AyrsharePostOutputGraphql>;
  getServiceOrder: ServiceOrderOutputGraphql;
  getServicesOrdersList: Array<ServiceOrderOutputGraphql>;
  getSkills: Array<SkillOutputGraphql>;
  getStatement?: Maybe<StatementV2OutputGraphql>;
  getStatementConfiguration: StatementConfigurationOutputGraphql;
  getSubscriptionsPlans: Array<SubscriptionPlanOutputGraphql>;
  getTactic: TacticOutputGraphql;
  /** @deprecated Canva integration has been turned off */
  getTacticCanvaCreations: Array<CanvaCreationOutputGraphql>;
  getTacticsMetrics: Array<TacticMetricOutputGraphql>;
  getTacticsRecommendations: Array<TacticRecommendationOutputGraphql>;
  /** Returns single team data by id */
  getTeam: TeamOutputGraphql;
  getUnreceivedNotifications: Array<NotificationOutputGraphql>;
  getUser: PublicUserOutputGraphql;
  getUserByEmail: PublicUserOutputGraphql;
  getUserTactics: TacticsListOutputGraphql;
  getZealyLeaderboard: Array<ZealyLeaderboardOutputGraphql>;
  getZealyUser: ZealyUserOutputGraphql;
  isMarketplaceStripeAccountVerified: Scalars['Boolean'];
  me: UserOutputGraphql;
  /** Returns user's teams list */
  myTeams: Array<TeamOutputGraphql>;
  rateTest: Scalars['Boolean'];
  /** Resolves prompt */
  resolveCustomPrompt: ResolvePromptOutputGraphql;
  searchChatMessages: MessagesListOutputGraphql;
  searchContentOutputsData: OutputsDataListOutputGraphql;
  /**
   * Available filters:
   * project.id - Project id
   *
   * Available orders:
   * a.name - Funnel nametacticsCount - Funnel tactics count
   */
  searchFunnels: FunnelsListOutputGraphql;
  /**
   * Available filters:
   * step.id - step id
   *
   * category.id - category id
   *
   * a.isPaid - true for only paid tactics, false for only free tactics
   *
   * a.owner.isPro - true for only pro users tactics
   *
   * Available orders:
   *
   * a.createdAt - Creation date
   * a.name - Tactic name
   * a.rate - Average rate
   * a.popularity - Popularity
   * a.price - Price
   * a.showOnTop - Recommended
   */
  searchTactics: TacticsListOutputGraphql;
  validateAppSumoToken: AppSumoValidationOutputGraphql;
  validateURL: Scalars['Boolean'];
};


export type QueryAiStrategyGeneratePdfArgs = {
  funnelId: Scalars['Int'];
  logo: Scalars['String'];
};


export type QueryCheckPromotionCodeArgs = {
  code: Scalars['String'];
  planId: Scalars['Int'];
};


export type QueryFilterActivitiesBudgetsArgs = {
  input: FilterInputGraphql;
};


export type QueryFilterActivitiesCarriersArgs = {
  input: FilterInputGraphql;
};


export type QueryFilterActivitiesFormatsArgs = {
  input: FilterInputGraphql;
};


export type QueryFilterActivitiesMediumsArgs = {
  input: FilterInputGraphql;
};


export type QueryFilterActivitiesPlacementsArgs = {
  input: FilterInputGraphql;
};


export type QueryFilterActivitiesPlatformsArgs = {
  input: FilterInputGraphql;
};


export type QueryFilterActivitiesTypesArgs = {
  input: FilterInputGraphql;
};


export type QueryFilterAdvancementsArgs = {
  input: FilterInputGraphql;
};


export type QueryFilterEffectsArgs = {
  input: FilterInputGraphql;
};


export type QueryFilterProjectsArgs = {
  input: FilterInputGraphql;
};


export type QueryFilterSegmentsArgs = {
  input: FilterInputGraphql;
};


export type QueryFilterStatementsParametersArgs = {
  category: StatementsParametersTypesEnum;
  input: FilterInputGraphql;
};


export type QueryFilterStepsArgs = {
  input: FilterInputGraphql;
};


export type QueryFilterTacticsCategoriesArgs = {
  input: FilterInputGraphql;
};


export type QueryFilterTacticsTypesArgs = {
  input: FilterInputGraphql;
};


export type QueryFindOnePromptArgs = {
  customEntityId: Scalars['Int'];
};


export type QueryFindOneStatementV3TemplateArgs = {
  statementV3TemplateId: Scalars['Int'];
};


export type QueryFindStatementV3InstanceArgs = {
  input: FindStatementInstanceInputGraphql;
};


export type QueryFunnelGeneratePdfArgs = {
  funnelId: Scalars['Int'];
  logo: Scalars['String'];
};


export type QueryGeneratePdfArgs = {
  instanceId: Scalars['Int'];
};


export type QueryGetActivityAllowedParametersArgs = {
  input: ListInput;
};


export type QueryGetAssetArgs = {
  input: GetAssetInputGraphql;
};


export type QueryGetAssetsAdTypesArgs = {
  input?: InputMaybe<AdTypesListInputGraphql>;
};


export type QueryGetAssetsPlacementsArgs = {
  input?: InputMaybe<AssetsPlacementsListInputGraphql>;
};


export type QueryGetAssetsResolutionsArgs = {
  input?: InputMaybe<AssetsResolutionsListInputGraphql>;
};


export type QueryGetCampaningArgs = {
  funnelId: Scalars['Int'];
};


export type QueryGetCanvasAnswerSuggestionsArgs = {
  canvasTemplateId: Scalars['Int'];
};


export type QueryGetCanvasCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryGetCanvasInstanceArgs = {
  id: Scalars['Int'];
};


export type QueryGetCanvasInstanceByFunnelAndTemplateArgs = {
  funnelId: Scalars['Int'];
  templateId: Scalars['Int'];
};


export type QueryGetCanvasTemplateArgs = {
  id: Scalars['Int'];
};


export type QueryGetCatalogByDefArgs = {
  defId: Scalars['Int'];
};


export type QueryGetCompanyDataArgs = {
  funnelId: Scalars['Int'];
};


export type QueryGetCustomPromptOutputArgs = {
  customPromptOutputId: Scalars['Int'];
};


export type QueryGetDashboardsArgs = {
  funnelId: Scalars['Int'];
};


export type QueryGetDataArgs = {
  categoryName: Scalars['String'];
  funnelId: Scalars['Int'];
};


export type QueryGetDataByTypeArgs = {
  funnelId: Scalars['Int'];
  type: Scalars['String'];
};


export type QueryGetFunnelArgs = {
  funnelId: Scalars['Int'];
};


export type QueryGetFunnelByPromotionCodeArgs = {
  promotionCode: Scalars['String'];
};


export type QueryGetFunnelStatisticsArgs = {
  funnelId: Scalars['Int'];
};


export type QueryGetFunnelTacticArgs = {
  id: Scalars['Int'];
};


export type QueryGetInputDataArgs = {
  funnelId: Scalars['Int'];
};


export type QueryGetInputsDataByOutputArgs = {
  outputId: Scalars['Int'];
};


export type QueryGetIntegrationInputsDataArgs = {
  input: InputsDataListInputGraphql;
};


export type QueryGetIntegrationOutputsDataArgs = {
  input: OutputsDataListInputGraphql;
};


export type QueryGetIntegrationsArgs = {
  integrationType?: IntegrationTypeEnum;
};


export type QueryGetLastGeneratedIntegrationInputsDataArgs = {
  integrationId: Scalars['Int'];
};


export type QueryGetLastOutputsDataArgs = {
  funnelId: Scalars['Int'];
  integrationId: Scalars['Int'];
  records?: Scalars['Int'];
};


export type QueryGetLibraryAssetsArgs = {
  input: GetLibraryAssetInputGraphql;
};


export type QueryGetMultipleDataByTypeArgs = {
  funnelId: Scalars['Int'];
  type: Scalars['String'];
};


export type QueryGetOnboardingFunnelIntegrationInputsDataArgs = {
  integrationId: Scalars['Int'];
};


export type QueryGetOutputsDataArgs = {
  outputsDataIds: Array<Scalars['Int']>;
};


export type QueryGetPartnerUserArgs = {
  id: Scalars['Int'];
};


export type QueryGetPostArgs = {
  id: Scalars['String'];
};


export type QueryGetPublicFunnelByIdArgs = {
  funnelId: Scalars['Int'];
};


export type QueryGetPublicUsersArgs = {
  query: ListInput;
};


export type QueryGetPubliclyVisibleTacticArgs = {
  id: Scalars['Int'];
};


export type QueryGetPurchasedTacticByRootArgs = {
  rootId: Scalars['Int'];
};


export type QueryGetResourceTeamsByResourceArgs = {
  input: ResourcesTeamsListInputGraphql;
};


export type QueryGetServiceOrderArgs = {
  id: Scalars['Int'];
};


export type QueryGetStatementArgs = {
  funnelId: Scalars['Int'];
};


export type QueryGetTacticArgs = {
  id: Scalars['Int'];
};


export type QueryGetTacticCanvaCreationsArgs = {
  tacticId: Scalars['Int'];
};


export type QueryGetTacticsMetricsArgs = {
  tacticId: Scalars['Int'];
};


export type QueryGetTacticsRecommendationsArgs = {
  funnelId?: InputMaybe<Scalars['Int']>;
  records: Scalars['Int'];
  uniqueReccommendations?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetTeamArgs = {
  id: Scalars['Int'];
};


export type QueryGetUserArgs = {
  id: Scalars['Int'];
};


export type QueryGetUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetUserTacticsArgs = {
  query: ListInput;
  userId: Scalars['Int'];
};


export type QueryGetZealyUserArgs = {
  discordName: Scalars['String'];
};


export type QueryRateTestArgs = {
  id: Scalars['Float'];
};


export type QueryResolveCustomPromptArgs = {
  resolvePromptInput: ResolvePromptInputGraphql;
};


export type QuerySearchChatMessagesArgs = {
  query: MessagesListInputGraphql;
};


export type QuerySearchContentOutputsDataArgs = {
  input: OutputsDataSearchInputGraphql;
};


export type QuerySearchFunnelsArgs = {
  query: FunnelsListInputGraphql;
};


export type QuerySearchTacticsArgs = {
  query: TacticListInputGraphql;
};


export type QueryValidateAppSumoTokenArgs = {
  token: Scalars['String'];
};


export type QueryValidateUrlArgs = {
  url: Scalars['String'];
};

export type RecomendationSystemInput = {
  points: Scalars['Int'];
  recomendationOwner: Scalars['Int'];
  recomendationUrl?: InputMaybe<Scalars['String']>;
  recomendationUser: Scalars['Int'];
  status?: InputMaybe<Scalars['String']>;
};

export type RecommendationOutputGraphql = {
  __typename?: 'RecommendationOutputGraphql';
  funnel: FunnelOutputGraphql;
  id: Scalars['Int'];
  points: Scalars['Int'];
  tactic: TacticOutputGraphql;
  usedInFunnel: Scalars['Boolean'];
};

export type RecommendationSystemOutput = {
  __typename?: 'RecommendationSystemOutput';
  points: Scalars['Int'];
  recomendationUrl: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type RecommenderOutputGraphql = {
  __typename?: 'RecommenderOutputGraphql';
  funnel?: Maybe<FunnelOutputGraphql>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type RegisterInputGraphql = {
  /** E-mail */
  email: Scalars['String'];
  /** First name */
  firstName: Scalars['String'];
  isMarketingCampaignUser?: Scalars['Boolean'];
  /** Last name */
  lastName?: InputMaybe<Scalars['String']>;
  /** Password */
  password: Scalars['String'];
  redirectedFromTacticsLpPage?: InputMaybe<Scalars['Boolean']>;
  /** Is rules accepted */
  rulesAccepted: Scalars['Boolean'];
};

/** Registration method */
export enum RegistrationMethodsEnum {
  AppSumo = 'appSumo',
  Email = 'email',
  /** When user used facebook sign in provider */
  Facebook = 'facebook',
  /** When user used google sign in provider */
  Google = 'google'
}

export type ReorderActivityInputGraphql = {
  activityId: Scalars['Int'];
  position: Scalars['Int'];
};

export type ReorderTacticInputGraphql = {
  funnelTacticId: Scalars['Int'];
  position: Scalars['Int'];
  stepId: Scalars['Int'];
};

export type ResolvePromptInputGraphql = {
  id: Scalars['Float'];
  variables: Array<ResolvePromptVariableInputGraphql>;
};

export type ResolvePromptOutputGraphql = {
  __typename?: 'ResolvePromptOutputGraphql';
  full_output: Scalars['String'];
  output: Scalars['String'];
};

export type ResolvePromptVariableInputGraphql = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ResolveStatementInputGraphql = {
  funnelId: Scalars['Float'];
  /** Chosen template id */
  id: Scalars['Float'];
  variables: Array<ResolveStatementVariableInputGraphql>;
};

export type ResolveStatementVariableInputGraphql = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ResourcePermissionInputGraphql = {
  permission?: FunnelPermissionEnum;
  teamMemberId: Scalars['Int'];
};

export type ResourcePermissionOutputGraphql = {
  __typename?: 'ResourcePermissionOutputGraphql';
  id: Scalars['Int'];
  permission: FunnelPermissionEnum;
  teamMember: TeamMemberOutputGraphql;
};

export type ResourceTeamOutputGraphql = {
  __typename?: 'ResourceTeamOutputGraphql';
  id: Scalars['Int'];
  permissions: Array<ResourcePermissionOutputGraphql>;
  team: TeamOutputGraphql;
};

export type ResourcesTeamsListInputGraphql = {
  funnelId?: InputMaybe<Scalars['Int']>;
  tacticId?: InputMaybe<Scalars['Int']>;
};

export type SaveAccountSetupInputGraphql = {
  category: AccountSetupParameterCategoriesEnum;
  parameter: AccountSetupParameterInputGraphql;
};

export type SaveLibraryAssetInputGraphql = {
  content: Scalars['String'];
  context: Array<ContextAssetInputGraphql>;
  funnelId: Scalars['Float'];
  integration: Scalars['String'];
};

export type SendHelpMessageInputGraphql = {
  content: Scalars['String'];
};

export type SendMessageInputGraphql = {
  content: Scalars['String'];
  subject: Scalars['String'];
  userId: Scalars['Int'];
};

export type ServiceOrderInputGraphql = {
  brief: BriefInputGraphql;
  serviceId: Scalars['Int'];
};

export type ServiceOrderOutputGraphql = {
  __typename?: 'ServiceOrderOutputGraphql';
  brief: BriefOutputGraphql;
  chat: ChatOutputGraphql;
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  customer: PublicUserOutputGraphql;
  growthHacker: PublicUserOutputGraphql;
  id: Scalars['Int'];
  lastBudgetCalculation?: Maybe<BudgetCalculationOutputGraphql>;
  price?: Maybe<Scalars['Int']>;
  service: ServiceOutputGraphql;
  status: ServiceOrderStatusEnum;
};

export type ServiceOrderPaymentOutputGraphql = {
  __typename?: 'ServiceOrderPaymentOutputGraphql';
  url: Scalars['String'];
};

export type ServiceOrderPriceInputGraphql = {
  currency?: InputMaybe<Scalars['String']>;
  price: Scalars['Int'];
};

export enum ServiceOrderStatusEnum {
  Failed = 'failed',
  Paid = 'paid',
  Waiting = 'waiting'
}

export type ServiceOutputGraphql = {
  __typename?: 'ServiceOutputGraphql';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Int'];
};

export type SetCompanyDataInputGraphql = {
  businessName?: InputMaybe<Scalars['String']>;
  funnelId: Scalars['Float'];
  url?: InputMaybe<Scalars['String']>;
};

export type SkillOutputGraphql = {
  __typename?: 'SkillOutputGraphql';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type SocialLoginInputGraphql = {
  method: RegistrationMethodsEnum;
  redirectedFromTacticsLpPage?: InputMaybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type StatementAnswerOutputGraphql = {
  __typename?: 'StatementAnswerOutputGraphql';
  field: StatementFieldOutputGraphql;
  value: Scalars['JSONObject'];
};

export type StatementConfigurationOutputGraphql = {
  __typename?: 'StatementConfigurationOutputGraphql';
  fields: Array<StatementFieldOutputGraphql>;
  id: Scalars['Float'];
};

export type StatementFieldOptionGraphql = {
  __typename?: 'StatementFieldOptionGraphql';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type StatementFieldOutputGraphql = {
  __typename?: 'StatementFieldOutputGraphql';
  category: FieldCategoryEnum;
  dependsOnField?: Maybe<StatementFieldOutputGraphql>;
  dependsOnOption?: Maybe<StatementFieldOptionGraphql>;
  id: Scalars['Int'];
  maxValue?: Maybe<StatementFieldValueGraphql>;
  minValue?: Maybe<StatementFieldValueGraphql>;
  options: Array<StatementFieldOptionGraphql>;
  required: Scalars['Boolean'];
  type: FieldTypeEnum;
};

export type StatementFieldValueGraphql = {
  __typename?: 'StatementFieldValueGraphql';
  id: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['Float'];
};

export type StatementFirstInputGraphql = {
  businessTypeId?: InputMaybe<Scalars['Int']>;
  customersPainsIds?: InputMaybe<Array<Scalars['Int']>>;
  funnelId?: InputMaybe<Scalars['Int']>;
  problemsIds?: InputMaybe<Array<Scalars['Int']>>;
  productName?: InputMaybe<Scalars['String']>;
  segmentsB2bIds?: InputMaybe<Array<Scalars['Int']>>;
  segmentsB2cIds?: InputMaybe<Array<Scalars['Int']>>;
  targetGroupsTypesIds?: InputMaybe<Array<Scalars['Int']>>;
  valuePropositionsIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type StatementInputGraphql = {
  avgProductMargin: Scalars['Int'];
  b2bProductType?: InputMaybe<Array<Scalars['Int']>>;
  b2cProductType?: InputMaybe<Array<Scalars['Int']>>;
  configurationId: Scalars['Int'];
  engagement: Scalars['Int'];
  funnelId?: InputMaybe<Scalars['Int']>;
  goalMeasureFirstCustomer?: InputMaybe<Scalars['Int']>;
  goalMeasureLeads?: InputMaybe<Scalars['Int']>;
  goalMeasureSales?: InputMaybe<Scalars['Int']>;
  goalPeriod: Array<Scalars['Int']>;
  goalType: Array<Scalars['Int']>;
  marketingAdvancement: Array<Scalars['Int']>;
  objectiveType: Array<Scalars['Int']>;
  offerPolicy: Array<Scalars['Int']>;
  paymentFrequency: Array<Scalars['Int']>;
  paymentMethod: Array<Scalars['Int']>;
  pricingCharacteristics: Array<Scalars['Int']>;
  productName: Scalars['String'];
  productType: Array<Scalars['Int']>;
  sellingMethod: Array<Scalars['Int']>;
  targetChannels: Array<Scalars['Int']>;
  targetCharacteristics: Array<Scalars['Int']>;
  targetJob?: InputMaybe<Array<Scalars['Int']>>;
  targetLocation: Array<Scalars['Int']>;
  targetPerson?: InputMaybe<Array<Scalars['Int']>>;
  targetSegment: Array<Scalars['Int']>;
};

export type StatementOutputGraphql = {
  __typename?: 'StatementOutputGraphql';
  businessType?: Maybe<FilterItemOutputGraphql>;
  channels?: Maybe<Array<StatementParameterOutputGraphql>>;
  customersPains?: Maybe<Array<FilterItemOutputGraphql>>;
  funnel?: Maybe<FunnelOutputGraphql>;
  id: Scalars['Int'];
  problems?: Maybe<Array<FilterItemOutputGraphql>>;
  productName?: Maybe<Scalars['String']>;
  rentalCharacteristics?: Maybe<Array<FilterItemOutputGraphql>>;
  segmentsB2b?: Maybe<Array<FilterItemOutputGraphql>>;
  segmentsB2c?: Maybe<Array<FilterItemOutputGraphql>>;
  targetsGroupsTypes?: Maybe<Array<StatementParameterOutputGraphql>>;
  valuePropositions?: Maybe<Array<FilterItemOutputGraphql>>;
};

export type StatementParameterFilterOutputGraphql = {
  __typename?: 'StatementParameterFilterOutputGraphql';
  /** Data */
  records: Array<FilterTypeOutputStatementParameterOutputGraphql>;
  /** Total records count */
  total: Scalars['Float'];
};

export type StatementParameterOutputGraphql = {
  __typename?: 'StatementParameterOutputGraphql';
  category: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  isB2b: Scalars['Boolean'];
  isB2c: Scalars['Boolean'];
  name: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
};

export type StatementV2OutputGraphql = {
  __typename?: 'StatementV2OutputGraphql';
  answers: Array<StatementAnswerOutputGraphql>;
  id: Scalars['Int'];
};

export type StatementV3FieldInputGraphql = {
  fieldType: Scalars['String'];
  id?: InputMaybe<Scalars['Float']>;
  label: Scalars['String'];
  name: Scalars['String'];
  optionValues?: InputMaybe<Array<Scalars['String']>>;
  order?: InputMaybe<Scalars['Float']>;
  required?: Scalars['Boolean'];
};

export type StatementV3FieldOutputGraphql = {
  __typename?: 'StatementV3FieldOutputGraphql';
  fieldType: Scalars['String'];
  id: Scalars['Float'];
  label: Scalars['String'];
  name: Scalars['String'];
  optionValues?: Maybe<Array<Scalars['String']>>;
  order?: Maybe<Scalars['Float']>;
  required: Scalars['Boolean'];
};

export type StatementV3InputGraphql = {
  category: Scalars['String'];
  id?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  settingsActive?: Scalars['Boolean'];
  statementV3Field: Array<StatementV3FieldInputGraphql>;
  text: Scalars['String'];
};

export type StatementV3InstanceGraphql = {
  __typename?: 'StatementV3InstanceGraphql';
  id: Scalars['Float'];
  params: Array<StatementV3InstanceParamsGraphql>;
  statementV3Template: StatementV3OutputGraphql;
  value: Scalars['String'];
};

export type StatementV3InstanceParamsGraphql = {
  __typename?: 'StatementV3InstanceParamsGraphql';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type StatementV3OutputGraphql = {
  __typename?: 'StatementV3OutputGraphql';
  category: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  settingsActive: Scalars['Boolean'];
  statementV3Field: Array<StatementV3FieldOutputGraphql>;
  text: Scalars['String'];
};

export enum StatementsParametersTypesEnum {
  Budget = 'budget',
  BusinessType = 'businessType',
  ChannelsAlreadyOwned = 'channelsAlreadyOwned',
  Community = 'community',
  CustomerSPain = 'customerSPain',
  Goal = 'goal',
  Model = 'model',
  PaymentFrequency = 'paymentFrequency',
  Problem = 'problem',
  PurchaseMethod = 'purchaseMethod',
  RevenueCharacteristics = 'revenueCharacteristics',
  SegmentB2b = 'segmentB2b',
  SegmentB2c = 'segmentB2c',
  ValueProposition = 'valueProposition'
}

export type StepFilterOutputGraphql = {
  __typename?: 'StepFilterOutputGraphql';
  /** Data */
  records: Array<FilterTypeOutputStepOutputGraphql>;
  /** Total records count */
  total: Scalars['Float'];
};

export type StepOutputGraphql = {
  __typename?: 'StepOutputGraphql';
  id: Scalars['Float'];
  name: Scalars['String'];
  tactics: Array<TacticOutputGraphql>;
  type: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  testSubscription: Scalars['String'];
};

export type SubscriptionOutputGraphql = {
  __typename?: 'SubscriptionOutputGraphql';
  id: Scalars['Int'];
  plan: SubscriptionPlanOutputGraphql;
  price: PriceOutputGraphql;
};

export type SubscriptionPlanOutputGraphql = {
  __typename?: 'SubscriptionPlanOutputGraphql';
  credits: Scalars['Int'];
  creditsInitial: Scalars['Int'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isPresale: Scalars['Boolean'];
  name: Scalars['String'];
  paymentInterval?: Maybe<PaymentIntervalEnum>;
  paymentIntervalCount?: Maybe<Scalars['Int']>;
  permissions: Array<PlanPermissionOutputGraphql>;
  price: Scalars['Int'];
  prices: Array<PriceOutputGraphql>;
  salePlanCounter: Scalars['Int'];
  strength: Scalars['Int'];
  trialDays?: Maybe<Scalars['Int']>;
};

export type SubscriptionsManagementOutputGraphql = {
  __typename?: 'SubscriptionsManagementOutputGraphql';
  generated: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export enum TacticBadgeEnum {
  NoData = 'noData'
}

export type TacticInputGraphql = {
  badge?: InputMaybe<TacticBadgeEnum>;
  /** Tactics categories ids */
  categoriesIds: Array<Scalars['Int']>;
  /** Effects ids */
  effectsIds: Array<Scalars['Int']>;
  /** Funnel ids */
  funnelStepsIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Advancement id */
  levelOfAdvancementId?: InputMaybe<Scalars['Int']>;
  /** Tactic name */
  name?: InputMaybe<Scalars['String']>;
  publiclyVisible?: InputMaybe<Scalars['Boolean']>;
  /** Is budget required? */
  requiresBudget?: InputMaybe<Scalars['Boolean']>;
  /** Segments ids */
  segmentsIds: Array<Scalars['Int']>;
  /** Details */
  tacticDetails?: InputMaybe<Scalars['String']>;
  /** Target name */
  target?: InputMaybe<Scalars['String']>;
  /** Type id */
  typeId?: InputMaybe<Scalars['Int']>;
};

export type TacticListInputGraphql = {
  filters?: InputMaybe<Array<ListFiltersFieldInput>>;
  page?: InputMaybe<Scalars['Int']>;
  records?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<OrderTypes>;
  sortField?: InputMaybe<Scalars['String']>;
  type: TacticListTypeEnum;
};

export enum TacticListTypeEnum {
  /** Show all available tactics */
  All = 'all',
  /** Show tactics marked as banners */
  Banner = 'banner',
  /** Show tactics created by Digital First */
  DigitalFirst = 'digital_first',
  /** Show users favourite tactics */
  Favourite = 'favourite',
  /** Show tactics created by currently logged user */
  My = 'my',
  /** Show tactics added to user funnels */
  MyFunnel = 'my_funnel',
  /** Show onboarding tactics list */
  Onboarding = 'onboarding',
  /** Show tactics recommended for user */
  Recommended = 'recommended'
}

export type TacticMetricInputGraphql = {
  cycleMeasurementPeriod?: InputMaybe<TacticMetricPeriodEnum>;
  isCyclical: Scalars['Boolean'];
  measurementPeriod: TacticMetricPeriodEnum;
  metricId: Scalars['Int'];
  tacticId: Scalars['Int'];
};

export type TacticMetricOutputGraphql = {
  __typename?: 'TacticMetricOutputGraphql';
  cycleMeasurementPeriod?: Maybe<TacticMetricPeriodEnum>;
  id: Scalars['Int'];
  isCyclical: Scalars['Boolean'];
  measurementPeriod: TacticMetricPeriodEnum;
  metric: MetricOutputGraphql;
};

export enum TacticMetricPeriodEnum {
  Month = 'month',
  Quarter = 'quarter',
  TwoWeeks = 'twoWeeks',
  Week = 'week'
}

export type TacticOutputGraphql = {
  __typename?: 'TacticOutputGraphql';
  activities: Array<ActivityOutputGraphql>;
  activitiesCount: Scalars['Int'];
  assetsCount: Scalars['Int'];
  badge?: Maybe<TacticBadgeEnum>;
  benchmarksCount: Scalars['Int'];
  canBePayed: Scalars['Boolean'];
  categories: Array<DictionaryOutputGraphql>;
  copyForSale?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  currentUserPermission?: Maybe<FunnelPermissionEnum>;
  effects: Array<DictionaryOutputGraphql>;
  funnelSteps: Array<StepOutputGraphql>;
  id: Scalars['Int'];
  image?: Maybe<AttachmentOutputGraphql>;
  imageUrl?: Maybe<Scalars['String']>;
  inMyFunnel: Scalars['Boolean'];
  isBanner: Scalars['Boolean'];
  isFavourite: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  isTopRated: Scalars['Boolean'];
  lastEditor?: Maybe<PublicUserOutputGraphql>;
  levelOfAdvancement?: Maybe<DictionaryOutputGraphql>;
  metrics: Array<TacticMetricOutputGraphql>;
  modifiedAt: Scalars['DateTime'];
  myRate?: Maybe<TacticRateOutputGraphql>;
  name: Scalars['String'];
  owner?: Maybe<UserOutputGraphql>;
  popularity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  publiclyVisible?: Maybe<Scalars['Boolean']>;
  purchased: Scalars['Boolean'];
  rate?: Maybe<Scalars['Float']>;
  requiresBudget?: Maybe<Scalars['Boolean']>;
  root?: Maybe<TacticOutputGraphql>;
  segments: Array<DictionaryOutputGraphql>;
  shares: Array<TacticShareOutputGraphql>;
  sharetribeListingUuid?: Maybe<Scalars['String']>;
  tacticDetails: Scalars['String'];
  target?: Maybe<Scalars['String']>;
  type?: Maybe<TacticTypeOutputGraphql>;
  verificationStatus?: Maybe<VerificationStatusEnum>;
};

export type TacticRateInputGraphql = {
  rate: Scalars['Int'];
  tacticId: Scalars['Int'];
};

export type TacticRateOutputGraphql = {
  __typename?: 'TacticRateOutputGraphql';
  creator: UserOutputGraphql;
  id: Scalars['Float'];
  rate: Scalars['Float'];
  tactic: TacticOutputGraphql;
};

export type TacticRecommendationOutputGraphql = {
  __typename?: 'TacticRecommendationOutputGraphql';
  recommendations: Array<RecommendationOutputGraphql>;
  step: StepOutputGraphql;
};

export type TacticShareInputGraphql = {
  emails: Array<Scalars['String']>;
  tacticId: Scalars['Int'];
};

export type TacticShareOutputGraphql = {
  __typename?: 'TacticShareOutputGraphql';
  email: Scalars['String'];
  id: Scalars['Float'];
};

export type TacticTypeFilterOutputGraphql = {
  __typename?: 'TacticTypeFilterOutputGraphql';
  /** Data */
  records: Array<FilterTypeOutputTacticTypeOutputGraphql>;
  /** Total records count */
  total: Scalars['Float'];
};

export type TacticTypeOutputGraphql = {
  __typename?: 'TacticTypeOutputGraphql';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TacticsListOutputGraphql = {
  __typename?: 'TacticsListOutputGraphql';
  count: Scalars['Int'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  records?: Maybe<Array<TacticOutputGraphql>>;
};

export type TagOutputGraphql = {
  __typename?: 'TagOutputGraphql';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type TeamMemberInputGraphql = {
  email: Scalars['String'];
  role?: TeamRoleEnum;
};

export type TeamMemberOutputGraphql = {
  __typename?: 'TeamMemberOutputGraphql';
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  role: TeamRoleEnum;
  user?: Maybe<MemberUserOutputGraphql>;
};

export type TeamOutputGraphql = {
  __typename?: 'TeamOutputGraphql';
  createdAt: Scalars['DateTime'];
  icon?: Maybe<AttachmentOutputGraphql>;
  id: Scalars['Int'];
  members: Array<TeamMemberOutputGraphql>;
  name: Scalars['String'];
};

export enum TeamRoleEnum {
  Creator = 'creator',
  Member = 'member'
}

export type UpdateCustomPromptInputGraphql = {
  category?: InputMaybe<Scalars['String']>;
  contentGeneratorCategory?: InputMaybe<Scalars['String']>;
  contentGeneratorIcon?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  forms: Array<CustomPromptFieldInputGraphql>;
  id: Scalars['Float'];
  name: Scalars['String'];
  promptProperty: Scalars['String'];
  promptText: Scalars['String'];
  settingsActive?: Scalars['Boolean'];
  settingsIntegration?: InputMaybe<Scalars['String']>;
};

export type UrlsArray = {
  __typename?: 'UrlsArray';
  isParsed?: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
};

export type UserOutputGraphql = {
  __typename?: 'UserOutputGraphql';
  activePlans: Array<SubscriptionPlanOutputGraphql>;
  activeSubscription: SubscriptionOutputGraphql;
  affiliationId?: Maybe<Scalars['String']>;
  avatar?: Maybe<AttachmentOutputGraphql>;
  contextFunnel?: Maybe<FunnelOutputGraphql>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  credits: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  discordId?: Maybe<Scalars['String']>;
  discordName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  experienceYears?: Maybe<Scalars['Int']>;
  favouritesCount: Scalars['Int'];
  filledStatement: Scalars['Boolean'];
  firstFunnel?: Maybe<FunnelOutputGraphql>;
  firstName?: Maybe<Scalars['String']>;
  firstTactic?: Maybe<TacticOutputGraphql>;
  funnels: Array<FunnelOutputGraphql>;
  funnelsCount: Scalars['Int'];
  hasEndedOnboarding: Scalars['Boolean'];
  id: Scalars['Float'];
  isAdmin: Scalars['Boolean'];
  isBlocked: Scalars['Boolean'];
  isMarketingCampaignUser: Scalars['Boolean'];
  isPro: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSONObject']>;
  newEmail?: Maybe<Scalars['String']>;
  paidTacticCount: Scalars['Int'];
  permissions: Array<PlanPermissionOutputGraphql>;
  position?: Maybe<Scalars['String']>;
  recentlyEndedTrial?: Maybe<Scalars['Boolean']>;
  registeredThroughAppSumo: Scalars['Boolean'];
  registrationMethod: RegistrationMethodsEnum;
  services: Array<ServiceOutputGraphql>;
  setupFormFilled: Scalars['Boolean'];
  skills: Array<SkillOutputGraphql>;
  statement?: Maybe<StatementOutputGraphql>;
  successPlanPaid?: Maybe<Scalars['Boolean']>;
  tacticDraft?: Maybe<TacticOutputGraphql>;
  tacticsCount: Scalars['Int'];
  trialDaysLeft?: Maybe<Scalars['Int']>;
  tutorialEnded: Scalars['Boolean'];
  usedTrial: Scalars['Boolean'];
  website?: Maybe<Scalars['String']>;
};

export type UserProfileInputGraphql = {
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discordName?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  experienceYears?: InputMaybe<Scalars['Float']>;
  firstName?: InputMaybe<Scalars['String']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type ValidationPatternOutputGraphql = {
  __typename?: 'ValidationPatternOutputGraphql';
  id: Scalars['Int'];
  rules: Scalars['JSONObject'];
};

export enum VerificationStatusEnum {
  Failed = 'failed',
  /** inProgress */
  InProgress = 'inProgress',
  Success = 'success'
}

export enum WorksForEnum {
  Clients = 'clients',
  Companies = 'companies'
}

export type ZealyLeaderboardOutputGraphql = {
  __typename?: 'ZealyLeaderboardOutputGraphql';
  avatar?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  discordId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  xp: Scalars['Float'];
};

export type ZealyUserOutputGraphql = {
  __typename?: 'ZealyUserOutputGraphql';
  avatar?: Maybe<Scalars['String']>;
  discordHandle?: Maybe<Scalars['String']>;
  level: Scalars['Float'];
  name: Scalars['String'];
  rank: Scalars['Float'];
  xp: Scalars['Float'];
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
