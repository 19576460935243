<div class="flex flex-row items-center justify-start mb-2 h-8">
  <span
    *ngFor="let step of tacticPreviewService.tactic?.funnelSteps"
    class="flex flex-row items-center tui-text-body-m-2 mr-0 md:mr-6"
  >
    <svg-icon
      [src]="'/assets/img/ic-' + step.type + '-fill.svg'"
      class="mr-2"
    ></svg-icon>
    <span class="tui-text_body-m-8 tui-text-03 hidden md:inline-flex">{{ step.name }}</span>
  </span>

  <a
    *ngIf="showCloseButton()"
    (click)="history.back()"
    class="ml-auto"
  >
    <svg-icon src="/assets/img/ic-close.svg"></svg-icon>
  </a>
</div>
<tui-island class="island">
  <div class="flex flex-col items-stretch w-full">
    <div class="flex justify-between items-center relative w-full">
      <df-created-by
        class="w-3/4"
        [user]="
          tacticPreviewService.tactic?.root
            ? (tacticPreviewService.tactic?.root)!.owner
            : tacticPreviewService.tactic?.owner
        "
      ></df-created-by>
    </div>
    <df-tactic-verification-banner
      class="mt-5"
      [vertical]="true"
      *ngIf="
        !!tacticPreviewService.tactic?.verificationStatus &&
        tacticPreviewService.tactic?.verificationStatus !== VerificationStatusEnum.Success &&
        !tacticPreviewService.tactic?.isPrivate
      "
      [tactic]="tacticPreviewService.tactic"
    ></df-tactic-verification-banner>

    <h1 class="tui-text_h3 md:tui-text_h2 tui-text-01 mb-4 mt-3 line-clamp-5 break-words font-semibold">
      {{ tacticPreviewService.tactic?.name }}
    </h1>

    <div
      class="w-full {{
        !!tacticPreviewService.tactic?.image?.file || !!tacticPreviewService.tactic?.imageUrl
          ? 'max-h-screen'
          : 'max-h-56'
      }} rounded-3xl overflow-hidden mb-4"
    >
      <img
        loading="lazy"
        class="w-full h-full object-cover object-center"
        [src]="
          (tacticPreviewService.tactic?.image?.file | assetsFile) ??
          tacticPreviewService.tactic?.imageUrl ??
          '/assets/img/placeholder.jpg'
        "
        onerror="this.onerror=null;this.src='/assets/img/placeholder.jpg'"
        alt=""
      />
    </div>

    <div class="flex flex-row items-center flex-wrap mb-4">
      <span
        *ngFor="let category of tacticPreviewService?.tactic?.categories"
        class="tui-primary tui-text_body-m-10 mr-2"
      >
        <!--          TODO: redirect to filtered list -->
        #{{ category.name }}
      </span>
    </div>

    <div
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 md:auto-cols-max lg:grid-cols-1 xl:grid-cols-2 gap-y-2 sm:gap-4 md:gap-1 lg:gap-y-2 xl:gap-4 mb-4"
    >
      <df-tactic-prameter-box
        [label]="'Tactics.budget' | translate"
        [value]="tacticPreviewService.tactic?.requiresBudget ? ('yes' | translate) : ('no' | translate)"
        [icon]="'/assets/img/ic-budget.svg'"
        [valueColorClass]="'tui-accent-09'"
      ></df-tactic-prameter-box>
      <df-tactic-prameter-box
        [label]="'Tactics.difficulty' | translate"
        [value]="tacticPreviewService.tactic?.levelOfAdvancement?.name!"
        [icon]="'/assets/img/ic-difficulty.svg'"
        [valueColorClass]="'tui-accent-10'"
      ></df-tactic-prameter-box>
      <df-tactic-prameter-box
        [label]="'Tactics.segment' | translate"
        [value]="getSegment()"
        [icon]="'/assets/img/ic-segment.svg'"
        [valueColorClass]="'tui-accent-11'"
      ></df-tactic-prameter-box>
      <df-tactic-prameter-box
        [label]="'Tactics.effect' | translate"
        [value]="getEffect()"
        [icon]="'/assets/img/ic-effect.svg'"
        [valueColorClass]="'tui-accent-12'"
      ></df-tactic-prameter-box>
    </div>
    <ng-container *ngIf="paidCurtain; else tacticDetails">
      <h3 class="mb-2 tui-text-01 font-bold tui-text_body-l-2">
        {{ 'Tactics.Preview.about' | translate }}
      </h3>
      <tui-island
        size="s"
        class="mt-3 py-5"
        *ngIf="tacticPreviewService.tactic as tactic"
      >
        <div class="h-full flex flex-col justify-center items-center">
          <div class="tui-accent-13-bg h-12 w-12 flex flex-col justify-center items-center rounded-full tui-primary">
            <svg-icon
              src="assets/img/ic_lock_open.svg"
              [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
            ></svg-icon>
          </div>
          <span class="tui-text_body-m-1 font-normal mt-3 tui-text-02">{{
            'Tactics.Preview.Buy tactic to unlock all its details' | translate
          }}</span>
          <button
            tuiButton
            size="l"
            appearance="primary"
            class="mt-5 lg:hidden"
            (click)="buyTactic.emit(true)"
          >
            <span class="font-semibold"
              >{{ 'Tactics.Preview.Buy tactic' | translate }}&nbsp;{{
                tactic.price! | price | currency: tactic.currency?.toUpperCase() : 'symbol' : '0.2-2'
              }}</span
            >
          </button>
        </div>
      </tui-island>
    </ng-container>
    <ng-template #tacticDetails>
      <ng-container *ngIf="tacticPreviewService?.tactic?.tacticDetails as details">
        <h3 class="mb-2 tui-text-01 font-bold tui-text_body-l-2">
          {{ 'Tactics.Preview.about' | translate }}
        </h3>
        <div
          class="preview-ql-editor tui-text_body-m-9 w-full tui-text-02 font-normal about-description"
          [innerHTML]="details | sanitizeHtml"
        ></div>
      </ng-container>
    </ng-template>
  </div>
</tui-island>
