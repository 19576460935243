export interface Action {
  icon: string;
  title: string;
  description: string;
  activeColor: string;
  borderColor: string;
  isActive: boolean;
  type: ActionTypeEnum;
  component: any;
}

export enum ActionTypeEnum {
  SCRAPE_WEBSITE = 'scrape_website',
  WRITE_DESCRIPTION = 'write_description',
  UPLOAD_FILES = 'upload_files',
  RECORDINGS = 'recordings',
  YOUTUBE = 'youtube',
  GOOGLE_DRIVE = 'google_drive',
  DROPBOX = 'dropbox',
}
