import { Component } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'df-master-visuals',
  templateUrl: './master-visuals.component.html',
  styleUrl: './master-visuals.component.scss',
})
export class MasterVisualsComponent {
  funnelId: number;

  constructor(
    private readonly n: NavigateService,
    private route: ActivatedRoute,
  ) {
    this.funnelId = this.route?.parent?.snapshot.params['id'];
  }
  onNextButtonClick() {
    this.n.go(`/funnels/f/d/${this.funnelId}/campaign`, { id: this.funnelId });
  }
}
