<div class="py-4 px-8 border-b-[1px] border-[#E0E0E0] h-[72px] flex justify-between border-solid">
  <button
    tuiButton
    size="m"
    type="button"
    appearance="outline"
    (click)="onBackClick()"
  >
    <span class="tui-primary font-semibold">Go back</span>
  </button>

  <nav class="flex">
    <div
      *ngFor="let item of flowLinks; let i = index"
      class="mr-8 last-of-type:mr-0 flex items-center"
    >
      <a
        [routerLink]="item.link"
        class="link"
        routerLinkActive="link--active"
      >
        <span class="link-circle">{{ i + 1 }}</span>
        <span class="text">{{ item.label }}</span>
      </a>
    </div>
  </nav>

  <button
    [class.invisible]="isLastStep"
    size="m"
    tuiButton
    type="button"
    (click)="onNextButtonClick.emit()"
  >
    <span class="font-semibold">Next</span>
  </button>
</div>
