<div
  class="h-full w-full flex flex-col justify-start"
  [ngClass]="{ 'overflow-x-hidden': router.url.includes('/graphics') }"
>
  <df-funnel-panel-subheader
    class="xl:hidden"
    *ngIf="detailsMode"
  ></df-funnel-panel-subheader>
  <div
    class="flex-grow overflow-hidden"
    [class.overflow-hidden]="isBusinessURL()"
  >
    <router-outlet></router-outlet>
  </div>
</div>
<df-memory-log [funnelId]="funnelId"></df-memory-log>
