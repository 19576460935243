import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Action, ActionTypeEnum } from '@modules/business-data/business-data.model';
import { ScrapeWebsiteFormComponent } from '../scrape-website-form/scrape-website-form.component';
import { FormGroup } from '@angular/forms';
import { WriteDescriptionFormComponent } from '../write-description-form/write-description-form.component';
import { UploadFilesComponent } from '../upload-files/upload-files.component';
import { RecordingsComponent } from '../recordings/recordings.component';
import { YoutubeContentComponent } from '../youtube-content/youtube-content.component';
import { DropboxComponent } from '../dropbox/dropbox.component';

@Component({
  selector: 'df-business-data-list',
  templateUrl: './business-data-list.component.html',
  styleUrl: './business-data-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessDataListComponent {
  @Output() actionChange = new EventEmitter<ActionTypeEnum>();
  @Input() form!: FormGroup;

  actionList: Action[] = [
    {
      icon: 'assets/img/scrape_a_website.svg',
      title: 'Enter your website address',
      description: 'Let us learn your business from website',
      activeColor: '#FBF8FF',
      borderColor: '#975BFF',
      isActive: true,
      type: ActionTypeEnum.SCRAPE_WEBSITE,
      component: ScrapeWebsiteFormComponent,
    },
    {
      icon: 'assets/img/text_content.svg',
      title: 'Write a description',
      description: 'Tell us about your business',
      activeColor: '#F0FBF9',
      borderColor: '#86E8D7',
      isActive: false,
      type: ActionTypeEnum.WRITE_DESCRIPTION,
      component: WriteDescriptionFormComponent,
    },
    {
      icon: 'assets/img/text_file.svg',
      title: 'Upload files',
      description: 'Upload TXT, DOCX, PPTX, CSV OR PDF.',
      activeColor: '#F3F8FF',
      borderColor: '#1677FF',
      isActive: false,
      type: ActionTypeEnum.UPLOAD_FILES,
      component: UploadFilesComponent,
    },
    {
      icon: 'assets/img/recordings.svg',
      title: 'Add recordings',
      description: 'Upload MPEG, MP4, MOV, AVI',
      activeColor: '#FFF8F8',
      borderColor: '#FCBDC0',
      isActive: false,
      type: ActionTypeEnum.RECORDINGS,
      component: RecordingsComponent,
    },
    {
      icon: 'assets/img/yt-card-icon.svg',
      title: 'Upload content from YouTube',
      description: 'Include a link to a YouTube video or channel',
      activeColor: '#F6F6F6',
      borderColor: '#B0B0B0',
      isActive: false,
      type: ActionTypeEnum.YOUTUBE,
      component: YoutubeContentComponent,
    },
    // {
    //   icon: 'assets/img/ico-google-drive.svg',
    //   title: 'Upload content from Google Drive',
    //   description: 'Add any type of file from your Google Drive account',
    //   activeColor: '#F6F6F6',
    //   borderColor: '#B0B0B0',
    //   isActive: false,
    //   type: ActionTypeEnum.GOOGLE_DRIVE,
    //   component: ScrapeWebsiteFormComponent,
    // },
    {
      icon: 'assets/img/ico_dropbox.svg',
      title: 'Upload content from Dropbox',
      description: 'Add any type of file from your Dropbox account',
      activeColor: '#F6F6F6',
      borderColor: '#B0B0B0',
      isActive: false,
      type: ActionTypeEnum.DROPBOX,
      component: DropboxComponent,
    },
  ];

  constructor(private changes: ChangeDetectorRef) {}

  changeActiveAction(action: Action) {
    this.actionList.forEach((action) => (action.isActive = false));
    action.isActive = true;
    this.actionChange.emit(action.type);
    this.changes.detectChanges();
  }

  changeActionByType(type: ActionTypeEnum) {
    this.actionList.forEach((action) => (action.isActive = false));
    const action = this.actionList.find((action) => action.type === type);
    if (action) action.isActive = true;
    this.changes.detectChanges();
  }
}
