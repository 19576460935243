<section class="w-full h-screen flex flex-col">
  <df-business-flow-header></df-business-flow-header>

  <div class="w-full h-full overflow-y-auto">
    <div class="pt-[72px]">
      <h3 class="text-black font-semibold mb-4 text-[32px] leading-[150%] mx-auto text-center">
        Get your campaign ready
      </h3>
      <p class="mb-12 text-center text-[20px] leading-[150%] text-[#191C30E5] max-w-[590px] mx-auto">
        Explore and act on recommended tactics. With actionable execution steps and prompts!
      </p>
    </div>

    <df-funnel-manage-content
      class="pt-0"
      [showHeader]="false"
      [showInfoBar]="false"
      [shouldRedirect]="false"
    ></df-funnel-manage-content>
  </div>
</section>
