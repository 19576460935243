<df-full-list-loader
  *ngIf="(graphicService.loadingWelcomePopup$ | async) || (graphicService.integrationLoading$ | async)"
></df-full-list-loader>
<ng-container *ngIf="access; else noAccess">
  <div class="cg-container w-full h-full flex flex-nowrap justify-start items-stretch overflow-x-auto">
    <div class="cg-container__form tui-base-01-bg flex flex-col flex-nowrap justify-start items-stretch">
      <df-funnel-graphic-tabs (tabChange)="aiDesignFile = null"></df-funnel-graphic-tabs>
      <ng-container
        *ngIf="TabEnum.BANNER_GENERATOR === (graphicService.selectedTab$ | async)?.type; then bannerGeneratorForm"
      ></ng-container>
      <ng-container
        *ngIf="TabEnum.AI_DESIGN === (graphicService.selectedTab$ | async)?.type; then aiDesignForm"
      ></ng-container>
    </div>
    <div
      class="cg-container__results p-4 lg:pb-8"
      *ngIf="graphicService.generatedGraphics$ | async as generatedGraphics"
    >
      <div class="tui-base-04-border rounded-2xl h-full tui-base-01-bg relative overflow-x-hidden">
        <div
          *ngIf="
            aiDesignFile && aiDesignFile.base64 && TabEnum.AI_DESIGN === (graphicService.selectedTab$ | async)?.type
          "
          class="mb-4"
        >
          <div class="controls pt-4 mb-6 mx-4 flex gap-x-4 justify-center">
            <button
              *ngIf="!deleteImageMaskButton"
              tuiButton
              appearance="outline"
              class="controls__btn"
              (click)="videoMode = false"
            >
              <svg-icon
                class="mr-2"
                src="/assets/img/icon-pen.svg"
              ></svg-icon>
              <span> {{ 'Funnels.Graphics.Mask' | translate }}</span>
            </button>
            <button
              *ngIf="deleteImageMaskButton"
              tuiButton
              appearance="outline"
              class="controls__btn"
              (click)="deleteMask(); videoMode = false"
            >
              <svg-icon
                class="mr-2"
                src="/assets/img/_ic_trash.svg"
              ></svg-icon>
              <span> {{ 'Funnels.Graphics.Delete Mask' | translate }}</span>
            </button>
            <button
              tuiButton
              appearance="outline"
              class="controls__btn"
              (click)="onUpscaleImage(); videoMode = false"
            >
              <svg-icon
                class="mr-2"
                src="/assets/img/icon-upscale.svg"
              ></svg-icon>
              <span> {{ 'Funnels.Graphics.Upscale image' | translate }}</span>
            </button>
            <button
              tuiButton
              appearance="outline"
              class="controls__btn"
              (click)="onMakeVideo()"
            >
              <svg-icon
                class="mr-2"
                src="/assets/img/icon-upscale.svg"
              ></svg-icon>
              <span> {{ 'Funnels.Graphics.Make Video' | translate }}</span>
            </button>
            <button
              tuiButton
              appearance="outline"
              class="controls__btn"
              (click)="downloadMask(); videoMode = false"
            >
              <svg-icon
                class="mr-2"
                src="/assets/img/icon-download.svg"
              ></svg-icon>
              <span> {{ 'Funnels.Graphics.Download Mask' | translate }}</span>
            </button>
          </div>
          <df-image-drawing
            (save)="saveMask($event)"
            [src]="'data:image/png;base64, ' + aiDesignFile.base64"
            [width]="aiDesignFile.width"
            [height]="aiDesignFile.height"
          >
          </df-image-drawing>
        </div>

        <div
          *ngIf="generatedGraphics.length; else outputPlaceholder"
          class="w-full flex flex-col flex-nowrap justify-start items-stretch generator-output"
        >
          <tui-scrollbar [class]="aiDesignFile?.base64 ? 'h-[40vh] mt-4' : 'h-[90vh]'">
            <ng-container
              *ngIf="TabEnum.AI_DESIGN === (graphicService.selectedTab$ | async)?.type"
              [ngTemplateOutlet]="aiDesignResults"
              [ngTemplateOutletContext]="{
                generatedGraphics: generatedGraphics,
                generatedGraphicsHistory: graphicService.generatedGraphicsHistory$ | async
              }"
            ></ng-container>
            <ng-container
              *ngIf="TabEnum.BANNER_GENERATOR === (graphicService.selectedTab$ | async)?.type"
              [ngTemplateOutlet]="bannerGeneratorResults"
              [ngTemplateOutletContext]="{
                generatedGraphics: generatedGraphics
              }"
            ></ng-container>
          </tui-scrollbar>
        </div>
        <div
          class="absolute top-0 right-0 left-0 bottom-0 tui-base-01-bg flex justify-center items-center"
          *ngIf="graphicService.loading$ | async"
        >
          <img
            src="assets/img/animation_graphics.gif"
            alt="loading"
          />
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noAccess>
  <div class="w-full h-full overflow-y-auto py-5 px-4 md:px-6 lg:px-7.5 tui-base-02">
    <h3 class="text-base md:text-xl font-semibold tui-text-03 mb-4 mt-0">
      {{ 'Funnels.graphics' | translate }}
    </h3>
    <df-funnel-no-access-banner>
      <span
        [innerHTML]="'Funnels.Graphics.in your plan is necessary to access' | translate: { permission: permissionName }"
      ></span>
      <span>{{ 'Funnels.Graphics.funnel graphics' | translate }}</span
      >.
    </df-funnel-no-access-banner>
  </div>
</ng-template>

<ng-template #bannerGeneratorForm>
  <div class="border-b border-solid tui-base-04-border px-5 py-3 flex justify-between items-center">
    <tui-input
      class="w-full"
      [(ngModel)]="fileName"
    >
      {{ 'Funnels.Graphics.title' | translate }}
    </tui-input>
  </div>
  <div class="flex-grow overflow-y-auto">
    <df-content-generator-form
      *ngIf="integration && !!!(graphicService.integrationLoading$ | async)"
      [integration]="integration"
      [form]="form"
      (formSubmitted)="handleDataSubmitted()"
      [loading]="!!(graphicService.loading$ | async)"
      [generateButtonLabel]="'Funnels.Graphics.Generate graphics' | translate"
    ></df-content-generator-form>
  </div>
</ng-template>
<ng-template #aiDesignForm>
  <df-funnel-graphics-ai-design-form
    *ngIf="!!!(graphicService.integrationLoading$ | async) && !videoMode"
    [integration]="integration"
    (fileAddedEvent)="handleAiDesignFile($event)"
    (formSubmitted)="handleDataSubmitted()"
  ></df-funnel-graphics-ai-design-form>

  <df-funnel-graphics-ai-video-form
    (formSubmitted)="handleVideoDataSubmitted()"
    *ngIf="!!!(graphicService.integrationLoading$ | async) && videoMode"
    [integration]="graphicService.videoIntegration"
    [image]="aiDesignFile"
    >!</df-funnel-graphics-ai-video-form
  >
</ng-template>

<ng-template #outputPlaceholder>
  <div
    class="w-full h-full flex flex-col justify-center items-center"
    *ngIf="(graphicService.loading$ | async) === false && !aiDesignFile?.base64"
  >
    <img
      src="assets/img/illustration-graphics.svg"
      alt="folder"
    />
    <p class="tui-text_body-m-1 tui-text-03 font-normal mt-12">
      {{ 'Funnels.Graphics.Your generated graphics will appear here' | translate }}
    </p>
  </div>
</ng-template>
<ng-template
  #bannerGeneratorResults
  let-generatedGraphics="generatedGraphics"
>
  <div class="px-4 py-3 flex justify-between items-center font-medium text-sm">
    <span>{{ 'Funnels.Content generator.Result' | translate }}</span>
    <button
      tuiButton
      appearance="outline"
      type="button"
      size="m"
      [disabled]="!!(loadingDownload$ | async)"
      [showLoader]="!!(loadingDownload$ | async)"
      (click)="downloadAll(generatedGraphics)"
    >
      <span class="tui-primary text-sm font-medium">{{ 'download all' | translate }}</span>
    </button>
  </div>
  <div class="h-full flex-grow p-3 lg:px-5 overflow-y-auto result-grid">
    <df-graphics-generator-item-tile
      class="result-grid__item"
      *ngFor="let item of generatedGraphics"
      [data]="item"
    ></df-graphics-generator-item-tile>
  </div>
</ng-template>
<ng-template
  #aiDesignResults
  let-generatedGraphics="generatedGraphics"
  let-generatedGraphicsHistory="generatedGraphicsHistory"
>
  <div class="ai-design-results-container">
    <span class="tui-text_body-xl font-semibold">{{ 'Funnels.Graphics.Your generated graphics' | translate }}</span>
    <div class="mt-2">
      <df-funnel-graphic-ai-design-tile
        *ngFor="let item of generatedGraphics"
        [data]="item"
        (fileAddedEvent)="handleAiDesignFile($event)"
        (imagePreview)="showDialog($event, aiImagePreview)"
      ></df-funnel-graphic-ai-design-tile>
    </div>
  </div>
</ng-template>
<ng-template #aiImagePreview>
  <div class="preview-image-container">
    <img
      *ngIf="selectedPreviewItem"
      [src]="selectedPreviewItem.value['data']"
      [alt]="selectedPreviewItem.configuration.type"
      class="image"
    />
  </div>
</ng-template>

<df-onboarding-video [src]="'https://www.youtube.com/embed/CXLaTDW0mng?si=BQjontfPwktQmBIb'"></df-onboarding-video>
