import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { BasePanelService } from '@modules/base-panel/pages/base-panel/services/base-panel.service';
import { Observable, of, Subscription, timer } from 'rxjs';
import { swiperFunnelConfig } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/swiper-funnel-config.const';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { FunnelStepsService } from '@shared/services/funnel-steps.service';
import { Tactic } from '@shared/models/tactic.model';
import { FlowItemOutputGraphql, FunnelPermissionEnum, NotificationTypeEnum } from '@modules/graphql/graphql-types';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Step } from '@shared/models/step.model';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { FunnelManageTacticComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/funnel-manage-tactic.component';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { Flow } from '@shared/models/flow.model';
import { throttle } from 'helpful-decorators';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { SwiperComponent } from 'swiper/angular';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { FunnelTab } from '@modules/funnels/shared/enums/funnel-tab.enum';
import { OnboardingService } from '@shared/services/onboarding.service';
import { EOnboardingStep } from '@shared/models/onboarding-step.model';
import { onboardingFadeAndSlide, onboardingHide } from '@shared/animations/onboarding.animations';
import { filter, take, map, lastValueFrom } from 'rxjs';
import { FetchResult } from '@apollo/client/core';
import Swiper from 'swiper';
import isMobile from 'is-mobile';
import { UserService } from '@shared/services/user.service';
import { NotificationsService } from '@modules/notifications/shared/services/notifications.service';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { StatementGraphqlService } from '@modules/statement/shared/services/statement-graphql.service';

const LOCALSTORAGE_CLOSE_DIALOG_FUNNEL_KEY = 'closedSharedFunnelDialogs';

@Component({
  selector: 'df-funnel-manage-content',
  templateUrl: './funnel-manage-content.component.html',
  styleUrls: ['./funnel-manage-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [onboardingFadeAndSlide(), onboardingHide()],
})
export class FunnelManageContentComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() showHeader = true;
  @Input() showInfoBar = true;
  @Input() shouldRedirect = true;

  readonly NotificationTypeEnum = NotificationTypeEnum;
  readonly EOnboardingStep = EOnboardingStep;
  Tactic = Tactic;
  swiperFunnelConfig = swiperFunnelConfig;
  private readonly sub: Subscription = new Subscription();
  readonly loading$: Observable<boolean>;
  toConnect: FunnelTactic[] = [];
  blockDragForMoment = false;
  canEdit = false;
  isActiveTacticPreview = false;
  recommendatedStepLoading = {
    'main-product': false,
    cheeseburger: false,
    icebreaker: false,
    upsell: false,
  };

  @ViewChild('shareDialogTemplate', { static: true })
  dialogTemplate!: PolymorpheusContent<TuiDialogContext>;

  get canManage$(): Observable<boolean> {
    return this.funnelManageService.isSelectedTab(FunnelTab.MANAGE);
  }

  @HostBinding('class.editMode')
  get isEditMode() {
    return this.funnelFlowManageService.editMode;
  }

  @HostBinding('class.flowPanel')
  get flowPanelExpanded() {
    return !this.funnelFlowManageService.panelShort;
  }

  get isEmpty() {
    return !this.funnelManageService?.funnel?.tactics?.length;
  }

  @HostBinding('class.not-empty')
  get isNotEmpty() {
    return !!this.funnelManageService?.funnel?.tactics?.length;
  }

  @HostListener('window:resize', ['$event'])
  @throttle(400)
  onResize() {
    const funnelTactics = document.querySelectorAll('df-funnel-manage-tactic');
    setTimeout(() => {
      funnelTactics.forEach((funnelTactic) => {
        this.funnelFlowManageService.revalidateElement(funnelTactic);
      });
      this.changes.detectChanges();
    }, 200);
  }

  @ViewChildren('singleFunnelManageTactic')
  singleFunnelManageTactic!: QueryList<FunnelManageTacticComponent>;
  @ViewChild('swiperComponent') swiperComponent!: SwiperComponent;

  constructor(
    public basePanelService: BasePanelService,
    public changes: ChangeDetectorRef,
    public funnelManageService: FunnelManageService,
    public funnelStepsService: FunnelStepsService,
    public funnelFlowManageService: FunnelFlowManageService,
    private tacticGraphqlService: TacticGraphqlService,
    private s: SnackbarService,
    private t: TranslateService,
    public onboardingService: OnboardingService,
    public readonly userService: UserService,
    private notificationsService: NotificationsService,
    @Inject(TuiDialogService) private readonly dialog: TuiDialogService,
    private statementGraphqlService: StatementGraphqlService,
  ) {
    this.loading$ = this.funnelManageService.loading$;
  }

  ngOnInit(): void {
    this.listenShortNavChange();
    this.listenStepsChange();
    this.listenFunnelChange();
    this.listenEditModeChange();
    this.listenOnboardingStepChange();
    this.listenShowDialog();
  }

  listenShowDialog() {
    setTimeout(() => {
      const item = localStorage?.getItem(LOCALSTORAGE_CLOSE_DIALOG_FUNNEL_KEY) ?? '[]';
      const parseItem = JSON.parse(item) as number[];

      if (this.getTacticsCount() > 8 && !parseItem.includes(this.funnelManageService.funnel?.id!)) {
        this.showDialog();
      }
    }, 2000);
  }

  ngAfterViewInit() {
    this.funnelFlowManageService.initFlowsController(document.querySelector('.swiper-wrapper')!);
    this.prepareOnboardingStep();
  }

  showDialog() {
    if (this.userService.User?.isSemrushPlan()) return;

    this.dialog.open(this.dialogTemplate).subscribe({
      complete: () => {
        const item = localStorage?.getItem(LOCALSTORAGE_CLOSE_DIALOG_FUNNEL_KEY) ?? '[]';
        const parseItem = JSON.parse(item) as number[];
        if (parseItem.includes(this.funnelManageService.funnel?.id!)) return;

        parseItem.push(this.funnelManageService.funnel?.id!);
        localStorage.setItem(LOCALSTORAGE_CLOSE_DIALOG_FUNNEL_KEY, JSON.stringify(parseItem));
      },
    });
  }

  getTacticsCount() {
    return this.funnelManageService.getTacicsCount();
  }

  showNotificationBookAdemo() {
    setTimeout(() => {
      if (
        this.userService.User?.firstFunnel?.id === this.userService.User?.contextFunnel.id &&
        this.userService.User?.contextFunnel.tactics.length === 1
      ) {
        this.notificationsService.showNotification(null, NotificationTypeEnum.BookAdemo);
      }
    }, 600);
  }

  listenEditModeChange() {
    const sub = this.funnelFlowManageService.editMode$.subscribe(() => {
      if (!this.funnelFlowManageService.editMode) {
        this.clearToConnect();
      }
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  async getRecomendedTactics(step: Step) {
    const statement = await lastValueFrom(
      this.statementGraphqlService
        .getNewStatement(this.funnelManageService!.funnel!.id)
        .pipe(map((res) => res.data.getStatement)),
    );
    if (!statement) {
      this.s.error(this.t.instant('Funnels.Manage.To see recommendations, fill in the statement.'));
      return;
    }

    this.recommendatedStepLoading[step.type] = true;

    this.sub.add(
      this.tacticGraphqlService
        .getAndAssignRecomendedTactic(this.funnelManageService.funnel?.id!, 1, step.id, this.funnelManageService)
        .subscribe(() =>
          setTimeout(() => {
            this.recommendatedStepLoading[step.type] = false;
            this.changes.detectChanges();
          }, 4000),
        ),
    );
  }

  private listenOnboardingStepChange(): void {
    this.sub.add(this.onboardingService.nextStep$.subscribe(() => this.changes.detectChanges()));
  }

  private prepareOnboardingStep(): void {
    if (this.onboardingService.onboardingRunning) {
      this.funnelManageService.funnel$
        .pipe(
          filter((f) => !!f),
          take(1),
        )
        .subscribe(() => {
          const tactic = this.funnelManageService.sortedFunnelTactics(this.funnelStepsService.steps[0])[1];
          if (tactic?.onboardingActionTactic) {
            tactic.onboardingActionTactic = true;
            this.funnelManageService.funnel!.flows[0].highlighted = true;
            this.funnelManageService.highlightFunnelTacticForFlow(this.funnelManageService.funnel!.flows[0]);
            this.funnelFlowManageService.paintConnections();
          }
        });
    }
  }

  isAlreadyConnect() {
    const chosenFirstId: number = this.toConnect[0].id;
    const chosenSecondId: number = this.toConnect[1].id;
    let isAlready = false;
    this.funnelFlowManageService.flow?.items.map((i: FlowItemOutputGraphql) => {
      if (i.from === chosenFirstId || i.to === chosenFirstId) {
        if (i.from === chosenSecondId || i.to === chosenSecondId) {
          isAlready = true;
        }
      }
    });
    if (isAlready) {
      this.s.error(this.t.instant('Funnels.Manage.Already connected'));
      this.toConnect[1].isActiveToConnect = false;
      this.toConnect.splice(1, 1);
    }
    return isAlready;
  }

  onFunnelTacticClick(funnelTactic: FunnelTactic) {
    if (!this.funnelFlowManageService.editMode) return;
    if (!this.canConnect(funnelTactic)) {
      this.clearToConnect();
      return;
    }
    this.toConnect.push(funnelTactic);
    funnelTactic.isActiveToConnect = true;
    if (this.toConnect.length === 2) {
      if (this.isAlreadyConnect()) {
        return;
      }
      this.funnelFlowManageService.flow?.items.push({
        from: this.toConnect[0].id,
        to: this.toConnect[1].id,
        toRemoveWithoutSave: true,
      });
      this.funnelFlowManageService.flow = new Flow(this.funnelFlowManageService.flow);
      this.clearToConnect(true);
      this.funnelFlowManageService.paintConnections();
    }
  }

  listenFunnelChange() {
    this.checkEditPermission();
    this.sub.add(this.funnelManageService.funnel$.subscribe(() => this.checkEditPermission()));
  }

  checkEditPermission() {
    this.canEdit =
      this.funnelManageService.funnel?.currentUserPermission === FunnelPermissionEnum.Editor ||
      this.onboardingService.onboardingRunning;
    this.changes.detectChanges();
  }

  dragDelay() {
    return isMobile() ? 100 : 0;
  }

  onDragEnd() {
    this.swiperComponent.allowTouchMove = false;
  }

  onDrag(event: CdkDragMove) {
    this.swiperComponent.allowTouchMove = false;
    if (event.delta.x > 0 && event.pointerPosition.x > window.innerWidth - 80 && !this.blockDragForMoment) {
      this.swiperComponent.swiperRef.slideNext();
      timer(200).subscribe(() => {
        //dummy solution for refresh cdk drag drop
        window.scroll({ top: window.scrollY + 1, left: window.scrollX });
      });
      this.blockDragForMoment = true;
      timer(800).subscribe(() => {
        this.blockDragForMoment = false;
      });
    }

    if (event.delta.x > 0 && event.pointerPosition.x < 80 && !this.blockDragForMoment) {
      this.swiperComponent.swiperRef.slidePrev();
      timer(200).subscribe(() => {
        //dummy solution for refresh cdk drag drop
        window.scroll({ top: window.scrollY + 1, left: window.scrollX });
      });
      this.blockDragForMoment = true;
      timer(800).subscribe(() => {
        this.blockDragForMoment = false;
      });
    }
  }

  canConnect(funnelTacticToConnect: FunnelTactic): boolean {
    if (!this.toConnect.length) return true;
    if (
      this.toConnect[0].id === funnelTacticToConnect.id &&
      this.toConnect[0].step.id === funnelTacticToConnect.step.id
    ) {
      return false;
    }
    return true;
  }

  listenStepsChange() {
    const sub = this.funnelStepsService.steps$.subscribe(() => {
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  listenShortNavChange() {
    const sub = this.basePanelService.emitter.subscribe(() => {
      this.changes.markForCheck();
    });
    this.sub.add(sub);
  }

  openBlog() {
    window.open('https://www.digitalfirst.ai/blog/4-stages-of-the-sales-funnel-how-does-it-work', '_blank')?.focus();
  }

  clearToConnect(withTimeout?: boolean) {
    timer(withTimeout ? 500 : 0).subscribe(() => {
      this.toConnect.map((funnelTactic: FunnelTactic) => {
        funnelTactic.isActiveToConnect = false;
      });
      this.toConnect = [];
      this.changes.detectChanges();
    });
  }

  drop(event: CdkDragDrop<{ step: Step }>) {
    let moved = false;
    if (this.onboardingService.onboardingRunning) {
      // force element to drop in last column
      const forceEvent = {
        previousIndex: event.previousIndex,
        currentIndex: 0,
        container: { data: { step: this.funnelStepsService.steps[3] } },
        previousContainer: { data: { ...event.previousContainer.data } },
      } as CdkDragDrop<{ step: Step }>;
      this.moveFunnelTacticToAnotherStep(forceEvent);
      this.swipeToLastStep();
      moved = true;
    } else if (event.previousContainer === event.container) {
      this.moveFunnelTacticInTheSameStep(event);
    } else {
      this.moveFunnelTacticToAnotherStep(event);
    }
    this.funnelManageService.recalculateStatsInFunnel();
    this.changes.detectChanges();
    this.funnelFlowManageService.paintConnections();
    this.changes.detectChanges();

    if (moved) {
      this.onboardingService.currentStep?.nextStep();
    }
  }

  moveFunnelTacticInTheSameStep(event: CdkDragDrop<{ step: Step }>) {
    const funnelTacticsArr = this.funnelManageService.sortedFunnelTactics(event.container.data.step);
    moveItemInArray(funnelTacticsArr, event.previousIndex, event.currentIndex);
    funnelTacticsArr.map((funnelTactic: FunnelTactic, index) => {
      this.funnelManageService.funnelTactics[event.container.data.step.id][funnelTactic.id].position = index;
    });
    this.getReorderRequest(event).toPromise();
  }

  moveFunnelTacticToAnotherStep(event: CdkDragDrop<{ step: Step }>) {
    const previousFunnelTacticArr = this.funnelManageService.sortedFunnelTactics(event.previousContainer.data.step);
    const currentFunnelTacticArr = this.funnelManageService.sortedFunnelTactics(event.container.data.step);

    transferArrayItem(previousFunnelTacticArr, currentFunnelTacticArr, event.previousIndex, event.currentIndex);

    this.funnelManageService.funnelTactics[event.previousContainer.data.step.id] = {};
    previousFunnelTacticArr.map((funnelTactic: FunnelTactic, index) => {
      this.funnelManageService.funnelTactics[event.previousContainer.data.step.id][funnelTactic.id] =
        funnelTactic as FunnelTactic;
      this.funnelManageService.funnelTactics[event.previousContainer.data.step.id][funnelTactic.id].position = index;
    });

    this.funnelManageService.funnelTactics[event.container.data.step.id] = {};
    currentFunnelTacticArr.map((funnelTactic: FunnelTactic, index) => {
      funnelTactic.step = event.container.data.step;
      this.funnelManageService.funnelTactics[event.container.data.step.id][funnelTactic.id] = funnelTactic;
      this.funnelManageService.funnelTactics[event.container.data.step.id][funnelTactic.id].position = index;
    });

    this.getReorderRequest(event).subscribe(() => {
      this.funnelManageService.setFunnelStepSummaries();
      this.changes.detectChanges();
    });
  }

  getReorderRequest(event: CdkDragDrop<{ step: Step }>): Observable<boolean | FetchResult> {
    return this.onboardingService.onboardingRunning
      ? of(true)
      : this.tacticGraphqlService.reorderFunnelTactic({
          funnelTacticId: event.item.data.funnelTactic.id,
          stepId: event.container.data.step.id,
          position: event.currentIndex,
        });
  }

  handleOnboardingNextStep(): void {
    this.onboardingService.currentStep?.nextStep(() => {
      this.funnelManageService.funnel!.flows[0] = new Flow(this.funnelManageService.funnel?.flows[0]);
      this.funnelManageService.funnel!.flows[0].highlighted = true;
      this.funnelManageService.highlightFunnelTacticForFlow(this.funnelManageService.funnel!.flows[0]);
      this.funnelFlowManageService.paintConnections();
      setTimeout(() => this.swipeToLastStep(), 500);
    });
  }

  onboardingDropEnabled(drag: CdkDrag, drop: CdkDropList): boolean {
    return drag.data?.funnelTactic.onboardingActionTactic
      ? drop.data?.step.type === OnboardingService.FUNNEL_DROP_STEP_TYPE
      : true;
  }

  isOnboardingDropStep(step?: Step): boolean {
    return step?.type === OnboardingService.FUNNEL_DROP_STEP_TYPE;
  }

  private swipeToLastStep(): void {
    const swiper = document.querySelector('.swiper')?.['swiper'] as Swiper;
    swiper.slideTo(3, 450);
  }

  getTacticPreviewOpen(val: boolean) {
    this.isActiveTacticPreview = val;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
