import { ChangeDetectorRef, Component } from '@angular/core';
import { AuthOutputGraphql } from '@modules/graphql/graphql-types';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Regex } from '@shared/configs/regex';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ApolloError, FetchResult } from '@apollo/client/core';
import { CustomGraphqlErrorInterface } from '@modules/graphql/interfaces/custom-graphql-error.interface';
import { GraphqlErrorEnum } from '@modules/graphql/enums/graphql-error.enum';
import { Config } from '@shared/configs/config';
import { Observable } from 'rxjs';
import { AbstractAuthComponent } from '@modules/authorization/shared/abstracts/abstract-auth.component';
import { GoogleAnalyticsEvent } from '@shared/services/events.service';
import { User } from '@sentry/angular-ivy';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';

@Component({ template: '' })
export abstract class AbstractRegisterComponent<T> extends AbstractAuthComponent {
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.pattern(Regex.whiteSpace),
    ]),
    lastName: new UntypedFormControl('', [Validators.minLength(2), Validators.pattern(Regex.whiteSpace)]),
    email: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.email)]),
    password: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.password)]),
    rulesAccepted: new UntypedFormControl(false, [Validators.requiredTrue]),
  });

  protected constructor(
    changes: ChangeDetectorRef,
    recaptchaService: ReCaptchaV3Service,
    protected funnelService: FunnelGraphqlService,
  ) {
    super(changes, recaptchaService);
  }

  signUp() {
    if (this.loading) return;

    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) return;
    this.loading = true;

    this.authController.getCaptcha().subscribe((token: string | null) => {
      if (token) {
        this.signUpHandler(token);
      }
    });
  }

  protected abstract getRegisterRequest(token: string): Observable<FetchResult<T>>;

  protected signUpHandler(token: string) {
    this.getRegisterRequest(token).subscribe({
      next: this.successSignUp.bind(this),
      error: this.errorSignUp.bind(this),
    });
  }

  protected successSignUp(res: FetchResult<T>) {
    this.loading = false;
    this.changes.detectChanges();
    if (res.data) {
      const auth = res.data['register'] as AuthOutputGraphql;
      this.authController.saveAuthData(auth);
      this.s.success(this.t.instant('Auth.Register.Thank you for register a new account. Confirm your e-mail.'));
      this.eventService.pushToGoogleAnalyticsEvent(
        auth.eventType === 'sign-up' ? GoogleAnalyticsEvent.Signup : GoogleAnalyticsEvent.Signin,
        {
          userID: auth.user?.id,
          email: auth.user?.email,
        },
      );

      this.eventService.setUpIntercome(res.data['register'].user);

      this.checkUserHaveAnonymousFunnel(auth?.user as User);
    }
  }

  protected checkUserHaveAnonymousFunnel(user: User) {
    if (!user) return;

    try {
      const anonymousUser = JSON.parse(localStorage.getItem('dfai-anonymous-user') ?? '');
      if (!anonymousUser) return;

      this.funnelService.setFunnelToOwner(anonymousUser.funnelId).subscribe(() => {
        localStorage.removeItem('dfai-anonymous-user');
      });
    } catch {
      //silent
    }
  }

  protected redirectAfterSign() {
    this.n.go(Config.MAIN_VIEW);
  }

  protected errorSignUp(error: ApolloError) {
    if (error.graphQLErrors?.length) {
      switch ((error.graphQLErrors[0] as CustomGraphqlErrorInterface).errorCode) {
        case GraphqlErrorEnum.ALREADY_EXISTS:
          this.s.error(this.t.instant('Auth.Register.User with provided e-mail already exists'));
          break;
        case GraphqlErrorEnum.VALIDATION_ERROR:
          this.s.error(this.t.instant('You provided wrong data. Please correct them and try again.'));
          break;
      }
    } else {
      this.s.defaultError();
    }
    this.loading = false;
    this.changes.detectChanges();
  }
}
