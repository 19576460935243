<div class="header">
  <h2 class="header-title">Landing page</h2>
  <div class="header-icon">
    <df-tactic-step-icon
      (click)="onEditLanding()"
      [type]="'edit'"
    ></df-tactic-step-icon>
  </div>
</div>
<!-- 
<div
  class="image"
  [ngStyle]="{ backgroundImage: imageSrc() }"
></div> -->

<iframe
  class="image"
  [src]="getUrl()"
></iframe>
