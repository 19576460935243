import { Component, Inject, Input } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'df-onboarding-video',
  templateUrl: './onboarding-video.component.html',
  styleUrls: ['./onboarding-video.component.scss'],
})
export class OnboardingVideoComponent {
  @Input() src!: string;

  constructor(
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    public userService: UserService,
  ) {}

  showDialog(content: PolymorpheusContent<TuiDialogContext>) {
    this.dialogs.open(content, { appearance: 'onboarding-video', size: 'auto' }).subscribe();
  }
}
