<div class="py-12 px-2 md:px-8 border-r-[1px] border-r-[#E0E0E0] border-solid min-h-screen">
  <h2 class="mb-2 text-2xl font-semibold text-[#191C30E5]">Add your business details</h2>
  <p class="mb-8 text-[14px] font-medium text-[rgba(27,31,59,0.65)]">
    Share your business details to train our model and get a marketing strategy in just a few minutes.
  </p>
  <virtual-scroller
    #scroll
    [items]="actionList"
    [scrollDebounceTime]="200"
    [enableUnequalChildrenSizes]="true"
    [bufferAmount]="actionList.length"
  >
    <button
      (click)="changeActiveAction(action)"
      tuiAction
      type="button"
      *ngFor="let action of scroll.viewPortItems"
      class="hidden md:flex py-6 px-4 no-shadow rounded-[24px] border-[1px] transform-none shadow-none mb-5 w-full justify-normal items-center"
      [style.borderColor]="action.borderColor"
      [style.background]="action.isActive ? action.activeColor : ''"
    >
      <img
        class="md:hidden lg:block size-[40px] mr-2"
        [src]="action.icon"
        alt=""
      />

      <div>
        <h3 class="text-[18px] font-semibold text-[#191C30E5]">
          {{ action.title }}
        </h3>
        <p class="text-[14px] font-medium text-[#191C30E5]">
          {{ action.description }}
        </p>
      </div>

      <img
        class="ml-auto"
        src="assets/img/chevron-right.svg"
        alt=""
      />
    </button>
  </virtual-scroller>

  <tui-accordion class="block md:hidden mobile-business-accordion">
    <tui-accordion-item
      #accordionItem
      (click)="changeActiveAction(action)"
      [borders]="null"
      *ngFor="let action of actionList"
      class="flex no-shadow rounded-[24px] border-[1px] transform-none shadow-none mb-5 justify-normal items-center"
      [style.borderColor]="action.borderColor"
      [style.background]="action.isActive && accordionItem.open ? action.activeColor : ''"
      [open]="action.isActive"
    >
      <img
        class="w-[40px] h-[40px] mr-2"
        [src]="action.icon"
        alt=""
      />
      <div class="w-[80%]">
        <h3 class="text-[18px] font-semibold text-[#191C30E5] text-wrap">
          {{ action.title }}
        </h3>
        <p class="text-[14px] font-medium text-[#191C30E5] text-wrap">
          {{ action.description }}
        </p>
      </div>

      <ng-template tuiAccordionItemContent>
        <ng-container *ngComponentOutlet="action.component; inputs: { form: form }" />
      </ng-template>
    </tui-accordion-item>
  </tui-accordion>
</div>
