import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CanvasInstanceOutput, CanvasInstancePositionValueOutput } from '@modules/graphql/graphql-types';
import { MarketingCampaignService } from '@modules/marketing-campaign/shared/services/marketing-campaign.service';
import { groupBy } from 'lodash-es';
import { retry } from 'rxjs';
import Typewriter from 'typewriter-effect/dist/core';

@Component({
  selector: 'df-campaign-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
})
export class CampaignCanvasComponent implements OnInit {
  @Input() campaignLoading = true;
  @Output() loadedSuccess = new EventEmitter();

  canvases: CanvasInstanceOutput[] = [];
  funnelId!: number;
  showSkeleton = true;

  constructor(
    private marketingCampaignService: MarketingCampaignService,
    private route: ActivatedRoute,
    private changes: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.funnelId = Number(this.route.snapshot.params['funnelId']);

    if (this.funnelId) {
      const timer = setInterval(() => this.getCanvas(timer), 10000);
      this.getCanvas(timer);
    } else {
      this.route.parent?.params.subscribe(() => {
        this.funnelId = +this.route.parent?.snapshot.params['id'];
        this.canvases = [];
        this.showSkeleton = true;

        this.clear();

        this.changes.detectChanges();

        const timer = setInterval(() => this.getCanvas(timer), 10000);
        this.getCanvas(timer);
      });
    }
  }

  clear() {
    const elements = document.querySelectorAll('#typewriter-canvas');
    elements.forEach((element) => {
      while (element?.firstChild) {
        element.removeChild(element.firstChild);
      }
    });
  }

  getCanvas(timer = null) {
    const type = 'canvas';

    this.marketingCampaignService
      .getDataByType(this.funnelId, type)
      .pipe(retry({ delay: 10000 }))
      .subscribe((res) => {
        res?.data.forEach((canvas: CanvasInstanceOutput) => {
          this.canvases.some((item) => item?.id === canvas?.id) ? null : this.canvases.push(canvas);
        });

        if (this.canvases.length >= 3) {
          if (timer) clearInterval(timer);

          this.loadedSuccess.emit();
          this.showSkeleton = false;
          this.animate();
          this.changes.detectChanges();
        }
      });
  }

  animate() {
    const elements = document.querySelectorAll('#typewriter-canvas');

    this.clear();

    let text = `<div class="p-2 grid grid-cols-1 lg:grid-cols-3 gap-4 controller md:p-6">`;

    this.canvases.forEach((canvas) => {
      const positionsValues = this.groupPositions(canvas?.positionValues ?? []);

      text += ` <div class="bg-white py-6 px-4 rounded-[12px]">
            <h1 class="text-[#2E4FFF] font-bold text-[1.5rem] mb-6">${canvas?.template?.name}</h1>
            <div class="grid grid-cols-1 xl:grid-cols-2 gap-8">`;

      Object.values(positionsValues).forEach((element) => {
        text += `<div> <div> <h2 class="text-[#191C30E5] font-bold text-[1rem] mb-3">${element[0].position.label}</h2>
        <ul class="list-disc pl-4">`;

        element.forEach((item) => {
          text += `<li>${item.value}</li>`;
        });

        text += `</ul></div></div>`;
      });

      text += `</div></div>`;
    });

    text += `</div>`;

    if (this.campaignLoading) {
      const typewriter = new Typewriter(elements[0], {
        delay: 1,
      });
      typewriter.typeString(text).start();
    } else {
      elements.forEach((element) => element?.insertAdjacentHTML('beforeend', text));
    }
  }

  groupPositions(positionsValues: CanvasInstancePositionValueOutput[]) {
    return groupBy(positionsValues, 'position.id');
  }
}
