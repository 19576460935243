<form
  class="relative"
  [formGroup]="form"
  [validate-form]="form"
  (submit)="onSubmit()"
  *ngIf="ayrshareAccount"
>
  <div class="flex items-center justify-start">
    <button
      type="button"
      class="w-fit px-8 text-[#1B1F3BA6] font-semibold text-[14px]"
      appearance="Flat"
      (click)="this.closePublication.emit()"
      tuiIconButton
      icon="tuiIconArrowLeft"
    >
      {{ 'Ayrshare.Back to Tactic' | translate }}
    </button>
  </div>
  <div class="ayrshare__wrapper h-[90vh] px-8 py-4">
    <h1 class="font-bold text-[24px] text-[#191C30E5]">
      {{ 'Ayrshare.Publish Post' | translate }}
    </h1>

    <div class="ayrshare__grid grid grid-cols-1 md:grid-cols-2 gap-6 m-auto mt-8">
      <div class="flex flex-col">
        <div class="ayrshare__text mb-6">
          <div class="mb-4">
            <h2 class="font-bold text-[1rem] text-[#191C30E5] mb-2">
              {{ 'Ayrshare.Post' | translate }}
            </h2>
            <p class="text-[#1B1F3BA6] font-medium text-[14px]">
              {{ 'Ayrshare.Your publication content preview' | translate }}
            </p>
          </div>

          <div class="mr-2">
            <tui-island
              *ngIf="showText"
              class="tui-island-prompt--yellow mt-2 tui-text_body-m-9 tui-text-03 border-solid border-[1px] border-[#FCE65B] bg-[#FFFBE8] tui-island_text-align_left ayrshare__tui-island"
            >
              <div class="flex items-center gap-4 justify-end">
                <button
                  class="control flex items-center justify-center w-[20px] h-[20px] hover:"
                  (click)="editText(false)"
                >
                  <svg-icon [src]="'/assets/img/prompt-pencil.svg'"></svg-icon>
                </button>
                <button
                  class="control flex items-center justify-center w-[20px] h-[20px]"
                  (click)="copyToClipboard(getControl('text')?.value)"
                >
                  <svg-icon [src]="'/assets/img/prompt-copy.svg'"></svg-icon>
                </button>
              </div>
              <span class="truncate-text">{{ getControl('text')?.value }}</span>
            </tui-island>
            <tui-text-area
              *ngIf="!showText"
              class="h-[400px]"
              formControlName="text"
            ></tui-text-area>
            <button
              class="mt-2"
              [size]="'s'"
              tuiButton
              (click)="editText(true)"
              *ngIf="!showText"
            >
              {{ 'Ayrshare.Save' | translate }}
            </button>
            <df-error-input [control]="getControl('text')"></df-error-input>
          </div>
        </div>

        <div class="ayrshare__image">
          <div class="mb-4">
            <h2 class="font-bold text-[1rem] text-[#191C30E5] mb-2">
              {{ 'Ayrshare.Image' | translate }}
            </h2>
            <p class="text-[#1B1F3BA6] font-medium text-[14px]">
              {{ 'Ayrshare.Upload the optional image to a post' | translate }}
            </p>
          </div>

          <div class="flex-grow mr-2">
            <df-input-file
              [type]="'new'"
              [form]="form"
              [formName]="'image'"
            ></df-input-file>
            <df-error-input [control]="getControl('image')"></df-error-input>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="ayrshare__channels mb-6">
          <div class="mb-4">
            <h2 class="font-bold text-[1rem] text-[#191C30E5] flex justify-between items-center mb-2">
              <span>{{ 'Ayrshare.Channels' | translate }}</span>
              <span
                (click)="onAddChannelClick()"
                class="font-semibold text-[1rem] text-[#1B1F3BA6]"
                >{{ 'Ayrshare.Connect more' | translate }}</span
              >
            </h2>
            <p class="text-[#1B1F3BA6] font-medium text-[14px]">
              {{ 'Ayrshare.Select the channels you want to publish the post' | translate }}
            </p>
          </div>

          <div class="ayrshare__channels-list">
            <div *ngIf="getChannels().length; else noChannels">
              <label class="flex items-center mb-5 select-all">
                <p class="mr-4 text-[#191C30E5] font-medium text-[1rem]">
                  {{ 'Ayrshare.All' | translate }}
                </p>
                <div class="relative">
                  <input
                    class="ayrshare__checkbox"
                    (change)="selectAll($event)"
                    type="checkbox"
                  />
                  <div class="checkmark absolute top-[4px] -left-[6px]"></div>
                </div>
              </label>
              <div class="flex gap-3">
                <ng-container *ngFor="let channel of getChannels()">
                  <label class="ayrshare__channel flex flex-col items-center max-w-fit py-4 px-6 cursor-pointer">
                    <div class="relative mb-5">
                      <input
                        [checked]="channel.checked"
                        class="ayrshare__checkbox"
                        (change)="onChannelClick(channel)"
                        type="checkbox"
                      />
                      <div class="checkmark absolute top-[4px] -left-[6px]"></div>
                    </div>
                    <svg-icon
                      [svgStyle]="{ width: '42px', height: '42px' }"
                      [src]="'/assets/img/' + channel.icon"
                    ></svg-icon>
                  </label>
                </ng-container>
              </div>
            </div>
            <div
              class="df-error-input"
              [ngClass]="{ 'df-error-input--show': !!channelsError }"
            >
              {{ channelsError }}
            </div>
          </div>
        </div>

        <div class="ayrshare__publish-on">
          <div class="mb-4">
            <h2 class="font-bold text-[1rem] text-[#191C30E5] mb-2">
              {{ 'Ayrshare.PublishOn' | translate }}
            </h2>
            <p class="text-[#1B1F3BA6] font-medium text-[14px]">
              {{ 'Ayrshare.Leave blank to publish immediately or choose a date and type a time' | translate }}
            </p>

            <tui-notification
              *ngIf="showNotification"
              (close)="showNotification = false"
              class="my-4"
              style="border: 1px solid #1677ff"
            >
              <div class="font-bold text-[1rem] text-[#191C30E5]">Content calendar</div>
              <div class="font-normal text-[14px] text-[#191C30E5]">
                The publication date is currently set to the present time. Please choose a future date to schedule your
                publication.
              </div>
            </tui-notification>
          </div>

          <div class="ayrshare__publish-on-calendar">
            <label>
              <p class="font-semibold text-[1rem] text-[#1B1F3BA6]">
                {{ 'Ayrshare.Choose date and time' | translate }}
              </p>
              <tui-input-date-time
                formControlName="publishOn"
                timeMode="HH:MM"
              >
                Date
              </tui-input-date-time>
              <div
                class="df-error-input"
                [ngClass]="{ 'df-error-input--show': !!dateError }"
              >
                {{ dateError }}
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="absolute -bottom-[55px] left-0 w-full flex justify-end ayrshare__footer px-5">
    <button
      tuiButton
      type="submit"
      class="mt-4"
    >
      {{ 'Ayrshare.Add to schedule' | translate }}
    </button>
  </div>
</form>
<ng-template #noChannels>
  <div class="bg-[#f6f6f6] py-8 ayrshare__no-content flex flex-col justify-center items-center px-16">
    <div class="mb-10 text-[40px]">📢</div>
    <p class="mb-5 font-semibold text-[1rem] text-[#191C30E5]">
      {{ 'Ayrshare.No connected channels' | translate }}
    </p>
    <p class="mb-8 font-medium text-[14px] text-[#1B1F3BA6]">
      {{
        'Ayrshare.To share your content on social media, you need to connect your social media accounts.' | translate
      }}
    </p>
    <button
      tuiButton
      [size]="'s'"
      (click)="onAddChannelClick()"
    >
      {{ 'Ayrshare.Connect more' | translate }}
    </button>
  </div>
</ng-template>
