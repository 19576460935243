<div
  *ngIf="form"
  [formGroup]="form"
  [validate-form]="form"
>
  <df-business-data-form-header [type]="type"></df-business-data-form-header>
  <div id="dropbox-box"></div>

  <div
    class="mt-6"
    *ngFor="let file of filesList"
  >
    <div class="rounded-[16px] border-[1px] border-solid border-[#E0E0E0] flex justify-between items-center px-2 py-2">
      <div class="flex">
        <img
          class="mr-3"
          src="assets/img/dropbox.svg"
          alt=""
        />
        <span class="text-[14px] text-[#191C30E5] font-semibold">{{ file.name }}</span>
      </div>

      <div>
        <svg-icon
          (click)="removeField(file.link)"
          src="assets/img/_ic_trash.svg"
          class="cursor-pointer pointer-events-auto"
        ></svg-icon>
      </div>
    </div>
  </div>
</div>
