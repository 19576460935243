<div class="dialog-bg"></div>
<h3 class="text-black text-[24px] leading-8 font-semibold text-center mb-4 max-w-[460px] mx-auto">
  Generate instant, ready-to-action marketing plans with AI
</h3>
<p class="text-[16px] leading-6 font-medium text-center text-[#1B1F3BA6] mb-8 max-w-[650px] mx-auto">
  Feed AI your business info. Select goals. Get a custom strategy with step-by-step campaign tactics. In minutes, not
  weeks!
</p>
<form
  [formGroup]="formGroup"
  (ngSubmit)="onSubmitData()"
>
  <div class="input-container">
    <label class="label">E-mail address</label>
    <tui-input
      [tuiTextfieldLabelOutside]="true"
      formControlName="email"
    >
      Type in your e-mail
      <input tuiTextfield />
    </tui-input>
    <df-error-input [control]="getControl('email')"></df-error-input>
  </div>

  <button
    class="w-full"
    type="submit"
    #button
    [showLoader]="isLoading"
    [disabled]="formGroup.invalid"
    tuiButton
    [tuiHint]="button.disabled ? 'Provide valid data' : ''"
  >
    Create Campaign Now
  </button>
</form>
