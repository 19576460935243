import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { RouteData } from '@shared/models/route-data.model';
import isMobile from 'is-mobile';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BasePanelService {
  mobile!: boolean;

  routeData: RouteData = new RouteData();

  userSetNavigation = false;

  emitter: EventEmitter<BasePanelEvent> = new EventEmitter<BasePanelEvent>();

  novuNotoficationsSubject = new Subject<boolean>();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  setShortLeftNavigation(value: boolean, byUser?: boolean) {
    if ((!value && this.userSetNavigation && !byUser) || isMobile()) {
      value = false;
    }
    this.routeData.shortLeftNavigation = value;
    this.emitter.emit(BasePanelEvent.CHANGE_NAVIGATION_SHORT);
    this.routeData.shortLeftNavigation!
      ? this.document.body.classList.add('menuShort')
      : this.document.body.classList.remove('menuShort');
  }

  openChat() {
    this.emitter.emit(BasePanelEvent.OPEN_CHAT);
  }

  showNovuNotification(open: boolean) {
    this.novuNotoficationsSubject.next(open);
  }
}

export enum BasePanelEvent {
  CHANGE_NAVIGATION_SHORT,
  RELOAD_FUNNELS_TO_CONTEXT,
  OPEN_CHAT,
}
