<div
  class="scroll-container pb-4"
  infiniteScroll
  [infiniteScrollThrottle]="50"
  (scrolled)="onEnd()"
  [scrollWindow]="false"
  [ngClass]="{
    'onboarding-accent-parent': isOnboardingRunning,
    'pt-12': onboardingService.onboardingRunning
  }"
>
  <!-- no records -->
  <div
    class="w-full h-full flex flex-col items-center justify-center"
    *ngIf="!tacticsListService.records.length && !tacticsListService.loading && tacticsListService.inited"
  >
    <svg-icon
      [src]="'/assets/img/ic-no-records.svg'"
      [svgStyle]="{ width: '150px', height: '150px' }"
    ></svg-icon>
    <h5 class="tui-text_h5 mt-4">
      {{ 'Tactics.List.Seems like this page is empty' | translate }}
    </h5>
  </div>

  <div class="px-2 md:px-4 lg:px-8 mb-3">
    <df-tactic-top-banner-tile
      [tactics]="tacticsListService.topBannerTactics"
      *ngIf="
        !topBannerDisabled &&
        tacticsListService.topBannerTactics.length &&
        !topBannerLoading &&
        !tacticsListService.query?.search &&
        !modalList &&
        tacticsListService.records.length
      "
    ></df-tactic-top-banner-tile>
  </div>
  <df-load-prev-page
    *ngIf="showLoadPrev()"
    (onLoadPrevPage)="onLoadPrevPage()"
    [loading]="tacticsListService.loadingPrevPage"
    [text]="'Tactics.List.Load previous tactics' | translate"
  ></df-load-prev-page>

  <div
    class="grid-tiles px-2 md:px-4 lg:px-8"
    #container
  >
    <ng-container *ngFor="let item of tacticsListService.records; trackBy: identify; let i = index">
      <df-tactic-rec-tile
        *ngIf="
          basePanelService.routeData?.showRequestCard === true &&
          !onboardingService.onboardingRunning &&
          ((i === 2 && tacticsListService.records.length >= 4) || (i === 0 && tacticsListService.records.length < 4))
        "
      ></df-tactic-rec-tile>
      <df-tactic-tile
        [tactic]="item"
        [step]="step"
        [funnel]="funnel"
        [toCopy]="toCopy"
        (onAddToFunnel)="reloadFunnel()"
        [noShadow]="isOnboardingRunning && i === 0"
        [ngClass]="{
          'onboarding-focus onboarding-fade': isOnboardingRunning,
          'onboarding-next': isOnboardingRunning && i > 0,
          'onboarding-action onboarding-accent onboarding-temp-anchor': isOnboardingRunning && i === 0
        }"
      ></df-tactic-tile>
    </ng-container>
  </div>

  <df-load-prev-page
    *ngIf="!tacticsListService.noNextPage && !tacticsListService.loadingNextPage && !tacticsListService.loading"
    class="big-screen"
    (onLoadPrevPage)="onEnd()"
    [text]="'Tactics.List.Load next tactics' | translate"
  ></df-load-prev-page>
  <df-page-list-loader *ngIf="tacticsListService.loadingNextPage || tacticsListService.loading"></df-page-list-loader>
</div>
