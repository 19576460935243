<!-- <df-tactics-list-sub-header></df-tactics-list-sub-header> -->
<div
  data-test="tactics"
  class="mx-4 md:mx-8 mt-4 md:my-6 relative flex-shrink-0"
  [ngClass]="{
    'h-64 md:h-auto': onboardingService.isCurrentStepType(EOnboardingStep.TACTICS_INTRO)
  }"
>
  <!-- SORT subheader -->
  <df-sort-list-sub-header
    class="mb-6"
    [modalList]="true"
    [filtersService]="tacticsFiltersService"
  >
    <!-- ng-content purchased - Tactics specific filter -->
    <df-tactic-purchased-filter
      class="mr-3"
      purchased
    ></df-tactic-purchased-filter>
  </df-sort-list-sub-header>

  <!-- FILTER subheader -->
  <df-filter-list-sub-header
    [filtersService]="tacticsFiltersService"
    [filtersVisible]="showFilters"
  ></df-filter-list-sub-header>
  <df-tactics-list-onboarding-banner
    *ngIf="onboardingService.onboardingRunning"
    class="onboarding-focus onboarding-shadow onboarding-action"
    (buttonClick)="onboardingService.currentStep?.nextStep()"
    [@onboardingFadeAndSlide]="
      (onboardingService.loading$ | async) === false &&
      onboardingService.isCurrentStepType(EOnboardingStep.TACTICS_INTRO)
        ? 'in'
        : 'out'
    "
  ></df-tactics-list-onboarding-banner>
</div>

<!-- here goes df-tactic-tiles -->
<router-outlet></router-outlet>

<!-- button onboarding -->
<df-onboarding-video [src]="'https://www.youtube.com/embed/sD0HHfaYguo?si=XO0B8BQM_Mf8vdHq'"></df-onboarding-video>

<!-- button NEW -->
<df-button-new
  (click)="createNewTactic()"
  *ngIf="!onboardingService.onboardingRunning"
></df-button-new>
