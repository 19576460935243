<div
  *ngIf="form"
  [formGroup]="form"
  [validate-form]="form"
>
  <df-business-data-form-header [type]="type"></df-business-data-form-header>
  <div *ngIf="form">
    <div *ngIf="getControl() as control">
      <tui-input-files
        class="bg-white"
        [accept]="TUI_FILES_RECORDING_FILES_EXTENSION"
        [formControl]="control"
        [multiple]="true"
        (ngModelChange)="control.markAsTouched()"
        (reject)="onReject($event)"
      ></tui-input-files>

      <tui-files class="mt-2">
        <tui-file
          *ngFor="let file of control.valueChanges | async"
          [file]="file"
          [showDelete]="control.enabled"
          (removed)="removeFile(file)"
        >
          <audio controls>
            <source
              [src]="file?.src ? file.src : createSource(file)"
              type="audio/ogg"
            />
            Your browser does not support the audio element.
          </audio>
        </tui-file>

        <tui-file
          *ngFor="let file of rejectedFiles"
          state="error"
          [file]="file"
          [showDelete]="control.enabled"
          (removed)="clearRejected(file)"
        ></tui-file>
      </tui-files>
    </div>
  </div>
</div>

<div *ngIf="!userService?.User?.isSemrushPlan()">
  <div class="relative flex py-5 items-center">
    <div class="flex-grow border-t h-[1px] border-solid border-[#E0E0E0]"></div>
    <span class="flex-shrink mx-4 text-[#E0E0E0]">OR</span>
    <div class="flex-grow border-t h-[1px] border-solid border-[#E0E0E0]"></div>
  </div>

  <tui-notification
    status="warning"
    class="p-4"
    *ngIf="noAccessToMicrophoneMessage"
  >
    <div class="font-bold text-[1rem]">No access to microphone</div>
    <div class="text-[0.875rem]">
      To allow Digital First AI to record your voice, you need to change the settings on your device.
    </div>
  </tui-notification>

  <button
    *ngIf="!isRecording"
    class="mr-4 mt-4 mb-4 block w-full bg-white"
    tuiButton
    appearance="outline"
    type="button"
    (click)="startRecording()"
  >
    <span class="text-[#1B1F3BA6] font-semibold text-[20px]">Record audio</span>
  </button>
  <button
    appearance="outline"
    *ngIf="isRecording"
    class="w-full block bg-[#CE5D5D]"
    tuiButton
    type="button"
    (click)="stopRecording()"
  >
    <img
      src="assets/img/stop-recording.svg"
      alt=""
      class="mr-2"
    /><span class="text-[#FFFFFF] font-semibold text-[20px]">Stop recording</span>
  </button>
</div>
