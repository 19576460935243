import { Component, Input } from '@angular/core';
import { FunnelListTypeEnum, TacticListTypeEnum } from '@modules/graphql/graphql-types';

@Component({
  selector: 'df-header-tabs',
  templateUrl: './header-tabs.component.html',
  styleUrls: ['./header-tabs.component.scss'],
})
export class HeaderTabsComponent {
  @Input() tabs: HeaderTab[] = [];
}

export interface HeaderTab {
  name: string;
  route: string;
  icon?: string;
  type?: TacticListTypeEnum | FunnelListTypeEnum;
  queryParams?: { [key: string]: string };
}
