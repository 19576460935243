import { Component, Input } from '@angular/core';
import { User } from '@shared/models/user.model';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Config } from '@shared/configs/config';

@Component({
  selector: 'df-created-by',
  templateUrl: './created-by.component.html',
  styleUrls: ['./created-by.component.scss'],
})
export class CreatedByComponent {
  Config = Config;

  @Input() user?: User;
  @Input() size: 'xs' | 's' | 'm' | 'l' = 's';

  constructor(private n: NavigateService) {}

  userPreview() {
    if (this.user) {
      this.n.go('users/preview/:id', { id: this.user.id });
    }
  }
}
