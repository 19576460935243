import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'df-iframe',
  templateUrl: './iframe.component.html',
  styleUrl: './iframe.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IframeComponent implements OnInit {
  @Input() funnelId!: number;
  @Input() goalId!: string;

  link = '';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.link = this.getLink();
  }

  private getLink(): string {
    if (this.goalId) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://6aeamwa3qq.eu-west-1.awsapprunner.com/aistrategy-${this.funnelId}?document=aistrategy-${this.funnelId}&funnelId=${this.funnelId}&goalId=goal-${this.goalId}`,
      ) as string;
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://landing.digitalfirst.ai/editor/master/${this.funnelId}`,
    ) as string;
  }
}
