import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { BusinessDataComponent } from '@modules/business-data/business-data.component';
import { BusinessDataService } from '@modules/business-data/business-data.service';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { MarketingCampaignService } from '@modules/marketing-campaign/shared/services/marketing-campaign.service';
import { TranslateService } from '@ngx-translate/core';

import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { UserService } from '@shared/services/user.service';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { TUI_PROMPT, TuiPromptData } from '@taiga-ui/kit';
import { PolymorpheusComponent, PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { Observable, map, retry } from 'rxjs';
import { MarketingFunnelComponent } from './components/marketing-funnel/marketing-funnel.component';
import { FormGroup, FormControl } from '@angular/forms';
import file2Base64 from '@shared/helpers/file-to-base64.helper';
import FileSaver from 'file-saver';
import { MixpanelEventName, MixpanelService } from '@shared/services/mixpanel.service';

export interface Campaign {
  id?: number;
  data: {
    bigIdea: string;
    toneOfVoice: string;
    cta: string;
    problemAwareness: string[];
    solutionHighlight: string[];
    resultEmphasis: string[];
    targetAudience: {
      audience: string;
      communication_channels: string[];
      gains: string[];
      interests: string;
      jobs_to_be_done: string[];
      pains: string[];
      image: string;
    }[];
    contentStrategyPlan: {
      day: string;
      activity: string;
      objective: string;
    }[];
    marketingChannelsAndTactics: {
      channel_name: string;
      description;
    }[];
    campaignStrategy: string;
    campaignExecution: {
      campaign: string;
      description: string;
      slogan: string;
    }[];
    campaignExecutionBanner: {
      banner: string;
    }[];
  };
}

@Component({
  selector: 'df-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignComponent extends AbstractSubscriptionComponent implements OnInit {
  campaign?: Campaign;
  loading = true;
  canvasLoading = true;
  aidaLoading = true;

  readonly tabs = [
    'Business Canvases',
    'Communication',
    'Campaign Key Message',
    'Target audience',
    'Marketing channels & Tactics',
    'Content strategy plan',
    'Campaign strategy',
    'Campaign execution',
    'Marketing funnel',
  ];
  activeElement = String(this.tabs[0]);
  funnelId!: number | null;
  inApp = false;
  dialog!: Observable<void>;

  form = new FormGroup({
    logo: new FormControl(null),
  });

  logoImage = '';
  pdfLoading = false;

  get activeItemIndex(): number {
    return this.tabs.indexOf(this.activeElement);
  }

  @ViewChild(MarketingFunnelComponent)
  marketingFunnelComponent!: MarketingFunnelComponent;

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    private marketingService: MarketingCampaignService,
    private route: ActivatedRoute,
    private n: NavigateService,
    private changes: ChangeDetectorRef,
    private businessDataService: BusinessDataService,
    private userService: UserService,
    private s: SnackbarService,
    private t: TranslateService,
    private funnelGraphqlService: FunnelGraphqlService,
    private mixpanelService: MixpanelService,
  ) {
    super();
  }

  ngOnInit() {
    this.funnelId = this.route.snapshot.params['funnelId']
      ? +this.route.snapshot.params['funnelId']
      : +this.route?.parent?.snapshot.params['id'];
    this.inApp = !!this.userService?.User?.id;

    this.dialog = this.dialogService.open(new PolymorpheusComponent(BusinessDataComponent, this.injector), {
      size: 'page',
      closeable: true,
      dismissible: true,
    });

    if (this.funnelId) {
      this.getCampaning();
    } else {
      this.route.parent?.params.subscribe(() => {
        this.funnelId = +this.route.parent?.snapshot.params['id'];
        this.campaign = undefined;
        this.loading = true;
        this.aidaLoading = true;
        this.canvasLoading = true;

        this.changes.detectChanges();
        this.createNewCampaign();
      });
    }

    location.href.includes('funnels') &&
      this.funnelGraphqlService.setPublicly({ funnelId: this.funnelId!, isPublic: true }).subscribe();
  }

  async logoChange(event: File | null) {
    this.logoImage = event ? await file2Base64(event) : '';
    this.changes.detectChanges();
  }

  showDialog(content: PolymorpheusContent<TuiDialogContext>) {
    this.dialogs.open(content).subscribe();
  }

  closeDialog(observer) {
    this.changes.detectChanges();
    this.savePdf();
    observer.complete();
    this.form.reset();
    this.logoImage = '';
  }

  sendMixpanelEvent(buttonId: string) {
    this.mixpanelService.trackEvent(MixpanelEventName.ButtonClick, {
      time: Date.now(),
      URL: location.href,
      button_name: buttonId,
    });
  }

  createNewCampaign() {
    this.sub.add(
      this.businessDataService
        .getCompanyData(this.funnelId!)
        .pipe(map((res) => res?.data?.getCompanyData))
        .subscribe((res) => {
          if (!res.url) {
            this.dialog.subscribe(() => this.createNewCampaign());
            return;
          }

          this.marketingService
            .createNewCampaign({
              funnelId: this.funnelId,
              businessName: res.businessName,
              url: res.url!,
              email: this.userService!.User!.email,
            })
            .subscribe(() => this.getCampaning());
        }),
    );
  }

  regenerate() {
    this.sub.add(
      this.marketingService.regenerateCampaign(this.funnelId!).subscribe((res) => {
        if (res) window.location.reload();
        else {
          this.s.error(this.t.instant('MarketingCampaign.Sory! This feature only works for newly generated campaigns'));
        }
      }),
    );
  }

  showRegenerateConfirmationDialog() {
    const data: TuiPromptData = {
      content: 'Are you sure you want to create a new campaign? You will lose current strategy',
      yes: 'Yes',
      no: 'No',
    };

    this.dialogs
      .open<boolean>(TUI_PROMPT, {
        label: 'Warning!',
        size: 's',
        data,
      })
      .subscribe((res) => (res ? this.regenerate() : null));
  }

  arrowClick(arrow: 'left' | 'right') {
    if (arrow === 'left') {
      if (this.activeItemIndex - 1 < 0) {
        return (this.activeElement = this.tabs[this.tabs.length - 1]);
      }

      this.activeElement = this.tabs[this.activeItemIndex - 1];
    } else {
      if (this.activeItemIndex + 1 > this.tabs.length - 1) {
        return (this.activeElement = this.tabs[0]);
      }
      this.activeElement = this.tabs[this.activeItemIndex + 1];
    }
  }

  async savePdf() {
    this.pdfLoading = true;
    this.changes.detectChanges();

    this.marketingService.generatePDF(this.funnelId!, this.logoImage).subscribe((res) => {
      this.pdfLoading = false;
      this.changes.detectChanges();

      if (!res) {
        this.s.error('Something went wrong. Please try again');
        return;
      }

      FileSaver.saveAs(res!.data!.aiStrategyGeneratePDF, 'ai-strategy.pdf');
    });
  }

  goToRegister() {
    this.n.go('/sign-up');
  }

  canvasComponentLoad() {
    this.canvasLoading = false;
    this.changes.detectChanges();
  }

  aidaComponentLoad() {
    this.aidaLoading = false;
    this.changes.detectChanges();
  }

  onClickTab(activeElement: string): void {
    this.activeElement = activeElement;

    if (!this.inApp && activeElement === 'Marketing funnel') {
      this.marketingFunnelComponent.showDialogIfNoRecommendations();
    }
  }

  getCampaning() {
    this.marketingService
      .getCampaign(this.funnelId!)
      .pipe(retry({ delay: 20000 }))
      .subscribe((res) => {
        this.campaign = res as Campaign;
        this.loading = false;
        this.changes.detectChanges();
      });
  }
}
