<div class="w-full bg-[#F2F3F5] h-full overflow-y-scroll">
  <div class="sticky top-0 z-50">
    <header class="h-[90px] bg-white flex flex-1 justify-between items-center px-5">
      <img
        *ngIf="!inApp"
        src="/assets/img/Logo-dark.svg"
        alt=""
      />
      <h1
        *ngIf="!inApp"
        class="tui-text_1 text-[#191C30E5] font-bold tui-text-03 text-[20px] translate-x-[50%] hidden xl:block"
      >
        {{ 'MarketingCampaign.Campaign strategy results' | translate }}
      </h1>
      <div
        class="fixed justify-between ml-auto w-full py-2 px-4 bottom-0 left-0 flex md:relative md:block bg-white controls md:w-auto"
      >
        <button
          (click)="sendMixpanelEvent('print')"
          tuiButton
          appearance="icon"
          class="hidden md:inline-block text-[#1B1F3BA6] text-[1rem] font-semibold"
          [printStyle]="{
            html: { overflow: 'initial !important' },
            body: { overflow: 'initial !important' }
          }"
          printSectionId="print-section"
          [useExistingCss]="true"
          ngxPrint
        >
          <tui-svg
            class="mr-2"
            src="tuiIconPrinterLarge"
          ></tui-svg>
          <span class="hidden md:block"> {{ 'MarketingCampaign.Print' | translate }}</span>
        </button>
        <button
          [showLoader]="pdfLoading"
          [disabled]="pdfLoading"
          (click)="showDialog(logo); sendMixpanelEvent('download')"
          tuiButton
          appearance="icon"
          class="text-[#1B1F3BA6] text-[1rem] font-semibold"
        >
          <tui-svg
            class="mr-2"
            src="tuiIconDownloadLarge"
          ></tui-svg>
          <span class="hidden md:block"> {{ 'MarketingCampaign.Download' | translate }}</span>
        </button>
        <button
          (click)="showRegenerateConfirmationDialog(); sendMixpanelEvent('regenerate')"
          tuiButton
          appearance="icon"
          class="text-[#1B1F3BA6] text-[1rem] font-semibold"
        >
          <tui-svg
            class="mr-2"
            src="tuiIconRefreshCwLarge"
          ></tui-svg>
          <span class="hidden md:block"> {{ 'MarketingCampaign.Regenerate' | translate }}</span>
        </button>
        <button
          *ngIf="!inApp"
          tuiButton
          (click)="goToRegister()"
        >
          <tui-svg
            class="mr-2"
            src="tuiIconZapLarge"
          ></tui-svg>
          <span> {{ 'MarketingCampaign.Start your campaign' | translate }}</span>
        </button>
      </div>
    </header>
  </div>

  <section class="bg-[#F2F3F5] w-full p-8 px-12 md:px-28 pt-8 relative">
    <div class="pt-14 mb-10 bg-[#F2F3F5] overflow-x-scroll">
      <tui-tabs
        class="tabs flex justify-around min-w-fit max-w-full"
        [activeItemIndex]="activeItemIndex"
      >
        <ng-container *ngFor="let tab of tabs">
          <button
            *ngIf="tab === 'Business Canvases'"
            [ngClass]="{ loading: canvasLoading }"
            tuiTab
            (click)="onClickTab(tab)"
          >
            {{ tab }}<span></span>
          </button>
          <button
            *ngIf="tab === 'Communication'"
            [ngClass]="{ loading: canvasLoading }"
            tuiTab
            (click)="onClickTab(tab)"
          >
            {{ tab }}<span></span>
          </button>
          <button
            *ngIf="!['Business Canvases', 'Communication'].includes(tab)"
            [ngClass]="{ loading: loading }"
            tuiTab
            (click)="onClickTab(tab)"
          >
            {{ tab }}<span></span>
          </button>
        </ng-container>
      </tui-tabs>
    </div>
    <div class="flex justify-center min-h-[calc(100vh-300px)]">
      <div class="w-full flex flex-col">
        <df-campaign-canvas
          (loadedSuccess)="canvasComponentLoad()"
          [campaignLoading]="loading"
          *ngIf="activeItemIndex === 0"
          class="mb-8"
        ></df-campaign-canvas>
        <df-aida
          (loadedSuccess)="aidaComponentLoad()"
          [campaignLoading]="loading"
          *ngIf="activeItemIndex === 1"
          class="w-full"
        ></df-aida>
        <df-campaign-key-message
          [campaign]="campaign"
          *ngIf="activeItemIndex === 2 && campaign"
        ></df-campaign-key-message>
        <df-campaign-target-audience
          [campaign]="campaign"
          *ngIf="activeItemIndex === 3 && campaign"
        ></df-campaign-target-audience>
        <df-campaign-marketing-channels-tactics
          [campaign]="campaign"
          *ngIf="activeItemIndex === 4 && campaign"
        ></df-campaign-marketing-channels-tactics>
        <df-campaign-content-strategy-plan
          [campaign]="campaign"
          *ngIf="activeItemIndex === 5 && campaign"
        ></df-campaign-content-strategy-plan>
        <df-campaign-strategy
          [campaign]="campaign"
          *ngIf="activeItemIndex === 6 && campaign"
        ></df-campaign-strategy>
        <df-campaign-execution
          [campaign]="campaign"
          *ngIf="activeItemIndex === 7 && campaign"
        ></df-campaign-execution>
        <df-marketing-funnel [style.display]="activeItemIndex === 8 ? 'block' : 'none'"></df-marketing-funnel>
      </div>
    </div>

    <tui-svg
      (click)="arrowClick('left')"
      [class]="
        inApp
          ? 'fixed top-[50%] text-[#1B1F3BA6] left-[15px] md:left-[210px] lg:left-[284px]'
          : 'fixed top-[50%] text-[#1B1F3BA6] left-[20px]'
      "
      src="tuiIconArrowLeftLarge"
    ></tui-svg>
    <tui-svg
      (click)="arrowClick('right')"
      class="fixed top-[50%] right-[20px] text-[#1B1F3BA6]"
      src="tuiIconArrowRightLarge"
    ></tui-svg>
  </section>
</div>

<!-- Print -->
<div
  class="w-auto flex justify-center fixed top-[99999rem]"
  *ngIf="campaign"
  id="print-section"
>
  <div class="flex flex-col">
    <div class="flex justify-between mb-8 mt-8 px-8">
      <img
        class="max-w-[150px]"
        *ngIf="logoImage"
        [src]="'data:image/png;base64,' + logoImage"
        alt=""
      />
      <div>
        Powered by
        <img
          class="max-w-[150px]"
          src="/assets/img/Logo-dark.svg"
          alt=""
        />
      </div>
    </div>

    <df-campaign-canvas
      [campaignLoading]="loading"
      class="mb-8 mt-8"
    ></df-campaign-canvas>
    <df-aida
      [campaignLoading]="loading"
      class="w-full mb-8"
    ></df-aida>
    <df-campaign-key-message
      [campaign]="campaign"
      class="mb-8 mt-8 px-8"
    ></df-campaign-key-message>
    <df-campaign-target-audience
      [campaign]="campaign"
      class="mb-8 mt-8 px-8"
    ></df-campaign-target-audience>
    <df-campaign-marketing-channels-tactics
      [campaign]="campaign"
      class="mb-8 mt-8 px-8"
    ></df-campaign-marketing-channels-tactics>
    <df-campaign-content-strategy-plan
      [campaign]="campaign"
      class="mb-8 px-8"
    ></df-campaign-content-strategy-plan>
    <df-campaign-strategy
      [campaign]="campaign"
      class="mb-8 mt-8 px-8"
    ></df-campaign-strategy>
    <df-campaign-execution
      [campaign]="campaign"
      class="mb-8 mt-8 px-8"
    ></df-campaign-execution>
    <df-marketing-funnel class="mb-8 mt-8 px-8"></df-marketing-funnel>
  </div>
</div>

<df-onboarding-video [src]="'https://www.youtube.com/embed/2pz0xVtJNGg?si=uMwF3S7KDEtF2n58'"></df-onboarding-video>

<ng-template
  #logo
  let-observer
>
  <div class="w-full mt-3 mb-6">
    <h2 class="text-[36px] font-bold text-center mb-8">Generate PDF with strategy</h2>
    <form
      [formGroup]="form"
      class="lg:w-5/12"
    >
      <p class="uppercase tui-text-04 tui-text_body-xs font-bold mb-2">
        {{ 'Funnels.Manage.Company logo' | translate }}
      </p>
      <df-input-file
        (ngModelChange)="logoChange($event)"
        #logoInput
        [maxFileSize]="10000000"
        [link]="'choose an image' | translate"
        [formName]="'logo'"
        [form]="form"
      ></df-input-file>
    </form>

    <button
      class="mt-2"
      size="m"
      tuiButton
      type="button"
      (click)="closeDialog(observer)"
    >
      Generate
    </button>
  </div>
</ng-template>
