import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Regex } from '@shared/configs/regex';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { BusinessDataService } from '@modules/business-data/business-data.service';

@Component({
  selector: 'df-business-data-dialog',
  templateUrl: './business-data-dialog.component.html',
  styleUrl: './business-data-dialog.component.scss',
})
export class BusinessDataDialogComponent {
  isLoading = false;

  readonly formGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(Regex.email)]),
  });

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { funnelId: number } }, void>,
    private readonly businessDataService: BusinessDataService,
  ) {}

  private get funnelId(): number {
    return this.context.data?.funnelId ?? '';
  }

  onSubmitData() {
    const { value, invalid } = this.formGroup;
    this.formGroup.markAllAsTouched();

    if (invalid) {
      return;
    }

    const body = {
      funnelId: this.funnelId,
      email: value?.email as string,
    };
    this.isLoading = true;

    this.businessDataService.saveDemoBusinessData(body).subscribe({
      next: () => {
        this.isLoading = false;
        this.context.completeWith();
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  getControl(name: string) {
    return this.formGroup.get(name);
  }
}
