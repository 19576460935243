import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigateService } from '@core/routes/services/navigate.service';
import { ActivatedRoute } from '@angular/router';
import { BusinessGoalsService } from '@modules/business-goals/business-goals.service';
import { SnackbarService } from '@core/services/snackbar.service';

@Component({
  selector: 'df-business-goals',
  templateUrl: './business-goals.component.html',
  styleUrl: './business-goals.component.scss',
})
export class BusinessGoalsComponent {
  funnelId = null;

  readonly goalControl = new FormControl(1);
  readonly goals = [
    {
      value: 1,
      label: 'I want to innovate and introduce new products',
      color: 'green',
    },
    {
      value: 2,
      label: 'I want to enter the Brazilian market',
      color: 'purple',
    },
    {
      value: 3,
      label: 'I want to acquire new users',
      color: 'pink',
    },
    {
      value: 4,
      label: 'I want to get 100 customers  within the next quarter',
      color: 'orange',
    },
  ];

  constructor(
    private readonly n: NavigateService,
    private route: ActivatedRoute,
    private businessGoalsService: BusinessGoalsService,
    private s: SnackbarService,
  ) {
    this.funnelId = this.route?.parent?.snapshot.params['id'];
  }

  onNextButtonClick() {
    const goalId = this.goalControl.value;

    this.businessGoalsService
      .syncTiptapTemplate({
        funnelId: Number(this.funnelId),
        goalId: `goal-${goalId}`,
      })
      .subscribe((response) => {
        if (response) {
          this.n.go(`/funnels/f/d/${this.funnelId}/tiptap`, { id: this.funnelId }, { queryParams: { goalId } });
        } else {
          this.s.error('Cannot sync TipTap template!');
        }
      });
  }
}
