import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MemoryLogService } from '@shared/services/memory-log.service';

@Component({
  selector: 'df-memory-log',
  templateUrl: './memory-log.component.html',
  styleUrl: './memory-log.component.scss',
})
export class MemoryLogComponent implements OnInit, OnDestroy {
  @Input() funnelId!: number;

  isOpen = true;
  memoryLogs: string[] = [];
  counter = 0;
  completed = false;

  constructor(
    private memoryLogService: MemoryLogService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.memoryLogService.connect(this.funnelId);
    this.memoryLogService.watch(this.funnelId).subscribe((mess: string) => {
      this.memoryLogs.push(mess);

      this.completed = mess === 'Process finished';

      if (!this.isOpen) {
        this.counter += 1;
      }

      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.memoryLogService.disconnect(this.funnelId);
  }

  toggle() {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.counter = 0;
    }
  }
}
