import { SanitizeHtmlPipe } from '@shared/pipes/sanitize-html.pipe';
import { PricePipe } from '@shared/pipes/price.pipe';
import { SanitizeResourceUrlPipe } from '@shared/pipes/sanitize-resource-url.pipe';
import { FirstLetterUppercasePipe } from '@shared/pipes/first-letter-uppercase.pipe';
import { OrPipe } from '@shared/pipes/or.pipe';
import { AssetsFilePipe } from '@shared/pipes/assets-file.pipe';
import { ParagraphPipe } from '@shared/pipes/paragraph.pipe';
import { RoundPipe } from '@shared/pipes/round.pipe';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { LinkRemoverPipe } from '@shared/pipes/link-remover.pipe';

export const SHARED_PIPES = [
  SanitizeHtmlPipe,
  PricePipe,
  SanitizeResourceUrlPipe,
  FirstLetterUppercasePipe,
  OrPipe,
  AssetsFilePipe,
  ParagraphPipe,
  RoundPipe,
  SafeHtmlPipe,
  LinkRemoverPipe,
];
