import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'df-business-flow-header',
  templateUrl: './business-flow-header.component.html',
  styleUrl: './business-flow-header.component.scss',
})
export class BusinessFlowHeaderComponent {
  @Input() activeLink = null;

  @Output() onNextButtonClick = new EventEmitter<void>();

  flowLinks: { link: string; label: string }[] = [];

  private readonly funnelId;

  constructor(private route: ActivatedRoute) {
    this.funnelId = +this.route?.parent?.snapshot.params['id'];
    this.initFlowLinks();
  }

  get isLastStep(): boolean {
    const lastRouteUrl = this.flowLinks.length ? this.flowLinks[this.flowLinks.length - 1].link : '';

    if (lastRouteUrl) {
      return window.location.href.includes(lastRouteUrl);
    }

    return false;
  }

  onBackClick() {
    history.back();
  }

  private initFlowLinks() {
    this.flowLinks = [
      {
        link: `/funnels/f/d/${this.funnelId}/business-data`,
        label: 'Company research',
      },
      {
        link: `/funnels/f/d/${this.funnelId}/business-goals`,
        label: 'Business goals',
      },
      {
        link: `/funnels/f/d/${this.funnelId}/tiptap`,
        label: 'Go-to market strategy',
      },
      {
        link: `/funnels/f/d/${this.funnelId}/master-visuals`,
        label: 'Master visuals',
      },
      {
        link: `/funnels/f/d/${this.funnelId}/campaign`,
        label: 'Campaign',
      },
    ];
  }
}
