import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MemoryLogService {
  constructor(private socket: Socket) {}

  connect(funnelId: number) {
    this.socket.connect();
    this.socket.emit('subscribe', `funnel:${funnelId}`);
  }

  disconnect(funnelId: number): void {
    this.socket.emit('unsubscribe', `funnel:${funnelId}`);
  }

  watch(funnelId: number): Observable<string> {
    return this.socket.fromEvent(`funnel:${funnelId}`);
  }
}
