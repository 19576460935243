import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { GetRecommenderItemsDocument } from './graphql/queries/recomender.query';
import { GetRecommenderItemsQuery } from './graphql/queries/recomender.query.generated';
import { catchError, map, of } from 'rxjs';
import { RecommenderOutputGraphql } from '@modules/graphql/graphql-types';
import { BusinessDataService } from '@modules/business-data/business-data.service';
import * as Sentry from '@sentry/browser';
import { BaseStorageService } from '@core/services/base-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PublicRecommenderService extends BaseStorageService {
  form!: FormGroup;

  constructor(
    private apollo: Apollo,
    private businessDataService: BusinessDataService,
  ) {
    super();
  }

  initForm() {
    this.form = new FormGroup({
      answer: new FormControl(null, Validators.required),
    });

    return this.form;
  }

  setDataToLocalStorage(url: string) {}

  getRecommenderItems() {
    return this.apollo
      .query<GetRecommenderItemsQuery>({
        query: GetRecommenderItemsDocument,
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res.data.getRecommenderItems as RecommenderOutputGraphql[]));
  }

  setCompanyData(businessName: string, url: string, funnelId: number) {
    return this.businessDataService.setCompanyData(businessName, url, funnelId).pipe(
      catchError((err) => {
        Sentry.captureException(err);
        return of(false);
      }),
    );
  }
}
