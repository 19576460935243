<section class="w-full h-screen flex flex-col">
  <df-business-flow-header (onNextButtonClick)="onNextButtonClick()"></df-business-flow-header>

  <div class="w-full h-full overflow-y-auto">
    <df-iframe
      [funnelId]="funnelId"
      class="w-full h-full"
    ></df-iframe>
  </div>
</section>
