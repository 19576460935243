<div *ngFor="let tab of tabs">
  <a
    [routerLink]="tab.route"
    [queryParams]="tab.queryParams"
    routerLinkActive="active"
  >
    <svg-icon
      [src]="tab.icon"
      *ngIf="tab.icon"
      class="mr-4"
    ></svg-icon>
    {{ tab.name }}
  </a>
</div>
