import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { FunnelFlowManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-flow-manage.service';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { Observable } from 'rxjs';
import { FunnelTab } from '@modules/funnels/shared/enums/funnel-tab.enum';
import { Step } from '@shared/models/step.model';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import { FunnelManageTacticActivitiesComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/components/funnel-manage-tactic/components/funnel-manage-tactic-activities/funnel-manage-tactic-activities.component';
import { FunnelPermissionEnum } from '@modules/graphql/graphql-types';
import { OnboardingService } from '@shared/services/onboarding.service';
import { ActivatedRoute } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'df-funnel-manage-tactic',
  templateUrl: './funnel-manage-tactic.component.html',
  styleUrls: ['./funnel-manage-tactic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelManageTacticComponent extends AbstractSubscriptionComponent implements OnInit {
  canManage$: Observable<boolean>;
  tacticPreviewOpen = false;

  @Output() tacticPreviewOpenEmit = new EventEmitter<boolean>();

  @Input() funnelTactic!: FunnelTactic;
  @Input() step!: Step;
  @Input() shouldRedirect = true;

  @ViewChild('activities')
  funnelManageTacticActivities!: FunnelManageTacticActivitiesComponent;

  @HostBinding('class.editMode')
  get isEditMode() {
    return this.funnelFlowManageService.editMode;
  }

  get cannotEdit() {
    return this.funnelManageService?.funnel?.currentUserPermission !== FunnelPermissionEnum.Editor;
  }

  constructor(
    public funnelManageService: FunnelManageService,
    private changes: ChangeDetectorRef,
    public funnelFlowManageService: FunnelFlowManageService,
    private onboardingService: OnboardingService,
    private route: ActivatedRoute,
    private n: NavigateService,
  ) {
    super();
    this.canManage$ = this.funnelManageService.isSelectedTab(FunnelTab.MANAGE);
  }

  ngOnInit(): void {
    this.listenSearchChanges();

    if (this.shouldRedirect) {
      this.showTacticWithRoutParam();
    }
  }

  private listenSearchChanges(): void {
    this.sub.add(
      this.funnelManageService.searchFunnelTactic.valueChanges.subscribe(() => this.changes.detectChanges()),
    );
  }

  showTacticWithRoutParam() {
    const id = this.route.snapshot.queryParamMap.get('showTactic');
    this.n.go('funnels/f/d/:id/manage', {
      id: this.funnelManageService?.funnel?.id,
    });

    if (!id) return;
    if (+id !== this.funnelTactic.tactic.id) return;

    this.toggleTacticPreview(true);
  }

  toggleTacticPreview(open: boolean) {
    if (this.funnelFlowManageService.editMode || this.onboardingService.onboardingRunning) return;
    this.tacticPreviewOpen = open;
    this.tacticPreviewOpenEmit.emit(open);
    this.changes.detectChanges();
  }

  detectChanges(funnelTactic: FunnelTactic): void {
    this.funnelTactic = funnelTactic;
    this.changes.detectChanges();
    this.funnelManageTacticActivities.changes.detectChanges();
  }
}
