import { ChangeDetectorRef, Component } from '@angular/core';
import { AuthOutputGraphql } from '@modules/graphql/graphql-types';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Regex } from '@shared/configs/regex';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthGraphqlService } from '@modules/authorization/shared/graphql/auth.graphql.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { TuiDialogService } from '@taiga-ui/core';
import { FetchResult } from '@apollo/client/core';
import { LoginMutation } from '@modules/authorization/shared/graphql/mutations/login.mutation.generated';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ForgotPasswordComponent } from '@modules/authorization/pages/forgot-password/forgot-password.component';
import { Observable } from 'rxjs';
import { Config } from '@shared/configs/config';
import { AbstractAuthComponent } from '@modules/authorization/shared/abstracts/abstract-auth.component';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';
import { User } from '@shared/models/user.model';

@Component({ template: '' })
export abstract class AbstractLoginComponent extends AbstractAuthComponent {
  protected router: Router;
  protected authGraphqlService: AuthGraphqlService;
  protected readonly dialogService: TuiDialogService;
  protected eventService: EventsService;

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.email)]),
    password: new UntypedFormControl('', [Validators.required]),
    rememberMe: new UntypedFormControl(false),
  });

  protected constructor(
    changes: ChangeDetectorRef,
    recaptchaService: ReCaptchaV3Service,
    protected route: ActivatedRoute,
  ) {
    super(changes, recaptchaService);
    this.router = this.injector.get(Router);
    this.authGraphqlService = this.injector.get(AuthGraphqlService);
    this.dialogService = this.injector.get(TuiDialogService);
    this.eventService = this.injector.get(EventsService);
  }

  signIn() {
    this.authController.getCaptcha().subscribe((token: string | null) => {
      if (token) {
        this.signInHandler(token);
      }
    });
  }

  forgotPassword() {
    this.dialogService
      .open<number>(new PolymorpheusComponent(ForgotPasswordComponent, this.injector), {
        data: {
          email: this.formGroup.get('email')?.valid ? this.formGroup.get('email')?.value : '',
        },
        dismissible: true,
        label: this.t.instant('Auth.Forgot Password.title'),
      })
      .subscribe();
  }

  protected signInHandler(token: string) {
    if (!this.formGroup.valid) return;
    this.loading = true;
    this.getLoginRequest(token)
      .subscribe({
        next: this.successSignIn.bind(this),
        error: this.errorSignIn.bind(this),
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  protected successSignIn(res: FetchResult<LoginMutation>) {
    this.s.success(this.t.instant("Auth.Login.You're logged. Welcome my lord!"));
    this.authController.saveAuthData(res.data?.login as AuthOutputGraphql);
    this.eventService.pushToGoogleAnalyticsEvent(
      res.data?.login.eventType === 'sign-in' ? GoogleAnalyticsEvent.Signin : GoogleAnalyticsEvent.Signup,
      {
        userID: res.data?.login.user?.id,
        email: res.data?.login.user?.email,
      },
    );
    this.eventService.setUpIntercome(res.data?.login.user as User);

    try {
      this.eventService.pushToSmartlookEvent(res.data!.login.user!.id, {
        email: res.data!.login.user!.email!,
      });
    } catch (e) {
      console.log(e);
    }
  }

  protected redirectAfterSign() {
    this.statementService.openStatementOnboarding = true;
    this.n.go(Config.MAIN_VIEW);
  }

  protected abstract getLoginRequest(token: string): Observable<FetchResult<LoginMutation>>;
}
