<section class="w-full h-screen flex flex-col">
  <df-business-flow-header (onNextButtonClick)="onNextButtonClick()"> </df-business-flow-header>

  <div class="bg-[#f8faff] flex flex-col h-full items-center overflow-y-auto">
    <div class="my-16 max-w-[670px] w-full">
      <h3 class="text-black font-semibold mb-4 text-[32px] leading-[150%] mx-auto text-center">
        Select a goal for your business
      </h3>
      <p class="mb-12 text-center text-[20px] leading-[150%] text-[#191C30E5] mx-auto">
        Based on your input, here are some recommended goals.
      </p>

      <div
        *ngFor="let goal of goals"
        class="goal"
        [ngClass]="goal.color"
      >
        <div class="flex items-center">
          <div class="star">
            <svg-icon [src]="'/assets/img/ic_star.svg'"></svg-icon>
          </div>
          <span class="label">
            {{ goal.label }}
          </span>
        </div>
        <tui-radio
          [formControl]="goalControl"
          size="l"
          [item]="goal.value"
        >
        </tui-radio>
      </div>
    </div>
  </div>
</section>
