import { FunnelManageCanActivateGuard } from '@modules/funnels/modules/funnel-manage/guards/funnel-manage-can-activate.guard';
import { FunnelManageComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/funnel-manage.component';
import { FunnelManageHeaderComponent } from '@modules/funnels/modules/funnel-manage/shared/components/funnel-manage-header/funnel-manage-header.component';
import { FunnelsListCanActivateGuard } from '@modules/funnels/modules/funnels-list/guards/funnels-list-can-activate.guard';
import { FunnelsListHeaderComponent } from '@modules/funnels/modules/funnels-list/shared/components/funnels-list-header/funnels-list-header.component';
import { FunnelsListPanelComponent } from '@modules/funnels/modules/funnels-list/pages/funnels-list-panel/funnels-list-panel.component';
import { FunnelListTypeEnum } from '@modules/graphql/graphql-types';
import { FunnelsTilesComponent } from '@modules/funnels/modules/funnels-list/pages/funnels-tiles/funnels-tiles.component';
import { FunnelViewMode } from '@modules/funnels/shared/enums/funnel-view-mode.enum';
import { FunnelPanelComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/funnel-panel.component';
import { FunnelTab } from '@modules/funnels/shared/enums/funnel-tab.enum';
import { FunnelOverviewComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-overview/funnel-overview.component';
import { FunnelCanvasesComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-canvases/funnel-canvases.component';
import { FunnelStatementComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-statement/funnel-statement.component';
import { FunnelRecommendationsComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-recommendations/funnel-recommendations.component';
import { FunnelContentGeneratorComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-content-generator/funnel-content-generator.component';
import { FunnelContentGenerateCanActivateGuard } from '@modules/funnels/modules/funnel-manage/guards/funnel-content-generate-can-activate.guard';
import { FunnelGraphicsComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-graphics/funnel-graphics.component';
import { FunnelGraphicsCanDeactivateGuard } from '@modules/funnels/modules/funnel-manage/guards/funnel-graphics-can-deactivate.guard';
import { FunnelSettingsComponent } from '@modules/funnels/modules/funnel-settings/pages/funnel-settings/funnel-settings.component';
import { FunnelSettingsHeaderComponent } from '@modules/funnels/modules/funnel-settings/shared/components/funnel-settings-header/funnel-settings-header.component';
import { FunnelSettingsViewModeEnum } from '@modules/funnels/modules/funnel-settings/shared/enums/funnel-settings-view-mode.enum';
import { FunnelSettingsCanDeactivateGuard } from '@modules/funnels/modules/funnel-settings/guards/funnel-settings-can-deactivate.guard';
import { OnboardingCanActivateGuard } from '@shared/guards/onboarding-can-activate.guard';
import { StatementFormStep1Component } from '@modules/statement/pages/statement-form-step1/statement-form-step1.component';
import { StatementFormStep2Component } from '@modules/statement/pages/statement-form-step2/statement-form-step2.component';
import { StatementFormStep3Component } from '@modules/statement/pages/statement-form-step3/statement-form-step3.component';
import { StatementNextStepInFunnelGuard } from '@modules/statement/guards/statement-next-step-in-funnel.guard';
import { FunnelContentGeneratorLibraryComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-content-generator-library/funnel-content-generator-library.component';
import { FunnelStatisticsComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-statistics/funnel-statistics.component';
import { FunnelStatisticsAddNewReportIntroComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-statistics/components/funnel-statistics-add-new-report-intro/funnel-statistics-add-new-report-intro.component';
import { FunnelStatisticsReportListComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-statistics/components/funnel-statistics-report-list/funnel-statistics-report-list.component';
import { Routes } from '@angular/router';
import { BusinessDataComponent } from '@modules/business-data/business-data.component';
import { FormNotDirtyCanDeactivateGuard } from '@shared/guards/form-not-dirty.guard';
import { ContentCalendarComponent } from '@modules/ayrshare/pages/content-calendar/content-calendar.component';

import { CampaignComponent } from '@modules/marketing-campaign/pages/campaign/campaign.component';
import { AssetsComponent } from '@modules/assets/assets/assets.component';
import { BusinessGoalsComponent } from '@modules/business-goals/business-goals.component';
import { TiptapComponent } from '@modules/tiptap/tiptap.component';
import { MasterVisualsComponent } from '@modules/master-visuals/master-visuals.component';
import { CampaignComponent as BusinessCampaignComponent } from '@modules/campaign/campaign.component';

export const FunnelsRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/funnels/list/all',
  },
  {
    path: 'manage/:id',
    pathMatch: 'full',
    redirectTo: 'f/:id',
  },
  {
    path: 'f',
    loadChildren: () =>
      import('@modules/funnels/modules/funnel-manage/funnel-manage.module').then((m) => m.FunnelManageModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('@modules/funnels/modules/funnel-settings/funnel-settings.module').then((m) => m.FunnelSettingsModule),
  },
  {
    path: 'list',
    loadChildren: () =>
      import('@modules/funnels/modules/funnels-list/funnels-list.module').then((m) => m.FunnelsListModule),
    canActivate: [FunnelsListCanActivateGuard],
  },
];

export const FunnelsListRoutes: Routes = [
  {
    path: '',
    component: FunnelsListPanelComponent,
    data: {
      metaTitle: 'Funnels.funnels list',
      viewTitle: 'Funnels.List.Funnels',
      headerComponent: FunnelsListHeaderComponent,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/funnels/list/all',
      },
      {
        path: 'all',
        component: FunnelsTilesComponent,
        data: {
          metaTitle: 'Funnels.all funnels',
          viewTitle: 'Funnels.List.Funnels',
          type: FunnelListTypeEnum.All,
          headerComponent: FunnelsListHeaderComponent,
        },
      },
      {
        path: 'favourites',
        component: FunnelsTilesComponent,
        data: {
          metaTitle: 'Funnels.favourites',
          viewTitle: 'Funnels.List.Funnels',
          type: FunnelListTypeEnum.Favourite,
          headerComponent: FunnelsListHeaderComponent,
        },
      },
      {
        path: 'shared-for-me',
        component: FunnelsTilesComponent,
        data: {
          metaTitle: 'Funnels.shared for me',
          viewTitle: 'Funnels.List.Funnels',
          type: FunnelListTypeEnum.SharedForMe,
          headerComponent: FunnelsListHeaderComponent,
        },
      },
    ],
  },
];

export const FunnelSettingsRoutes = [
  {
    path: 'create',
    component: FunnelSettingsComponent,
    canDeactivate: [FunnelSettingsCanDeactivateGuard],
    data: {
      metaTitle: 'Funnels.create funnel',
      viewTitle: 'Funnels.Settings.create title',
      shortLeftNavigation: false,
      headerComponent: FunnelSettingsHeaderComponent,
      type: FunnelSettingsViewModeEnum.CREATE,
    },
  },
  {
    path: ':id',
    component: FunnelSettingsComponent,
    canDeactivate: [FunnelSettingsCanDeactivateGuard],
    data: {
      metaTitle: 'Funnels.settings',
      viewTitle: 'Funnels.Settings.title',
      shortLeftNavigation: false,
      headerComponent: FunnelSettingsHeaderComponent,
      type: FunnelSettingsViewModeEnum.EDIT,
    },
  },
];

export const FunnelManageRoutes: Routes = [
  {
    path: 'promo/:slug',
    component: FunnelPanelComponent,
    data: {
      metaTitle: 'Funnels.funnel',
      shortLeftNavigation: false,
      headerComponent: FunnelManageHeaderComponent,
      type: FunnelViewMode.PROMOTION,
    },
    children: [
      {
        path: '',
        component: FunnelManageComponent,
        data: {
          metaTitle: 'Funnels.promo funnel',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.PROMOTION,
        },
      },
    ],
  },
  {
    path: 'shared/:id',
    component: FunnelPanelComponent,
    data: {
      metaTitle: 'Funnels.funnel',
      shortLeftNavigation: false,
      headerComponent: FunnelManageHeaderComponent,
      type: FunnelViewMode.SHARED,
    },
    children: [
      {
        path: '',
        component: FunnelManageComponent,
        data: {
          metaTitle: 'Funnels.shared funnel',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.SHARED,
        },
      },
    ],
  },
  {
    path: 'onboarding',
    component: FunnelPanelComponent,
    data: {
      metaTitle: 'Funnels.funnel',
      shortLeftNavigation: false,
      headerComponent: FunnelManageHeaderComponent,
      type: FunnelViewMode.ONBOARDING,
    },
    canActivate: [OnboardingCanActivateGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'manage' },
      {
        path: '',
        component: FunnelManageComponent,
        data: {
          metaTitle: 'Funnels.manage funnel',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.MANAGE,
        },
      },
      {
        path: 'content-generator',
        component: FunnelContentGeneratorComponent,
        canActivate: [FunnelContentGenerateCanActivateGuard],
        data: {
          metaTitle: 'Funnels.content generator',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.CONTENT_GENERATE,
        },
      },
    ],
  },
  {
    path: 'd/:id',
    component: FunnelPanelComponent,
    data: {
      metaTitle: 'Funnels.funnel',
      shortLeftNavigation: false,
      hideLeftNavigation: true,
      hideHeader: true,
      headerComponent: FunnelManageHeaderComponent,
      type: FunnelViewMode.DETAILS,
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'manage' },
      {
        path: 'overview',
        component: FunnelOverviewComponent,
        data: {
          metaTitle: 'Funnels.overview',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.OVERVIEW,
        },
      },
      {
        path: 'manage',
        component: FunnelManageComponent,
        canActivate: [FunnelManageCanActivateGuard],
        data: {
          metaTitle: 'Funnels.manage funnel',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.MANAGE,
        },
      },
      {
        path: 'preview',
        component: FunnelManageComponent,
        data: {
          metaTitle: 'Funnels.preview funnel',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.PREVIEW,
        },
      },
      {
        path: 'business-data',
        component: BusinessDataComponent,
        data: {
          metaTitle: 'BusinessData.Title',
          shortLeftNavigation: false,
          hideHeader: true,
          hideLeftNavigation: true,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.CANVASES,
        },
      },
      {
        path: 'business-goals',
        component: BusinessGoalsComponent,
        data: {
          metaTitle: 'Business Goals',
          shortLeftNavigation: false,
          hideHeader: true,
          hideLeftNavigation: true,
        },
      },
      {
        path: 'tiptap',
        component: TiptapComponent,
        data: {
          metaTitle: 'Go-to market strategy',
          shortLeftNavigation: false,
          hideHeader: true,
          hideLeftNavigation: true,
        },
      },
      {
        path: 'master-visuals',
        component: MasterVisualsComponent,
        data: {
          metaTitle: 'Master Visuals',
          shortLeftNavigation: false,
          hideHeader: true,
          hideLeftNavigation: true,
        },
      },
      {
        path: 'campaign',
        component: BusinessCampaignComponent,
        data: {
          metaTitle: 'Campaign',
          shortLeftNavigation: false,
          hideHeader: true,
          hideLeftNavigation: true,
        },
      },
      {
        path: 'content-calendar',
        component: ContentCalendarComponent,
        data: {
          metaTitle: 'Funnels.Content Calendar',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.CONTENT_CALENDAR,
        },
      },
      {
        path: 'assets-library',
        component: AssetsComponent,
        data: {
          metaTitle: 'Funnels.Assets',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.ASSETS_LIBRARY,
        },
      },
      {
        path: 'marketing-campaign/campaign',
        component: CampaignComponent,
        data: {
          metaTitle: 'Funnels.AI Strategy',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.AI_STRATEGY,
        },
      },
      {
        path: 'canvases',
        component: FunnelCanvasesComponent,
        canDeactivate: [FormNotDirtyCanDeactivateGuard],
        data: {
          metaTitle: 'Funnels.canvases',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.CANVASES,
        },
      },
      {
        path: 'tiptap',
        component: TiptapComponent,
        data: {
          metaTitle: 'Funnels.tiptap',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.CANVASES,
        },
      },
      {
        path: 'recommendations',
        component: FunnelRecommendationsComponent,
        data: {
          metaTitle: 'Funnels.recommended tactics',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.RECOMMENDATIONS,
        },
      },
      {
        path: 'statement',
        loadChildren: () =>
          import('@modules/statement/shared/modules/funnel-statement/funnel-statement.module').then(
            (m) => m.FunnelStatementModule,
          ),
      },
      {
        path: 'content-generator',
        component: FunnelContentGeneratorComponent,
        canActivate: [FunnelContentGenerateCanActivateGuard],
        data: {
          metaTitle: 'Funnels.content generator',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.CONTENT_GENERATE,
        },
      },
      {
        path: 'content-generator-library',
        component: FunnelContentGeneratorLibraryComponent,
        canActivate: [FunnelContentGenerateCanActivateGuard],
        data: {
          metaTitle: 'Funnels.content generator library',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.CONTENT_GENERATOR_LIBRARY,
        },
      },
      {
        path: 'graphics',
        component: FunnelGraphicsComponent,
        canDeactivate: [FunnelGraphicsCanDeactivateGuard],
        data: {
          metaTitle: 'Funnels.graphics',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.GRAPHICS,
        },
      },
      {
        path: 'statistics',
        component: FunnelStatisticsComponent,
        data: {
          metaTitle: 'Funnels.Statistics.Statistics',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
          type: FunnelTab.STATISTICS,
        },
        children: [
          {
            path: '',
            component: FunnelStatisticsReportListComponent,
            data: {
              metaTitle: 'Funnels.Statistics.Statistics',
              shortLeftNavigation: false,
              headerComponent: FunnelManageHeaderComponent,
              type: FunnelTab.STATISTICS,
            },
          },
          {
            path: 'create-new-report',
            component: FunnelStatisticsAddNewReportIntroComponent,
            data: {
              metaTitle: 'Funnels.Statistics.Statistics',
              shortLeftNavigation: false,
              headerComponent: FunnelManageHeaderComponent,
              type: FunnelTab.STATISTICS,
            },
          },
        ],
      },
    ],
  },
];

export const FunnelStatementRoutes: Routes = [
  {
    path: '',
    component: FunnelStatementComponent,
    data: {
      metaTitle: 'Funnels.statement',
      shortLeftNavigation: false,
      headerComponent: FunnelManageHeaderComponent,
      type: FunnelTab.STATEMENT,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '1',
      },
      {
        path: '1',
        component: StatementFormStep1Component,
        data: {
          metaTitle: 'Statement.step 2',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
        },
      },
      {
        path: '2',
        component: StatementFormStep2Component,
        data: {
          metaTitle: 'Statement.step 3',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
        },
        canActivate: [StatementNextStepInFunnelGuard],
      },
      {
        path: '3',
        component: StatementFormStep3Component,
        data: {
          metaTitle: 'Statement.step 4',
          shortLeftNavigation: false,
          headerComponent: FunnelManageHeaderComponent,
        },
        canActivate: [StatementNextStepInFunnelGuard],
      },
    ],
  },
];
