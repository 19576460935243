<div
  *ngIf="form"
  [formGroup]="form"
  [validate-form]="form"
>
  <df-business-data-form-header [type]="type"></df-business-data-form-header>
  <div *ngIf="form">
    <label tuiLabel="Description">
      <tui-textarea
        class="type-desc"
        [rows]="15"
        [expandable]="true"
        [formControlName]="type"
        >Type description</tui-textarea
      >
      <df-error-input
        class="mb-6"
        [control]="control"
      ></df-error-input>
    </label>
  </div>
</div>
