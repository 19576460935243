import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { TacticAddComponent } from '../tactic-ad/tactic-ad.component';
import { TacticStepWrapperComponent } from '../tactic-step-wrapper/tactic-step-wrapper.component';
import { TacticNewsletterComponent } from '../tactic-newsletter/tactic-newsletter.component';
import { TacticLandingComponent } from '../tactic-landing/tactic-landing.component';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

interface Items {
  ads: string[];
  images: string[];
}

export interface Email {
  title: string;
  description: string;
  call_to_action: string;
}

@Component({
  selector: 'df-tactic-columns',
  standalone: true,
  imports: [
    TacticAddComponent,
    TacticStepWrapperComponent,
    TacticNewsletterComponent,
    TacticLandingComponent,
    CommonModule,
    SharedModule,
  ],
  templateUrl: './tactic-columns.component.html',
  styleUrl: './tactic-columns.component.css',
})
export class TacticColumnsComponent {
  funnelId!: number;
  itemsAds!: Items;
  emailItem!: Email;
  type: 'ads' | 'newsletter' | 'landing' | 'social' = 'ads';

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
  ) {}

  @Input() funnelTactic?: FunnelTactic;

  isLoading = true;
  regeneratedAdds: number[] = [];
  isRegeneratingNewsletter = false;

  ngOnInit() {
    this.funnelId = +this.route.parent?.snapshot.params['id'];

    switch (this.funnelTactic?.tactic?.id) {
      case 3955: {
        this.type = 'ads';
        break;
      }

      case 3957: {
        this.type = 'newsletter';
        break;
      }

      case 3959: {
        this.type = 'landing';
        break;
      }

      case 3958: {
        this.type = 'social';
        break;
      }
    }

    const key = this.getStorageKey();
    const data = this.getFromStore(key);

    if (data) {
      this.isLoading = false;

      if (this.type === 'ads' || this.type === 'social') this.itemsAds = data;
      if (this.type === 'newsletter') this.emailItem = data;
      return;
    }

    if (this.type === 'ads' || this.type === 'social') this.generateAds(key);
    if (this.type === 'newsletter') this.generateNewslatter(key);
    if (this.type === 'landing') this.isLoading = false;
  }

  getStorageKey() {
    return 'mvp_funnel_' + this.funnelId + '_tactic_' + this.funnelTactic?.tactic?.id;
  }

  getText(text: string) {
    return text.replaceAll('"', '');
  }

  getFromStore(localStorageKey: string) {
    const value = localStorage.getItem(localStorageKey);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }

  generateAds(localStorageKey: string) {
    this.http.get<Items>(`${environment.API_PREFIX}/demo-mvp/generate-ad/${this.funnelId}`).subscribe((data) => {
      this.itemsAds = data;
      localStorage.setItem(localStorageKey, JSON.stringify(this.itemsAds));
      this.isLoading = false;
      this.cd.detectChanges();
    });
  }

  regenerateAdd(index: number) {
    this.regeneratedAdds.push(index);
    this.http.get<Items>(`${environment.API_PREFIX}/demo-mvp/generate-ad/${this.funnelId}`).subscribe((data) => {
      this.itemsAds.ads[index] = data.ads[index];
      this.itemsAds.images[index] = data.images[index];
      localStorage.setItem(this.getStorageKey(), JSON.stringify(this.itemsAds));
      this.cd.detectChanges();
      this.regeneratedAdds = this.regeneratedAdds.filter((v) => v !== index);
    });
  }

  isAddRegenerating(index: number) {
    return this.regeneratedAdds.includes(index);
  }

  regenerateNewsletter() {
    this.isRegeneratingNewsletter = true;
    this.http
      .get<Email>(`${environment.API_PREFIX}/demo-mvp/generate-newsletter/${this.funnelId}`)
      .subscribe((data) => {
        this.emailItem = data;
        localStorage.setItem(this.getStorageKey(), JSON.stringify(this.emailItem));
        this.isRegeneratingNewsletter = false;
        this.cd.detectChanges();
      });
  }

  generateNewslatter(localStorageKey: string) {
    this.http
      .get<Email>(`${environment.API_PREFIX}/demo-mvp/generate-newsletter/${this.funnelId}`)
      .subscribe((data) => {
        console.log(data);
        this.emailItem = data;
        localStorage.setItem(localStorageKey, JSON.stringify(this.emailItem));
        this.isLoading = false;
        this.cd.detectChanges();
      });
  }
}
