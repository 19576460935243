import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { CheckCompanyDataExistDocument } from './graphql/check-company-data-exist.mutation';
import { CheckCompanyDataExistMutation } from './graphql/check-company-data-exist.mutation.generated';
import { SetCompanyDataDocument } from './graphql/set-company-data.mutation';
import { SetCompanyDataMutation } from './graphql/set-company-data.mutation.generated';
import { GetCompanyDataDocument } from './graphql/get-company-data.query';
import { GetCompanyDataQuery } from './graphql/get-company-data.query.generated';
import { PerformPromptBaseOnCompanyDataDocument } from './graphql/perform-prompt-base-on-company-data.mutation';
import { PerformPromptBaseOnCompanyDataMutation } from './graphql/perform-prompt-base-on-company-data.mutation.generated';
import { InputsInputGraphql } from '@modules/graphql/graphql-types';
import { SaveInputDataDocument } from './graphql/save-input-data.mutation';
import { SaveInputDataMutation } from './graphql/save-input-data.mutation.generated';
import { GetInputDataQuery } from './graphql/get-input-data.query.generated';
import { GetInputDataDocument } from './graphql/get-input-data.query';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BusinessDataService {
  constructor(
    private apollo: Apollo,
    private readonly httpClient: HttpClient,
  ) {}

  setCompanyData(businessName: string, url: string, funnelId: number) {
    return this.apollo.mutate<SetCompanyDataMutation>({
      mutation: SetCompanyDataDocument,
      variables: { input: { businessName, url, funnelId } },
      fetchPolicy: 'no-cache',
    });
  }

  getCompanyData(funnelId: number) {
    return this.apollo.query<GetCompanyDataQuery>({
      query: GetCompanyDataDocument,
      variables: { funnelId },
      fetchPolicy: 'no-cache',
    });
  }

  checkCompanyDataExist(funnelId: number) {
    return this.apollo.mutate<CheckCompanyDataExistMutation>({
      mutation: CheckCompanyDataExistDocument,
      variables: { funnelId },
      fetchPolicy: 'no-cache',
    });
  }

  performPromptBaseOnCompanyData(promptId: number, activityId: number, funnelTacticId: number, funnelId: number) {
    return this.apollo.mutate<PerformPromptBaseOnCompanyDataMutation>({
      mutation: PerformPromptBaseOnCompanyDataDocument,
      variables: { input: { promptId, funnelId, activityId, funnelTacticId } },
      fetchPolicy: 'no-cache',
    });
  }

  setUserInputData(input: InputsInputGraphql) {
    return this.apollo.mutate<SaveInputDataMutation>({
      mutation: SaveInputDataDocument,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  }

  getInputData(funnelId: number) {
    return this.apollo.query<GetInputDataQuery>({
      query: GetInputDataDocument,
      variables: { funnelId },
      fetchPolicy: 'no-cache',
    });
  }

  saveDemoBusinessData(body: { email: string; funnelId: number }): Observable<void> {
    return this.httpClient.post<void>(`${environment.API_PREFIX}/demo-mvp`, body);
  }

  checkCanvasIsGenerated(funnelId: number) {
    return this.httpClient.get<boolean>(`${environment.API_PREFIX}/tiptap/canvas-check/${funnelId}`);
  }
}
