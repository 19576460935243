export enum StorageEnum {
  JWT = 'jwt',
  USER = 'user',
  URL = 'action_url',
  HIDDEN_URL = 'hidden_action_url',
  PLAN_TO_BUY = 'plan_to_buy',
  SELECTED_TRIAL = 'selected_trial',
  TEAMS = 'teams',
  NEW_USER_REGISTERED = 'new_user_registered',
}
