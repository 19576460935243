export const LANG_FILES = [
  { prefix: './assets/i18n/', suffix: '.json' },
  { prefix: './assets/i18n/auth/auth-', suffix: '.json' },
  { prefix: './assets/i18n/tactics/tactics-', suffix: '.json' },
  { prefix: './assets/i18n/statement/statement-', suffix: '.json' },
  { prefix: './assets/i18n/user-settings/user-settings-', suffix: '.json' },
  { prefix: './assets/i18n/users/users-', suffix: '.json' },
  { prefix: './assets/i18n/funnels/funnels-', suffix: '.json' },
  { prefix: './assets/i18n/pricing/pricing-', suffix: '.json' },
  { prefix: './assets/i18n/faq/faq-', suffix: '.json' },
  { prefix: './assets/i18n/content-library/content-library-', suffix: '.json' },
  { prefix: './assets/i18n/notifications/notifications-', suffix: '.json' },
  { prefix: './assets/i18n/resource-teams/resource-teams-', suffix: '.json' },
  { prefix: './assets/i18n/prompt/prompt-', suffix: '.json' },
  { prefix: './assets/i18n/canvas/canvas-', suffix: '.json' },
  { prefix: './assets/i18n/tiles/tiles-', suffix: '.json' },
  {
    prefix: './assets/i18n/marketing-campaign/marketing-campaign-',
    suffix: '.json',
  },
  { prefix: './assets/i18n/ayrshare/ayrshare-', suffix: '.json' },
  { prefix: './assets/i18n/recommender/recommender-', suffix: '.json' },
  { prefix: './assets/i18n/assets-library/assets-library-', suffix: '.json' },
];
